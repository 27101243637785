import { createContext, useContext, useState, useEffect } from "react";

//custom hook
import { useFetch } from "../hooks/useFetch";

//context
import { useAuth } from "../context/AuthContext";

//constants
import { ApiEndpoints } from "../utils/Constants";

const AdminDataContext = createContext();
export const AdminDataProvider = ({ children, componentName }) => {
    const { logout } = useAuth();
    const [allInformation, setAllInformation] = useState([]);
    const [loadGet, setLoadGet] = useState(false);
    const getFetcher = useFetch("get"); //for all get requests
    const postFetcher = useFetch("post"); //for add, edit and delete requests

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (componentName === "ManageInformation") {
                    const response = await getFetcher.setRequest({
                        endpoint: ApiEndpoints["API_GET_ALL_INFO"]
                    });
                    if (response) {
                        if (!response.error && response.data) //if no error
                            setAllInformation(response.data);
                        else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                            logout();
                    }
                }
            }
            catch (error) {
                console.error('Error fetching data for admin:', error)
            }
        };
        fetchData();
    }, [componentName, loadGet]);

    //Update data as per component.
    const updateInformation = async (newData, endpoint) => {
        try {
            if (componentName === "ManageInformation") {
                const response = await postFetcher.setRequest({
                    endpoint: endpoint,
                    body: JSON.stringify(newData)
                });
                if (response) {
                    if (!response.error) //if no error
                        setLoadGet(prev => !prev);
                    else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                        logout();
                }
            }
        }
        catch (error) {
            console.error("Error udpating data for admin:", error);
        }
    };
    return (
        <AdminDataContext.Provider
            value={{ allInformation, updateInformation }}>
            {children}
        </AdminDataContext.Provider>
    );
};

// Custom hook to access the data and update function.
export const useAdminData = () => {
    const context = useContext(AdminDataContext);
    if (!context) {
        throw new Error("useAdminData must be used within a AdminDataProvider");
    }
    return context;
};
