//constants
import { ApiEndpoints } from "../utils/Constants";

//useFetch react hook
import { useFetch } from "../hooks/useFetch";

//Renders services for user authentication.
export default function AuthService() {
    const postFetcher = useFetch("post");

    //User login
    const login = async (username, password) => {

        //API call.
        const response = await postFetcher.setRequest({
            endpoint: ApiEndpoints["API_LOGIN"],
            body: JSON.stringify({
                username: username,
                password: password
            })
        });
        if (response && !response.error && response.data) {

            //Iterate through response.
            let user = {}
            Object.entries(response.data).forEach(([key, value]) => {
                user[key] = value;
            });
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        }
        else
            return response;
    };

    return {
        login
    };
};