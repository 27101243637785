import { Routes, Route, Navigate } from 'react-router-dom';

//context
import { PatrollerDataProvider } from '../../context/PatrollerContext';
import { DispatcherDataProvider } from '../../context/DispatcherContext';
import { ManagerDataProvider } from '../../context/ManagerContext';
import { AdminDataProvider } from '../../context/AdminContext';

//components
import Header from '../Header/Header';
import { useAuth } from '../../context/AuthContext';

//shared pages
import LoginUser from '../../pages/Login/LoginUser';

//patroller pages
import ShiftInformation from '../../pages/Patroller/ShiftInformation';
import AddIncident from '../../pages/Patroller/AddIncident';
import ViewOpenIncidents from '../../pages/Patroller/ViewOpenIncidents';
import ViewCompleteIncidents from '../../pages/Patroller/ViewCompleteIncidents';
import EditCompleteIncident from '../../pages/Patroller/EditCompleteIncident';

//dispatcher pages
import IncidentManagement from '../../pages/Dispatcher/IncidentManagement';
import ViewAllCompleteIncidents from '../../pages/Dispatcher/ViewAllCompleteIncidents';
import EditIncident from '../../pages/Dispatcher/EditIncident';
import OpenIncident from '../../pages/Dispatcher/OpenIncident';

//manager pages
import ViewServiceCalls from '../../pages/Manager/ViewServiceCalls';
import EditServiceCall from '../../pages/Manager/EditServiceCall';
import ViewServiceCallSummaries from '../../pages/Manager/ViewServiceCallSummaries';

//admin pages
import ManageInformation from "../../pages/Admin/ManageInformation";

//Renders Routing and Header of the application.
export default function BasicLayout() {
    const { auth } = useAuth();
    const isUserLoggedin = (auth && Object.keys(auth)?.length > 0) || sessionStorage.getItem("user"); //check session storage value for page reload

    //Custom element for different routes
    const customElement = (Component, componentName, type, showEndShiftBtn = false, showLogoutBtn = false) => {
        return (
            <>
                {type === "patroller" && (
                    <>
                        <Header showEndShiftBtn={showEndShiftBtn} showLogoutBtn={showLogoutBtn} />
                        <PatrollerDataProvider componentName={componentName}>
                            <Component />
                        </PatrollerDataProvider>
                    </>
                )}
                {type === "dispatcher" && (
                    <>
                        <Header showEndShiftBtn={showEndShiftBtn} showLogoutBtn={showLogoutBtn} />
                        <DispatcherDataProvider componentName={componentName}>
                            <Component />
                        </DispatcherDataProvider>
                    </>
                )}
                {type === "manager" && (
                    <>
                        <Header showEndShiftBtn={showEndShiftBtn} showLogoutBtn={showLogoutBtn} />
                        <ManagerDataProvider componentName={componentName}>
                            <Component />
                        </ManagerDataProvider>
                    </>
                )}
                {type === "admin" && (
                    <>
                        <Header showEndShiftBtn={showEndShiftBtn} showLogoutBtn={showLogoutBtn} />
                        <AdminDataProvider componentName={componentName}>
                            <Component />
                        </AdminDataProvider>
                    </>
                )}
            </>
        );
    };

    return (
        <Routes>
            <Route path="/login" element={<><Header /><LoginUser /></>} />

            {isUserLoggedin ? (
                <>
                    {/* Paths for patroller */}
                    <Route path="/patroller" element={<Navigate to="/patroller/shift-info" />} />
                    <Route path="/patroller/shift-info" element={customElement(ShiftInformation, "ShiftInformation", "patroller", false, true)} />
                    <Route path="/patroller/add-incident" element={customElement(AddIncident, "AddIncident", "patroller")} />
                    <Route path="/patroller/view-complete-incidents" element={customElement(ViewCompleteIncidents, "ViewCompleteIncidents", "patroller", true)} />
                    <Route path="/patroller/view-open-incidents" element={customElement(ViewOpenIncidents, "ViewOpenIncidents", "patroller", true)} />
                    <Route path="/patroller/edit-complete-incident" element={customElement(EditCompleteIncident, "EditCompleteIncident", "patroller", true)} />

                    {/* Paths for dispatcher */}
                    <Route path="/dispatcher" element={<Navigate to="/dispatcher/incident-management" />} />
                    <Route path="/dispatcher/incident-management" element={customElement(IncidentManagement, "IncidentManagement", "dispatcher", false, true)} />
                    <Route path="/dispatcher/view-all-complete-incidents" element={customElement(ViewAllCompleteIncidents, "ViewAllCompleteIncidents", "dispatcher", false, true)} />
                    <Route path="/dispatcher/edit-incident" element={customElement(EditIncident, "EditIncident", "dispatcher", false, true)} />
                    <Route path="/dispatcher/open-incident" element={customElement(OpenIncident, "OpenIncident", "dispatcher", false, true)} />

                    {/* Paths for manager */}
                    <Route path="/manager" element={<Navigate to="/manager/view-service-calls" />} />
                    <Route path="/manager/view-service-calls" element={customElement(ViewServiceCalls, "ViewServiceCalls", "manager", false, true)} />
                    <Route path="/manager/edit-service-call" element={customElement(EditServiceCall, "EditServiceCall", "manager", false, true)} />
                    <Route path="/manager/service-call-summaries" element={customElement(ViewServiceCallSummaries, "ViewServiceCallSummaries", "manager", false, true)} />

                    {/* Paths for admin */}
                    <Route path="/admin" element={<Navigate to="/admin/manage-information" />} />
                    <Route path="/admin/manage-information" element={customElement(ManageInformation, "ManageInformation", "admin", false, true)} />

                    {/* Fallback route */}
                    <Route path="*" element={<Navigate to="/login" />} />
                </>
            ) : (
                <Route path="*" element={<Navigate to="/login" />} />
            )}
        </Routes>
    );
}