import { useEffect, useState, useMemo } from "react";
import { Grid, IconButton, Box, Select, MenuItem, CircularProgress } from "@mui/material";
import useStyles from "./styles";
import { MaterialReactTable } from "material-react-table";
import {
    AddCircleOutline as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    CheckCircleOutline as YesIcon,
    DoNotDisturbAlt as NoIcon
} from "@mui/icons-material";

//components
import PageTitle from "../../components/Titles/PageTitle";
import CustomToolbarWithExport from "../../components/CustomDataGridOptions/CustomToolbarWithExport";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";
import { ConfirmationDialog } from "../../components/Dialog/ConfirmationDialog";
import ManageInfoDialog from "../../components/Dialog/ManageInfoDialog";
import InformationDialog from "../../components/Dialog/InformationDialog";

//context
import { useAdminData } from "../../context/AdminContext";

//themes
import themes from "../../theme/defaultTheme";

//constants
import { ApiEndpoints, GenericConstants } from "../../utils/Constants";

function camelCaseToSentenceCase(input) {
    const res = input
        .replace(/([A-Z])/g, ' $1') //Insert space before uppercase letters
        .replace(/^./, str => str.toUpperCase()); //Capitalize first letter

    return res.trim(); // Remove any leading/trailing whitespace
}

export default function ManageInformation() {
    const { StyledCard, StyledGrid, StyledCardContent, StyledButton } = useStyles();
    const { allInformation, updateInformation } = useAdminData();
    const [loading, setLoading] = useState(true);
    const [selectedDropDown, setSelectedDropDown] = useState({});
    const [uniqueFields, setUniqueFields] = useState([]);
    const [mandatoryFields, setMandatoryFields] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isDelDialogOpen, setIsDelDialogOpen] = useState(false);
    const [editDialog, setEditDialog] = useState({ open: false });
    const [addDialog, setAddDialog] = useState({ open: false });
    const [informationDialog, setInformationDialog] = useState({ open: false });
    const [selectedRow, setSelectedRow] = useState([]);

    useEffect(() => {
        setLoading(true);
        if (allInformation && allInformation.dropDowns?.length > 0 && allInformation.data?.length > 0) {

            //Set drop down value
            const firstItem = allInformation.dropDowns[0]
            setSelectedDropDown(firstItem);

            //set unique fields for selected drop down
            setUniqueFields(firstItem?.uniqueFields || []);

            //set mandatory fields for selected drop down
            setMandatoryFields(firstItem?.mandatoryFields || []);

            //filter data as per drop down value
            const filteredData = allInformation.data.filter(data => data.id === firstItem.id);

            //Set table value
            setTableData(filteredData[0]?.data || []);

            setLoading(false);
        }
    }, [allInformation]);

    //When drop down value changes, update the table data
    useEffect(() => {
        if (selectedDropDown && allInformation?.data?.length > 0) {

            //filter data as per drop down value
            const filteredData = allInformation.data.filter(data => data.id === selectedDropDown.id);

            //set unique fields for selected drop down
            setUniqueFields(selectedDropDown?.uniqueFields || []);

            //set mandatory fields for selected drop down
            setMandatoryFields(selectedDropDown?.mandatoryFields || []);

            //Set table value
            if (filteredData.length > 0 && filteredData[0].data?.length > 0)
                setTableData(filteredData[0].data);
            else
                setTableData([]);
        }
    }, [selectedDropDown]);

    //When delete button is clicked
    const handleDeleteConfirm = () => {
        if (selectedRow?.length > 0) {

            //delete data from database
            const delData = {
                "dropDownId": selectedDropDown.id,
                "itemId": selectedRow[0]
            }
            updateInformation(delData, ApiEndpoints["API_DELETE_ITEM"]);
            setSelectedRow([]);
        }
    }

    const updateDialogFields = () => {
        let updatedColumns = [];
        if (columns?.length > 0) {

            //remove id and actions column
            updatedColumns = columns.filter(col => col.accessorKey !== "id" && col.accessorKey !== "actions");

            //for user drop down, add password column for dialog
            if (selectedDropDown.name.toLowerCase() === "users") {

                const usernameIndex = updatedColumns.findIndex(obj => obj.accessorKey === "username");
                const emailIndex = updatedColumns.findIndex(obj => obj.accessorKey === "email");

                if (usernameIndex !== -1 && emailIndex !== -1) {

                    //remove existing username object
                    const [usernameObj] = updatedColumns.splice(usernameIndex, 1);

                    //insert username after the email
                    updatedColumns.splice(emailIndex + 1, 0, usernameObj);

                    //insert password after username
                    updatedColumns.splice(emailIndex + 2, 0, { accessorKey: "password", header: "Password" });
                }

                //remove individual role columns
                updatedColumns = updatedColumns.filter(col => !GenericConstants.ROLES_LIST.includes(col.accessorKey));

                //add roles column
                updatedColumns.splice(Object.keys(updatedColumns).length - 1, 0, {
                    accessorKey: "roles",
                    header: "Role(s)"
                })
            }
        }
        return updatedColumns;
    }

    //Validate user input before adding/editing to database
    const validateUserInput = (input, uniqueFields) => {
        const selectedRowId = selectedRow.length > 0 ? selectedRow[0] : null;
        const errorFields = [];
        uniqueFields.forEach(field => {
            if (tableData?.length > 0) {
                const existingDataForField = tableData
                    .filter(data => data.id !== selectedRowId) //filter out selected row to avoid unnecessary errors
                    .map(row => {
                        return String(row[field]).toLowerCase() || "";
                    });
                //if input field value already exists, set error
                if (existingDataForField?.length > 0 && existingDataForField.includes(input[field].toLowerCase()) && input[field] !== "")
                    errorFields.push(camelCaseToSentenceCase(field));
            }
        })
        return errorFields;
    }

    //When add or edit button is clicked
    const handleDialogConfirm = (dialogInput, dialogType) => {
        if (dialogInput) {

            //check if user input is valid or not.
            let inputErrors = [];
            if (uniqueFields.length > 0)
                inputErrors = validateUserInput(dialogInput, uniqueFields)

            //if no errors then push item to database
            if (inputErrors.length === 0) {
                let newData = {}
                if (dialogType === "add") {
                    newData = {
                        "dropDownId": selectedDropDown.id,
                        "data": {
                            ...dialogInput
                        }
                    }

                    //add data to database
                    updateInformation(newData, ApiEndpoints["API_ADD_ITEM"]);

                    //close add dialog
                    setAddDialog({
                        open: false
                    });
                }
                else if (dialogType === "edit") {
                    newData = {
                        "dropDownId": selectedDropDown.id,
                        "itemId": selectedRow[0],
                        "data": {
                            ...dialogInput
                        }
                    }

                    //update data in database
                    updateInformation(newData, ApiEndpoints["API_EDIT_ITEM"]);

                    //close edit dialog
                    setEditDialog({
                        open: false
                    });
                    setSelectedRow([]);
                }
            }
            else { //show error dialog if errors exist
                const errorMsg = "Below field value(s) already exist. Please provide different value(s):\n" + inputErrors.join("\n");
                setInformationDialog({
                    open: true,
                    title: "Alert",
                    message: errorMsg
                });
            }
        }
    }

    //When edit icon is clicked
    const handleEditSelected = (selectedRowId) => {
        if (selectedRowId) {

            //set selected row
            setSelectedRow([selectedRowId]);
            const selectedRowData = tableData?.find(data => data.id === selectedRowId) || {};
            const dialogFields = updateDialogFields();
            setEditDialog({
                open: true,
                dialogFields: dialogFields,
                dialogFieldValues: selectedRowData,
                mandatoryFields: mandatoryFields.filter(field => field !== "password"),
                isTruckDialog: !!dialogFields.find(col => col.accessorKey === "truckNumber")
            });
        }
    }

    //when table data changes, update the columns
    const columns = useMemo(() => {
        if (tableData?.length > 0) {
            const firstRow = tableData[0];
            const allColumns = [];
            const mandatoryCols = [];
            const nonMandatoryCols = [];

            //add other columns
            Object.keys(firstRow)
                .filter(field => field.toLowerCase() !== "id" && field.toLowerCase() !== "status")
                .map(field => {
                    const fieldLower = field.toLowerCase();
                    let cols = [];
                    if (fieldLower === "roles") {
                        GenericConstants.ROLES_LIST.forEach(role => {
                            cols.push({
                                accessorKey: role,
                                header: camelCaseToSentenceCase(role),
                                Cell: ({ row }) => {
                                    const hasRole = row.original?.roles?.map(role => role.toLowerCase())?.includes(role.toLowerCase()) || false;
                                    return (
                                        <IconsWithTooltip
                                            title={hasRole ? "Yes" : "No"}
                                            icon={hasRole ?
                                                <YesIcon style={{ color: themes.default.palette.success.main }} /> :
                                                <NoIcon style={{ color: themes.default.palette.error.main }} />
                                            }
                                        />
                                    );
                                },
                                size: 80
                            });
                        })
                    }
                    else {
                        cols.push({
                            accessorKey: field,
                            header: camelCaseToSentenceCase(field),
                            size: 80
                        });
                    }
                    if (mandatoryFields.includes(field))
                        mandatoryCols.push(...cols);
                    else
                        nonMandatoryCols.push(...cols);
                });

            //add actions column at first
            allColumns.push({
                accessorKey: "actions",
                header: "Actions",
                enableSorting: false,
                enableColumnActions: false,
                enableColumnFilter: false,
                Cell: ({ row }) => (
                    <Box>
                        <IconButton sx={{ p: 0 }}>
                            <IconsWithTooltip
                                title="Click to edit"
                                icon={<EditIcon
                                    sx={{ color: themes.default.palette.primary.contrastText }}
                                    onClick={() => handleEditSelected(row.original.id)}
                                />}
                            />
                        </IconButton>
                        <IconButton>
                            <IconsWithTooltip
                                title="Click to delete"
                                icon={<DeleteIcon sx={{ color: themes.default.palette.primary.contrastText }}
                                    onClick={() => { setIsDelDialogOpen(true); setSelectedRow([row.original.id]); }}
                                />} />
                        </IconButton>
                    </Box>
                ),
                size: 80,
            });

            //add mandatory fields first
            allColumns.push(...mandatoryCols);

            //add non mandatory columns next
            allColumns.push(...nonMandatoryCols);

            // Add status column at the end
            if (firstRow.hasOwnProperty("status")) {
                allColumns.push({
                    accessorKey: "status",
                    header: camelCaseToSentenceCase("status"),
                    size: 80
                });
            }
            return allColumns;
        } else {
            return [];
        }
    }, [tableData]);

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* Page Title */}
                    <StyledGrid item xs={12} sx={{ display: 'flex', justifyContent: "center" }}>
                        <PageTitle aria-label="Manage Information" title="Manage Information" />
                    </StyledGrid>

                    {loading ? (
                        <StyledGrid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress aria-label="loading indicator" />
                            </Box>
                        </StyledGrid>
                    ) : (
                        <>
                            <StyledGrid container>

                                {/* Drop Down */}
                                <Grid item xs={4} lg={3}>
                                    <Select
                                        value={selectedDropDown || null}
                                        onChange={(event) => setSelectedDropDown(event.target.value)}
                                        fullWidth
                                    >
                                        {allInformation?.dropDowns?.map(option => (
                                            <MenuItem key={option.id} value={option}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                {/* Add button */}
                                <Grid item xs={8} lg={9} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <StyledButton variant="contained" startIcon={<AddIcon />} onClick={() => {
                                        setAddDialog({
                                            open: true,
                                            dialogFields: updateDialogFields(),
                                            dialogFieldValues: [],
                                            isTruckDialog: !!columns.find(col => col.accessorKey === "truckNumber")
                                        });
                                    }}>
                                        Add New
                                    </StyledButton>
                                </Grid>
                            </StyledGrid>

                            {/* Table */}
                            <StyledGrid item xs={12} sx={{ overflow: "hidden" }}>
                                <MaterialReactTable
                                    state={{ isLoading: loading }}
                                    columns={columns}
                                    data={tableData}
                                    enableColumnActions={false} //Disable column headers actions
                                    enableToolbarInternalActions={false} //Disable default toolbar actions
                                    renderTopToolbarCustomActions={({ table }) =>
                                        <CustomToolbarWithExport
                                            table={table}
                                            type="manageInfo"
                                        />}
                                    initialState={{ showGlobalFilter: true }}
                                    getRowId={(row) => row.id}
                                />
                            </StyledGrid>
                        </>
                    )}
                </Grid>
            </StyledCardContent>

            {/* Delete Confirmation Dialog */}
            <ConfirmationDialog
                open={isDelDialogOpen}
                title="Delete Confirmation"
                content="Are you sure you want to delete selected item?"
                onConfirm={handleDeleteConfirm}
                onClose={() => { setIsDelDialogOpen(false) }}
            />

            {/* Add Dialog */}
            <ManageInfoDialog
                open={addDialog.open}
                dialogFields={addDialog.dialogFields || []}
                dialogFieldValues={addDialog.dialogFieldValues || []}
                mandatoryFields={mandatoryFields}
                isTruckDialog={addDialog.isTruckDialog || false}
                dialogType="add"
                onClose={() =>
                    setAddDialog((prevState) => ({ ...prevState, open: false }))
                }
                onConfirm={handleDialogConfirm}
            />

            {/* Edit Dialog */}
            <ManageInfoDialog
                open={editDialog.open}
                dialogFields={editDialog.dialogFields || []}
                dialogFieldValues={editDialog.dialogFieldValues || []}
                mandatoryFields={editDialog.mandatoryFields || []}
                isTruckDialog={editDialog.isTruckDialog || false}
                dialogType="edit"
                onClose={() =>
                    setEditDialog((prevState) => ({ ...prevState, open: false }))
                }
                onConfirm={handleDialogConfirm}
            />

            {/* Information Dialog component */}
            <InformationDialog
                open={informationDialog.open}
                onClose={() =>
                    setInformationDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={informationDialog.title}
                message={informationDialog.message}
                onConfirm={informationDialog.onConfirm}
            />
        </StyledCard>
    )
}