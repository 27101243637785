import { styled } from "@mui/system";
import { Card, Stepper, Typography, TextField, Grid, Button, DialogTitle } from "@mui/material";
import themes from "../../theme/defaultTheme";

export default function useStyles() {
    const StyledCard = styled(Card)({
        maxWidth: '100%',
        marginTop: '45px',
        marginLeft: '35px',
        marginRight: '35px',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2) !important'
    });

    const StyledStepper = styled(Stepper)({
        margin: 25
    });

    const FormLabel = styled(Typography)({
        marginLeft: "17px",
        marginTop: "15px",
        fontWeight: "bold"
    });

    const FormValue = styled(Typography)({
        marginTop: "15px"
    });

    const StyledTextField = styled(TextField)({
        width: '90%',
    });

    const AdditionalInfoLabel = styled(Typography)({
        fontStyle: 'italic',
        fontSize: '12px !important',
        marginLeft: "17px",
    });

    const StyledButtonGrid = styled(Grid)({
        borderColor: themes.default.palette.text.primary,
        paddingTop: '15px',
        paddingBottom: '15px'
    });

    const StyledButton = styled(Button)({
        color: themes.default.palette.primary.contrastText,
        textTransform: 'None !important',
        margin: '10px !important'
    });

    const StyledInputRequired = styled(Typography)({
        color: 'red',
        fontStyle: 'italic',
        fontSize: '10px',
        marginLeft: "17px"
    });

    const StyledMandatoryGrid = styled(Grid)({
        marginTop: "16px",
        marginRight: "8px",
        marginLeft: "17px"
    });

    const StyledAlerts = styled(Grid)({
        marginRight: "8px",
        marginLeft: "17px"
    });

    const ImgActions = styled(Grid)({
        padding: "10px"
    });

    const StyledDialogTitle = styled(DialogTitle)({
        padding: 30,
        fontWeight: 'bold',
        color: themes.default.palette.primary.main,
        textAlign: 'center'
    });

    const StyledDialogButton = styled(Button)({
        textTransform: 'None !important'
    });

    return {
        StyledCard, StyledStepper, FormLabel, FormValue,
        StyledTextField, AdditionalInfoLabel, StyledButtonGrid, StyledButton,
        StyledInputRequired, StyledMandatoryGrid, StyledAlerts, ImgActions,
        StyledDialogTitle, StyledDialogButton
    };
}