import log from "loglevel";

let logBuffer = [];
const uploadInterval = 3600000; // Send logs every 1 hour

const uploadLogsToAzure = async () => {
  if (logBuffer.length > 0) {
    const blobContent = logBuffer.join("\n"); // Combine logs into a single string
    logBuffer = []; // Clear buffer
    try {
      const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
      const blobName = `request_logs_${timestamp}.txt`;
      const blobUrl = `${process.env.REACT_APP_AZURE_REQUEST_LOGS_CONTAINER_URL}${blobName}?${process.env.REACT_APP_AZURE_REQUEST_LOGS_SAS_TOKEN}`;
      const response = await fetch(blobUrl, {
        method: "PUT",
        body: blobContent,
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": "text/plain",
        },
      });

      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error uploading logs:", error);
    }
  }
};

// Add log messages to buffer
const logWithBuffer = (level, message) => {
  const logEntry = `Timestamp: ${new Date().toISOString()}\nLevel: ${level}\nMessage: ${message}\n`;
  logBuffer.push(logEntry);
};

// Start periodic log upload
setInterval(uploadLogsToAzure, uploadInterval);

export const customRequestLog = (level, message, logToConsole = false) => {
  logWithBuffer(level, message); // Use the buffered logging function

  if (logToConsole) {
    log[level](message); // Log to console if specified
  }
};
