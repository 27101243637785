import { Typography } from "@mui/material";
import themes from "../../theme/defaultTheme";

const customStyle = {
    marginTop: "12px",
    marginRight: "8px",
    marginLeft: "17px",
    fontWeight: "bold",
    color: themes.default.palette.text.primary
}

export default function SectionTitle({ title, isSubTitle = false }) {
    return (
        <>
            {!isSubTitle && (
                <>
                    <Typography variant="h5" mt={2} style={{ ...customStyle }}>
                        {title}
                    </Typography>
                    <hr style={customStyle} />
                </>
            )}
            {isSubTitle && (
                <Typography variant="h6" mt={2} style={{ ...customStyle }}>
                    {title}
                </Typography>
            )}
        </>
    );
}