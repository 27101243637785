import {
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Typography
} from "@mui/material";
import useStyles from "./styles";
import { useState } from "react";

export default function LoginAsDialog({ open, onClose, title, menuItems }) {
    const { StyledDialogButton, ErrorField, StyledDialogTitle } = useStyles();
    const defaultRole = "Select a role"
    const [loginAs, setLoginAs] = useState(defaultRole);
    const [showInputRequired, setShowInputRequired] = useState(false);

    const handleDropDwnChange = (event) => {
        setLoginAs(event.target.value);
    };

    const handleLogin = () => {
        if (loginAs !== defaultRole) {
            onClose(loginAs);
            setShowInputRequired(false);
        }
        else {
            setShowInputRequired(true);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    width: '280px'
                }
            }}
        >
            <StyledDialogTitle style={{ textAlign: "center" }}>{title}</StyledDialogTitle>
            <DialogContent>
                <ErrorField
                    labelId="login-as-label"
                    id="login-as"
                    value={loginAs}
                    label="Login As"
                    onChange={handleDropDwnChange}
                    error={showInputRequired}
                    sx={{ width: "230px" }}
                >
                    {menuItems.map((item, index) =>
                        <MenuItem key={index} value={item}>
                            {item}
                        </MenuItem>)}
                </ErrorField>
                {showInputRequired && <Typography sx={{ color: 'red', fontStyle: 'italic', fontSize: '12px' }}>Make a selection</Typography>}
            </DialogContent>
            <DialogActions>
                <DialogActions>
                    <StyledDialogButton onClick={() => onClose(null)}>Cancel</StyledDialogButton>
                    <StyledDialogButton onClick={handleLogin}>Log In</StyledDialogButton>
                </DialogActions>
            </DialogActions>
        </Dialog>
    );
}