import { useState } from 'react';
import { AppBar, Alert, Toolbar, Grid } from '@mui/material';
import { HeaderConstants, ApiEndpoints } from '../../utils/Constants';
import useStyles from "./styles";

//components
import EndShiftDialog from '../Dialog/EndShiftDialog';

//context
import { useAuth } from '../../context/AuthContext';
import { useNetworkStatus } from '../../context/NetworkStatusContext';

//custom hook
import { useFetch } from '../../hooks/useFetch';

//Renders Header of the application.
export default function Header(props) {
    const { logout } = useAuth();
    const { StyledButton, StyledTypography } = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const postFetcher = useFetch("post");
    const isOffline = useNetworkStatus();

    const handleOnEndShift = async (endshiftData) => {

        //close dialog
        setIsDialogOpen(false);

        if (endshiftData) {

            //post shift end details
            const response = await postFetcher.setRequest({
                endpoint: ApiEndpoints["API_POST_SHIFT_END"],
                body: JSON.stringify(endshiftData)
            });
            if (response) {
                if (!response.error) //if no error
                    logout();
                else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                    return
            }
        }
    }
    return (
        <>
        <AppBar position="static">
            <Toolbar disableGutters>
                <Grid container alignItems="center">

                    {/* TxDOT and TTI logo */}
                    <Grid item xs={5} align="left" sx={{ display: 'flex', paddingLeft: 3, alignItems: 'center' }}>
                        <img alt="TxDOT Logo" src="/images/TxDOTLogo.png" style={{ width: "auto", height: '55px', marginBottom: 6 }} />
                        <div style={{
                            height: '50px',
                            width: '1px',
                            backgroundColor: 'white',
                            marginLeft: 20,
                            marginRight: 15,
                            marginTop: 8,
                            marginBottom: 2
                        }}></div>
                        <img alt="TTI Logo" src="/images/TTILogoWhite.png" style={{ width: "auto", height: '30px', marginTop: 25 }} />
                    </Grid>

                    {/* DCSO Courtesy Patrol Management Heading. */}
                    <Grid item xs={5} align="left">
                        <StyledTypography align="left">
                            {HeaderConstants.APPLICATION_TITLE}
                        </StyledTypography>
                    </Grid>

                    {/* Display End Shift or Logout button as needed. */}
                    {(props.showEndShiftBtn || props.showLogoutBtn) && (
                        <Grid item xs={2} align="right" sx={{ paddingRight: 2 }}>
                            {props.showEndShiftBtn && (<StyledButton variant="outlined" onClick={() => { setIsDialogOpen(true) }}>
                                {HeaderConstants.END_SHIFT_BTN}
                            </StyledButton>)}
                            {props.showLogoutBtn && (<StyledButton variant="outlined" onClick={logout}>
                                {HeaderConstants.LOGOUT_BTN}
                            </StyledButton>)}
                        </Grid>
                    )}
                </Grid>
            </Toolbar>

            {/* End Shift Dialog */}
            <EndShiftDialog
                open={isDialogOpen}
                title="End Shift Form"
                onClose={() => { setIsDialogOpen(false) }}
                onSubmit={handleOnEndShift}
            />
        </AppBar>
        {isOffline && <Grid>
            <Alert severity="error">You are offline. Some features may be unavailable.</Alert>
        </Grid>}
        </>

    )
}