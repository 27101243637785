import { Tooltip, IconButton } from "@mui/material"

//icons
import { Edit as EditIcon } from "@mui/icons-material";

//theme
import themes from "../../theme/defaultTheme";

//Render custom edit button with icon and tooltip,
export default function CustomEditButton({ tooltipTitle, onClick }) {
    return (
        <Tooltip title={tooltipTitle}>
            <IconButton onClick={onClick} style={{ marginTop: "8px" }}>
                <EditIcon style={{ color: themes.default.palette.primary.contrastText }} />
            </IconButton>
        </Tooltip>
    );
}