import { styled } from "@mui/system";
import { Card, Grid, CardContent, Chip, Button } from "@mui/material";
import themes from "../../theme/defaultTheme";

export default function useStyles() {
    const StyledCard = styled(Card)({
        maxWidth: '100%',
        marginTop: 40,
        marginBottom: 40,
        marginLeft: 35,
        marginRight: 35,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2) !important'
    });

    const StyledGrid = styled(Grid)({
        paddingLeft: "17px",
        paddingRight: "17px",
        paddingTop: "17px"
    });

    const StyledCardContent = styled(CardContent)({
        paddingTop: "40px"
    });

    const StyledButton = styled(Button)({
        color: themes.default.palette.primary.contrastText,
        textTransform: 'None !important',
        p: 0
    });

    return {
        StyledCard, StyledGrid, StyledCardContent, StyledButton
    };
}