import { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Autocomplete,
    TextField
} from "@mui/material";
import { InfoRounded as InfoIcon } from "@mui/icons-material";
import useStyles from "./styles";

//constants
import { ManagerConstants } from "../../utils/Constants";

//components
import IconsWithTooltip from "../Icons/IconsWithTooltip";

export default function SelectTableColumnsDialog({ open, primaryCols, secondaryCols, onClose, onSave }) {
    const { StyledDialogButton, StyledLabel, StyledDialogTitle, AdditionalInfoLabel } = useStyles();

    //Filter out actions column
    const [localPrimaryCols, setLocalPrimaryCols] = useState([]);
    const [localSecondaryCols, setLocalSecondaryCols] = useState([]);

    useEffect(() => {
        if (open) {
            setLocalPrimaryCols(primaryCols?.length > 0 ? primaryCols.filter(col => col.accessorKey !== "actions") : []);
            setLocalSecondaryCols(secondaryCols);
        }
    }, [open, primaryCols, secondaryCols]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <StyledDialogTitle>Select Table Columns</StyledDialogTitle>
            <DialogContent>
                <Grid container>

                    {/* Primary Columns */}
                    <Grid container sx={{ paddingBottom: "20px" }}>
                        <Grid item xs={5}>
                            <StyledLabel style={{ display: 'flex' }}>
                                Primary
                                <IconsWithTooltip title="Columns that appear on table"
                                    icon={<InfoIcon sx={{ fontSize: "16px", marginLeft: "4px", marginTop: "4px" }} />} />
                            </StyledLabel>
                            <AdditionalInfoLabel>Select all that apply</AdditionalInfoLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <Autocomplete
                                multiple
                                value={localPrimaryCols || null}
                                onChange={(event, value) => setLocalPrimaryCols(value)}
                                options={ManagerConstants?.SERVICE_CALL_ALL_COLUMNS || []}
                                getOptionLabel={(option) => option?.header || ""}
                                isOptionEqualToValue={(option, value) => option.accessorKey === value.accessorKey}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" fullWidth />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Secondary Columns */}
                    <Grid container>
                        <Grid item xs={5}>
                            <StyledLabel style={{ display: 'flex' }}>
                                Secondary
                                <IconsWithTooltip title="Information that appears on detailed view"
                                    icon={<InfoIcon sx={{ fontSize: "16px", marginLeft: "4px", marginTop: "4px" }} />} />
                            </StyledLabel>
                            <AdditionalInfoLabel>Select all that apply</AdditionalInfoLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <Autocomplete
                                multiple
                                value={localSecondaryCols || null}
                                onChange={(event, value) => setLocalSecondaryCols(value)}
                                options={ManagerConstants?.SERVICE_CALL_ALL_COLUMNS || []}
                                getOptionLabel={(option) => option?.header || ""}
                                isOptionEqualToValue={(option, value) => option.accessorKey === value.accessorKey}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" fullWidth />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={() => onClose()}>Cancel</StyledDialogButton>
                <StyledDialogButton onClick={() => onSave({ localPrimaryCols, localSecondaryCols })}>Save</StyledDialogButton>
            </DialogActions>
        </Dialog >
    );
}