import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Box, Alert, List, ListItem, ListItemText, CircularProgress, Typography } from "@mui/material";
import useStyles from "./styles";

//components
import PageTitle from "../../components/Titles/PageTitle";
import SectionTitle from "../../components/Titles/SectionTitle";
import CustomEditButton from "../../components/Buttons/CustomEditButton";
import EditSelectedIncidentDialog from "../../components/Dialog/EditSelectedIncidentDialog";

//utils
import { GenericConstants, ApiEndpoints } from '../../utils/Constants';
import { getFormattedTime } from "../../utils/GetFormattedDateTimeDay";

//context
import { useManagerData } from "../../context/ManagerContext";
import { useAuth } from "../../context/AuthContext";

//custom hooks
import { useFetch } from "../../hooks/useFetch";

export default function EditServiceCall() {
    const { incidentDrpDwnValues, allRoutesInfo } = useManagerData();
    const { StyledCard, StyledButton, StyledCardContent, StyledGrid } = useStyles();
    const [loading, setLoading] = useState(true);
    const { logout } = useAuth();
    const getFetcher = useFetch("get");
    const navigate = useNavigate();

    //Fetch passed parameters
    const location = useLocation();
    const { serviceCallId } = location.state;

    //Fetch data from API call
    const { updateServiceCalls } = useManagerData();

    //React hooks
    const [selectedIncident, setSelectedIncident] = useState({});
    const [filteredImages, setFilteredImages] = useState([]);

    //Dialog Box hooks
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogData, setDialogData] = useState({});
    const [dialogType, setDialogType] = useState('');

    const handleEditClick = (dialogTitle, dialogData, dialogType) => {
        setDialogTitle(dialogTitle);
        setDialogData(dialogData);
        setDialogType(dialogType)
        setIsDialogOpen(true);
    };

    //Saves dialog data to the page when "Save" is clicked.
    const handleDialogSave = (newData) => {
        let updatedIncident;
        if (newData?.detectMethods) {
            updatedIncident = {
                ...selectedIncident,
                isCritical: newData.isCritical,
                time: {
                    ...selectedIncident.time,
                    detectMethod: newData.detectMethods
                },
                location: {
                    ...selectedIncident.location,
                    route: newData.route,
                    freeway: newData.freeway,
                    crossStreet: newData.crossStreet,
                    lanesBlocked: newData.lanesBlocked,
                    travelDirection: newData.travelDirections
                }
            }
        }
        else if (newData?.vehicleNumber) {
            const vehicleNum = newData.vehicleNumber;
            const vehiclesLst = selectedIncident.vehicles;
            vehiclesLst[vehicleNum - 1] = newData.selectedVehicle;
            updatedIncident = {
                ...selectedIncident,
                vehicles: vehiclesLst
            }
        }
        else if (newData?.closingComments) {
            updatedIncident = {
                ...selectedIncident,
                closingComments: newData.closingComments
            }
        }
        else {
            const updatedImages = newData?.filter(img => img.length > 0);
            setFilteredImages(updatedImages);
            updatedIncident = {
                ...selectedIncident,
                images: updatedImages
            }
        }
        setSelectedIncident(updatedIncident);
        setIsDialogOpen(false);
    };

    //Trigger when Cancel button of dialog is clicked
    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    //converts file into base64 format
    const handleFileUpdate = async (imgsToUpdate) => {
        const filePromises = imgsToUpdate.map((img) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve({
                        file: reader.result, // base64 string
                        fileName: img.file.name,
                        previewUrl: URL.createObjectURL(img.file),
                        type: img.type
                    });
                };
                reader.onerror = reject;
                reader.readAsDataURL(img.file);
            });
        });
        try {
            const updatedImages = await Promise.all(filePromises);
            return updatedImages;
        } catch (error) {
            console.error("Error reading file:", error);
        }
    };

    //when save is clicked
    const handleSaveBtnClick = async () => {
        let updatedIncident = selectedIncident

        //update images format before sending to API
        if (selectedIncident?.images?.length > 0) {

            const allUpdatedImages = [];
            for (const images of selectedIncident.images) {
                let imgsToUpdate = [];

                for (const img of images) {
                    if (img.blobURL) {
                        const response = await fetch(img.blobURL);
                        const blob = await response.blob();
                        imgsToUpdate.push({
                            file: new File([blob], img.file),
                            type: img.type
                        })
                    } else {
                        imgsToUpdate.push({
                            file: img.file,
                            type: img.type
                        });
                    }
                }
                const updatedImages = imgsToUpdate.length > 0 ? await handleFileUpdate(imgsToUpdate) : [];
                allUpdatedImages.push(updatedImages)
            }

            updatedIncident = {
                ...selectedIncident,
                images: allUpdatedImages
            }
        }
        await updateServiceCalls(updatedIncident);
        navigate(-1);
    }

    //Fetch incident from api
    useEffect(() => {
        setLoading(true);
        if (serviceCallId) {
            const fetchData = async () => {
                try {
                    const response = await getFetcher.setRequest({
                        endpoint: ApiEndpoints["API_GET_SERVICE_CALL_BY_ID"] + "?id=" + serviceCallId
                    });
                    if (response) {
                        if (!response.error && response.data?.incident) //if no error
                            setSelectedIncident(response.data.incident);
                        else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                            logout();
                    }
                }
                catch (error) {
                    console.error('Error fetching data for incident :', serviceCallId, " : ", error)
                }
                finally {
                    setLoading(false);
                }
            }
            fetchData();
        }
        else
            setLoading(false);
    }, [serviceCallId])

    useEffect(() => {
        if (selectedIncident && Object.keys(selectedIncident).length > 0) {

            //set images
            setFilteredImages(selectedIncident?.images ? selectedIncident.images.filter(img => img.length > 0) : [])
        }
    }, [selectedIncident])

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* Page Title */}
                    <Grid item xs={12}>
                        <PageTitle aria-label="Edit Service Call" title="Edit Service Call" />
                    </Grid>
                    {loading ? (
                        <StyledGrid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress aria-label="loading indicator" />
                            </Box>
                        </StyledGrid>
                    ) : (
                        <>
                            {selectedIncident && Object.keys(selectedIncident).length > 0 && (
                                <>

                                    {/* Alert */}
                                    <Grid item xs={12} sx={{ marginLeft: "17px" }}>
                                        <Alert severity="info">To edit a section, click on edit icon</Alert>
                                    </Grid>

                                    {/* Shift Information heading */}
                                    <Grid item xs={12}>
                                        <SectionTitle title="Shift Information" isSubTitle={true} />
                                    </Grid>

                                    {/* Display Shift Information */}
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                                <List >
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Truck Number"
                                                            primary={<b>Truck Number</b>}
                                                            secondary={selectedIncident.shiftInfo?.truckNumber || ""}
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Phone Number"
                                                            primary={<b>Phone Number</b>}
                                                            secondary={selectedIncident.shiftInfo?.phoneNumber || ""}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                                <List >
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Primary Patroller"
                                                            primary={<b>Primary Patroller</b>}
                                                            secondary={selectedIncident.shiftInfo?.loggedInPatroller || ""}
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Other Patroller(s)"
                                                            primary={<b>Other Patroller(s)</b>}
                                                            secondary={selectedIncident.shiftInfo?.otherPatrollers?.length > 0
                                                                ? selectedIncident.shiftInfo.otherPatrollers.join(", ")
                                                                : ""}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Incident time and Location heading */}
                                    <Grid item xs={12}>
                                        <Box display="flex">
                                            <SectionTitle title="Incident Time and Location" isSubTitle={true} />
                                            <CustomEditButton tooltipTitle="Edit" onClick={() => handleEditClick("Incident Time and Location",
                                                {
                                                    "detectMethods": selectedIncident.time.detectMethod,
                                                    "route": selectedIncident.location.route,
                                                    "freeway": selectedIncident.location.freeway,
                                                    "crossStreet": selectedIncident.location.crossStreet,
                                                    "lanesBlocked": selectedIncident.location.lanesBlocked,
                                                    "travelDirections": selectedIncident.location.travelDirection,
                                                    "isCritical": selectedIncident.isCritical
                                                },
                                                "timeLocation")} />
                                        </Box>
                                    </Grid>

                                    {/* Display incident time and location */}
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                                <List >
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Date"
                                                            primary={<b>Date</b>}
                                                            secondary={selectedIncident.time?.date || ""}
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Day of Week"
                                                            primary={<b>Day of Week</b>}
                                                            secondary={selectedIncident.time?.dayOfWeek || ""}
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Start Time"
                                                            primary={<b>Start Time</b>}
                                                            secondary={selectedIncident.time?.startTime
                                                                ? getFormattedTime(new Date(selectedIncident.time.startTime))
                                                                : ""
                                                            }
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="End Time"
                                                            primary={<b>End Time</b>}
                                                            secondary={selectedIncident.completedOn
                                                                ? getFormattedTime(new Date(selectedIncident.completedOn))
                                                                : ""
                                                            }
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                                <List >
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Route"
                                                            primary={<b>Route</b>}
                                                            secondary={selectedIncident.location?.route ? selectedIncident.location?.route["name"] : ""}
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Freeway"
                                                            primary={<b>Freeway</b>}
                                                            secondary={selectedIncident.location?.freeway ? selectedIncident.location?.freeway["name"] : ""}
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Cross Street"
                                                            primary={<b>Cross Street</b>}
                                                            secondary={selectedIncident.location?.crossStreet ? selectedIncident.location?.crossStreet["name"] : ""}
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Travel Direction(s)"
                                                            primary={<b>Travel Direction(s)</b>}
                                                            secondary={selectedIncident.location?.travelDirection?.length > 0
                                                                ? selectedIncident.location?.travelDirection.join(", ")
                                                                : ""}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                                <List >
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Lane(s) Blocked"
                                                            primary={<b>Lane(s) Blocked</b>}
                                                            secondary={selectedIncident.location?.lanesBlocked?.length > 0
                                                                ? selectedIncident.location?.lanesBlocked?.map(lane => lane.name).join(", ")
                                                                : ""}
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Detect Method"
                                                            primary={<b>Detect Method</b>}
                                                            secondary={selectedIncident.time?.detectMethod || ""}
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            aria-label="Critical Incident"
                                                            primary={<b>Critical Incident</b>}
                                                            secondary={selectedIncident.isCritical ? "Yes" : "No"}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {selectedIncident?.vehicles?.length > 0 && selectedIncident.vehicles.map((vehicle, index) => (
                                        <React.Fragment key={index}>

                                            {/* Vehicle Information heading */}
                                            <Grid item xs={12}>
                                                <Box display="flex">
                                                    <SectionTitle title={`Vehicle ${index + 1} Information`} isSubTitle={true} />
                                                    <CustomEditButton tooltipTitle="Edit" onClick={() => handleEditClick(`Vehicle ${index + 1} Information`,
                                                        {
                                                            "selectedVehicle": selectedIncident.vehicles[index],
                                                            "vehicleNumber": index + 1
                                                        },
                                                        "vehicleInfo")} />
                                                </Box>
                                            </Grid>

                                            {/* Display vehicle information */}
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sm={4} md={4} lg={4}>
                                                        <List >
                                                            <ListItem>
                                                                <ListItemText
                                                                    aria-label="Model"
                                                                    primary={<b>Model</b>}
                                                                    secondary={vehicle.model || ""}
                                                                />
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemText
                                                                    aria-label="Make"
                                                                    primary={<b>Make</b>}
                                                                    secondary={vehicle.make || ""}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                    <Grid item xs={6} sm={4} md={4} lg={4}>
                                                        <List >
                                                            <ListItem>
                                                                <ListItemText
                                                                    aria-label="Color"
                                                                    primary={<b>Color</b>}
                                                                    secondary={vehicle.color || ""}
                                                                />
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemText
                                                                    aria-label="Year"
                                                                    primary={<b>Year</b>}
                                                                    secondary={vehicle.year || ""}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <List >
                                                            <ListItem>
                                                                <ListItemText
                                                                    aria-label="License Plate Number"
                                                                    primary={<b>License Plate Number</b>}
                                                                    secondary={vehicle.licensePlateNum || ""}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* Assist Information heading */}
                                            <Grid item xs={12}>
                                                <SectionTitle title={`Assist Information`} isSubTitle={true} />
                                            </Grid>

                                            {/* Display assist information */}
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <List >
                                                            <ListItem>
                                                                <ListItemText
                                                                    aria-label="Type of Assist"
                                                                    primary={<b>Type of Assist</b>}
                                                                    secondary={vehicle.typeOfAssist?.name || ""}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <List >
                                                            <ListItem>
                                                                <ListItemText
                                                                    aria-label="Service Provided"
                                                                    primary={<b>Service Provided</b>}
                                                                    secondary={vehicle.serviceProvided?.length > 0
                                                                        ? vehicle.serviceProvided?.map(service => service.name).join(", ")
                                                                        : ""}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                    {vehicle.gallonsGiven !== 0 && (
                                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                                            <List >
                                                                <ListItem>
                                                                    <ListItemText
                                                                        aria-label="Gallons Given"
                                                                        primary={<b>Gallons Given</b>}
                                                                        secondary={vehicle.gallonsGiven}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>

                                            {/* Comments heading */}
                                            <Grid item xs={12}>
                                                <SectionTitle title="Comments" isSubTitle={true} />
                                            </Grid>

                                            {/* Display comments */}
                                            <Grid item xs={12}>
                                                <textarea
                                                    rows={2}
                                                    value={vehicle.comments || ""}
                                                    readOnly
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontSize: '0.875rem',
                                                        marginRight: "8px",
                                                        marginLeft: "17px"
                                                    }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ))}

                                    {filteredImages.length > 0 && (
                                        <>
                                            {/* Images heading */}
                                            <Grid item xs={12}>
                                                <Box display="flex">
                                                    <SectionTitle title="Images" isSubTitle={true} />
                                                    <CustomEditButton tooltipTitle="Edit" onClick={() => handleEditClick("Images", filteredImages, "images")} />
                                                </Box>
                                            </Grid>

                                            {/* Display images */}
                                            {filteredImages.map((imgs, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                                            <List >
                                                                <ListItem>
                                                                    <ListItemText
                                                                        aria-label={`Image(s) type`}
                                                                        primary={<b>{`Image(s) type`}</b>}
                                                                        secondary={imgs[0]?.type || ""}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                                            <List >
                                                                <ListItem>
                                                                    <ListItemText
                                                                        aria-label={`Image(s) uploaded`}
                                                                        primary={<b>Image(s) uploaded</b>}
                                                                        secondary={imgs.length}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            ))}
                                        </>
                                    )}

                                    {/* Closing comments for closed incidents */}
                                    {selectedIncident.status === "closed" && (
                                        <>
                                            <Grid item xs={12}>
                                                <Box display="flex">
                                                    <SectionTitle title="Comments" isSubTitle={true} />
                                                    <CustomEditButton tooltipTitle="Edit" onClick={() => handleEditClick("Comments", { "closingComments": selectedIncident.closingComments }, "closingComments")} />
                                                </Box>
                                            </Grid>
                                            <StyledGrid item xs={12}>
                                                <Typography>{selectedIncident.closingComments}</Typography>
                                            </StyledGrid>
                                        </>
                                    )}

                                    {/* Buttons */}
                                    <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '18px' }}>

                                        {/* Cancel Button */}
                                        <StyledButton variant="contained" onClick={() => { navigate(-1) }}>
                                            {GenericConstants.CANCEL_BTN}
                                        </StyledButton>

                                        {/* Save Button */}
                                        <StyledButton variant="contained" onClick={() => { handleSaveBtnClick() }} sx={{ marginLeft: "10px" }}>
                                            {GenericConstants.SAVE_BTN}
                                        </StyledButton>

                                    </Grid>

                                    {/* Edit Time Location Dialog */}
                                    <EditSelectedIncidentDialog
                                        open={isDialogOpen}
                                        onClose={handleDialogClose}
                                        dialogTitle={dialogTitle}
                                        dialogData={dialogData}
                                        dialogType={dialogType}
                                        onSave={handleDialogSave}
                                        dropDownValues={{ incidentDrpDwnValues, allRoutesInfo }}
                                    />
                                </>
                            )}
                        </>)}
                </Grid>
            </StyledCardContent >
        </StyledCard >
    )
}