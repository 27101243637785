import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Alert, List, ListItem, ListItemText } from "@mui/material";
import useStyles from "./styles";
import {
    GridActionsCellItem
} from "@mui/x-data-grid";
import {
    Edit as EditIcon,
} from "@mui/icons-material";

//components
import PageTitle from "../../components/Titles/PageTitle";
import { getFormattedTime } from "../../utils/GetFormattedDateTimeDay";
import CustomToolbar from "../../components/CustomDataGridOptions/CustomToolbar";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";

//constants
import { PatrollerConstants } from '../../utils/Constants';

//context
import { usePatrollerData } from "../../context/PatrollerContext";
import { useNetworkStatus } from "../../context/NetworkStatusContext";

//themes
import themes from "../../theme/defaultTheme";

export default function ViewCompleteIncidents() {
    const { StyledCard, StyledButton, StyledGrid, StyledCardContent, StyledDataGrid, StyledChip } = useStyles();
    const { incidentsData } = usePatrollerData();
    const isOffline = useNetworkStatus()
    const [completeIncidents, setCompleteIncidents] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [shiftData, setShiftData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        //set shift info
        const loggedinUser = JSON.parse(sessionStorage.getItem("user"));
        if (loggedinUser)
            setShiftData(loggedinUser?.shift || {});

        //set incidents data
        if (incidentsData?.length > 0) {
            setLoading(true);
            const filteredIncidents = incidentsData.filter(incident => incident.status.toLowerCase() === "complete" || incident.status.toLowerCase() === "closed").map(incident => ({
                id: incident.id,
                startTime: incident.time.startTime ? getFormattedTime(new Date(incident.time.startTime)) : "",
                route: incident.location?.route?.name || "",
                freeway: incident.location?.freeway?.name || "",
                crossStreet: incident.location?.crossStreet?.name || "",
                endTime: incident.completedOn ? getFormattedTime(new Date(incident.completedOn)) : "",
                status: incident.status
            }));
            setCompleteIncidents(filteredIncidents);
            setLoading(false)
        }
    }, [incidentsData]);

    let columns = [
        //Show actions
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 120,
            getActions: (params) => {
                const incidentId = params.row.id;
                const actions = [];
                actions.push(
                    <GridActionsCellItem
                        disabled={isOffline}
                        aria-label="Edit Action"
                        icon={<IconsWithTooltip title="Edit Incident" 
                            icon={<EditIcon 
                                sx={{ color: navigator.onLine ? 
                                themes.default.palette.primary.contrastText:
                                themes.default.palette.secondary.light}} 
                            />} 
                        />}
                        label="Edit"
                        onClick={() => { navigate('/patroller/edit-complete-incident', { state: { incidentId } }) }}
                    />
                )
                return actions;
            }
        },
        //Show status.
        { field: "status", headerName: "Status", width: 120 },

        //Show route.
        { field: "route", headerName: "Route", width: 180 },

        //Show freeway.
        { field: "freeway", headerName: "Freeway", width: 220 },

        //Show cross street.
        { field: "crossStreet", headerName: "Cross Street", width: 220 },

        //Show incident start time
        { field: "startTime", headerName: "Start Time", width: 140 },

        //Show incident end time
        { field: "endTime", headerName: "End Time", width: 140 },
    ];

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* View open incidents link */}
                    <StyledGrid item xs={12} sm={5} md={5} lg={5}>
                        <StyledChip label="View Open Incidents" onClick={() => navigate('/patroller/view-open-incidents')} />
                    </StyledGrid>

                    {/* Page Title */}
                    <Grid item xs={12} sm={7} md={7} lg={7} sx={{ display: 'flex', alignItems: "center" }}>
                        <PageTitle aria-label="View Complete Incidents" title="View Complete Incidents" />
                    </Grid>

                    {/* Display Shift Information */}
                    {shiftData && Object.keys(shiftData).length > 0 && (
                        <Grid item xs={12} sx={{ paddingLeft: "5px", paddingTop: "10px" }}>
                            <List style={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid item xs={12} sm={3} md={3} lg={3}>
                                    <ListItem>
                                        <ListItemText
                                            aria-label="Truck Number"
                                            primary={<b>Truck Number</b>}
                                            secondary={shiftData.truckNumber || ""}
                                        />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} lg={3}>
                                    <ListItem>
                                        <ListItemText
                                            aria-label="Phone Number"
                                            primary={<b>Phone Number</b>}
                                            secondary={shiftData.phoneNumber || ""}
                                        />
                                    </ListItem>
                                </Grid>

                                <Grid item xs={12} sm={3} md={3} lg={3}>
                                    <ListItem>
                                        <ListItemText
                                            aria-label="Primary Patroller"
                                            primary={<b>Primary Patroller</b>}
                                            secondary={shiftData.loggedInPatroller || ""}
                                        />
                                    </ListItem>
                                </Grid>

                                <Grid item xs={12} sm={3} md={3} lg={3}>
                                    <ListItem>
                                        <ListItemText
                                            aria-label="Other Patroller(s)"
                                            primary={<b>Other Patroller(s)</b>}
                                            secondary={shiftData.otherPatrollers?.join(", ") || ""}
                                        />
                                    </ListItem>
                                </Grid>
                            </List>
                        </Grid>
                    )}

                    {/* Alert */}
                    <StyledGrid item xs={12}>
                        <Alert severity="info">Below are complete/closed incidents for current shift only.</Alert>
                    </StyledGrid>
                    {isOffline && 
                        <StyledGrid item xs={12}>
                            <Alert severity="error">You're offline, so some incidents may not be shown, and editing may be unavailable.</Alert>
                        </StyledGrid>}

                    {/* Table */}
                    <StyledGrid container>
                        <StyledDataGrid
                            aria-label="Incidents Data Table"
                            loading={loading}
                            rows={completeIncidents}
                            columns={columns}
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[10, 15, 20, 25]}
                            slots={{
                                toolbar: CustomToolbar
                            }}
                            slotProps={{
                                toolbar: { recordsLength: completeIncidents.length }
                            }}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setSelectedRows(newRowSelectionModel);
                            }}
                            rowSelectionModel={selectedRows}
                        />
                    </StyledGrid>

                    {/* Add New Incident button */}
                    <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '18px' }}>
                        <StyledButton variant="contained" onClick={() => { navigate('/patroller/add-incident') }}>
                            {PatrollerConstants.ADD_INCIDENT_BTN}
                        </StyledButton>
                    </Grid>
                </Grid>
            </StyledCardContent>
        </StyledCard >
    )
}