import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Select,
  Grid,
  IconButton,
  Tooltip,
  InputAdornment,
  Button
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useStyles from "./styles";

//icons
import {
  ContentPaste as CopyPwdIcon,
  Replay as RegeneratePwdIcon,
  PasswordOutlined as PwdIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@mui/icons-material";

//constants
import { AdminConstants } from "../../utils/Constants";

//Generate password function
import CustomPasswordGenerator from "../../utils/CustomPasswordGenerator";
import themes from "../../theme/defaultTheme";

export default function ManageInfoDialog({
  open,
  dialogFields,
  dialogFieldValues,
  mandatoryFields,
  dialogType,
  onClose,
  onConfirm,
  isTruckDialog
}) {
  const { StyledDialogButton, StyledLabel, StyledInputRequired, StyledDialogTitle, AdditionalInfoLabel } = useStyles();
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  // Determine status options and default status based on isTruckDialog
  const defaultStatus = isTruckDialog ? "in service" : "active";
  const statusOptions = isTruckDialog ? AdminConstants.TRUCK_STATUS : AdminConstants.STATUS;
  const defaultRole = ["patroller"];

  // Update local state when dialogFieldValues changes
  useEffect(() => {
    if (dialogFields?.length > 0) {
      const getDefaultValue = (accessorKey) => {
        if (accessorKey === "status")
          return isTruckDialog ? "in service" : "active";
        else if (accessorKey === "roles") {
          return ["patroller", "dispatcher"];
        }
        return "";
      };

      const initialValues = dialogFields.reduce((acc, field) => {
        acc[field.accessorKey] = dialogFieldValues?.[field.accessorKey] || getDefaultValue(field.accessorKey);
        return acc;
      }, {});

      setFormValues(initialValues);
    }
  }, [dialogFieldValues, dialogFields, isTruckDialog]);

  // Handler for input changes
  const handleInputChange = (accessorKey, event) => {
    const value = event.target.value;
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };

      if (accessorKey === "email") {
        updatedValues[accessorKey] = value;
        updatedValues['username'] = value?.split('@')[0] || "";
      } else if (accessorKey === "roles") {
        updatedValues[accessorKey] = value.length === 0 ? defaultRole : value;
      } else {
        updatedValues[accessorKey] = value;
      }
      return updatedValues;
    });
  };

  //validate input in dialog fields
  const validateInput = () => {
    const newErrors = {};

    //check integer validation for year in truck dialog
    if (isTruckDialog) {
      const yearValue = formValues.year?.trim();
      if (yearValue) {
        const isValidInput = /^\d+$/.test(yearValue);
        if (!isValidInput)
          newErrors["year"] = true;
      }
    }

    //check if mandatory fields exist in form values
    mandatoryFields.forEach(field => {
      if (!Object.keys(formValues).includes(field) || !formValues[field])
        newErrors[field] = true
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  }

  const handleConfirm = () => {
    if (validateInput()) {
      if (isTruckDialog) {
        let updatedFormValues = { ...formValues };
        updatedFormValues.year = updatedFormValues.year ? parseInt(updatedFormValues.year, 10) : null;
        onConfirm(updatedFormValues, dialogType);
      }
      else
        onConfirm(formValues, dialogType);
      setFormValues({});
    }
  }

  return (
    <Dialog aria-label="Add or Edit Dialog" open={open} onClose={onClose}>
      <StyledDialogTitle>{dialogType === "add" ? "Add" : "Edit"}</StyledDialogTitle>
      <DialogContent>
        <Grid container>
          {dialogFields?.length > 0 && dialogFields.map(field => (
            <Grid container key={field.accessorKey} spacing={3} sx={{ padding: "14px" }}>
              <Grid item xs={5}>
                <StyledLabel>
                  {field.header}
                  {mandatoryFields.includes(field.accessorKey) && (
                    <em style={{ color: 'red' }}> *</em>
                  )}
                </StyledLabel>
                {field.accessorKey === "roles" && (<AdditionalInfoLabel>Select one or more</AdditionalInfoLabel>)}
              </Grid>
              <Grid item xs={7}>
                {(() => {
                  switch (field.accessorKey) {
                    case "status":
                      return (
                        <Select
                          value={formValues[field.accessorKey] || defaultStatus}
                          onChange={event => handleInputChange(field.accessorKey, event)}
                          fullWidth
                        >
                          {statusOptions?.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    case "roles":
                      return (
                        <Select
                          value={formValues[field.accessorKey] || defaultRole}
                          onChange={event => handleInputChange(field.accessorKey, event)}
                          fullWidth
                          multiple
                          renderValue={(selected) => selected.join(',')}
                        >
                          {AdminConstants.ROLES?.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    case "password":
                      return (
                        <Grid container spacing={2} alignItems="center">
                          {dialogType === "add" ? (
                            <>
                              <Grid item xs={8}>
                                <TextField
                                  type={showPassword ? "text" : "password"}
                                  value={formValues[field.accessorKey] || ""}
                                  onChange={(event) =>
                                    handleInputChange(field.accessorKey, event)
                                  }
                                  fullWidth
                                  error={!!errors[field.accessorKey]}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => { setShowPassword(!showPassword) }}
                                          edge="end"
                                          sx={{ color: themes.default.palette.primary.contrastText }}
                                        >
                                          {showPassword ? (
                                            <Tooltip title="Hide password">
                                              <VisibilityOffIcon />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip title="Show password">
                                              <VisibilityIcon />
                                            </Tooltip>
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={errors[field.accessorKey] ? { '& .MuiInputBase-root': { borderColor: 'red' } } : {}}
                                />
                                {errors[field.accessorKey] && <StyledInputRequired>Required field</StyledInputRequired>}
                              </Grid>
                              <Grid item xs={4}>
                                <CopyToClipboard text={formValues[field.accessorKey]}>
                                  <IconButton sx={{ color: themes.default.palette.primary.contrastText }}>
                                    <Tooltip title="Copy to clipboard">
                                      {<CopyPwdIcon style={{ fontSize: 20 }} />}
                                    </Tooltip>
                                  </IconButton>
                                </CopyToClipboard>

                                {formValues[field.accessorKey] === "" ? (
                                  <IconButton
                                    onClick={(event) => {
                                      const newPassword = CustomPasswordGenerator();
                                      handleInputChange(
                                        field.accessorKey,
                                        {
                                          target: {
                                            value: newPassword,
                                          },
                                        }
                                      );
                                    }}
                                    sx={{ color: themes.default.palette.primary.contrastText }}
                                  >
                                    <Tooltip title="Generate password">
                                      {<PwdIcon style={{ fontSize: 20 }} />}
                                    </Tooltip>
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      const newPassword = CustomPasswordGenerator();
                                      handleInputChange(
                                        field.accessorKey,
                                        {
                                          target: {
                                            value: newPassword,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    <Tooltip title="Generate new password">
                                      {<RegeneratePwdIcon style={{ fontSize: 20, color: themes.default.palette.primary.contrastText }} />}
                                    </Tooltip>
                                  </IconButton>
                                )
                                }
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={9} sx={{ display: "flex" }}>
                                <TextField
                                  label="Enter new password"
                                  type={showPassword ? "text" : "password"}
                                  value={formValues[field.accessorKey] || ""}
                                  onChange={(event) =>
                                    handleInputChange(field.accessorKey, event)
                                  }
                                  fullWidth
                                  error={!!errors[field.accessorKey]}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => { setShowPassword(!showPassword) }}
                                          edge="end"
                                          sx={{ color: themes.default.palette.primary.contrastText }}
                                        >
                                          {showPassword ? (
                                            <Tooltip title="Hide password">
                                              <VisibilityOffIcon />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip title="Show password">
                                              <VisibilityIcon />
                                            </Tooltip>
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />

                                {/* Copy to clipboard */}
                                {formValues[field.accessorKey] && (
                                  <CopyToClipboard text={formValues[field.accessorKey]}>
                                    <IconButton sx={{ color: themes.default.palette.primary.contrastText }}>
                                      <Tooltip title="Copy to clipboard">
                                        {<CopyPwdIcon style={{ fontSize: 20 }} />}
                                      </Tooltip>
                                    </IconButton>
                                  </CopyToClipboard>
                                )}
                              </Grid>
                              <Grid item xs={3} sx={{ paddingLeft: 0 }}>
                                <span>or</span>

                                {/* Generate new password button */}
                                <IconButton
                                  onClick={() => {
                                    const newPassword = CustomPasswordGenerator();
                                    handleInputChange(
                                      field.accessorKey,
                                      {
                                        target: {
                                          value: newPassword,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  <Tooltip title="Generate new password">
                                    {<RegeneratePwdIcon style={{ fontSize: 20, color: themes.default.palette.primary.contrastText }} />}
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      );
                    default:
                      return (
                        <>
                          <TextField
                            variant="outlined"
                            label={field.accessorKey === "username" ? "" : "Enter a value"}
                            value={formValues[field.accessorKey] || ""}
                            disabled={field.accessorKey === "username"}
                            onChange={(event) => handleInputChange(field.accessorKey, event)}
                            fullWidth
                            error={!!errors[field.accessorKey]}
                            sx={errors[field.accessorKey] ? { '& .MuiInputBase-root': { borderColor: 'red' } } : {}}
                          />
                          {errors[field.accessorKey] && field.accessorKey !== "year"
                            ? <StyledInputRequired>Required field</StyledInputRequired>
                            : errors[field.accessorKey] && <StyledInputRequired>Input only numbers</StyledInputRequired>}
                        </>
                      );
                  }
                })()}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <StyledDialogButton aria-label="Close"
          onClick={() => { setFormValues({}); onClose() }}
        >
          Cancel
        </StyledDialogButton>
        <StyledDialogButton aria-label="Confirm"
          onClick={handleConfirm}
          color="primary">
          {dialogType === "add" ? "Add" : "Edit"}
        </StyledDialogButton>
      </DialogActions>
    </Dialog>
  );
}