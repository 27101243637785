import { styled } from "@mui/system";
import { Typography, Button } from "@mui/material";
import themes from "../../theme/defaultTheme";

export default function useStyles() {
    const StyledTypography = styled(Typography)({
        color: themes.default.palette.primary.light,
        marginLeft: '30px !important'
    });

    const StyledButton = styled(Button)({
        color: themes.default.palette.primary.light,
        borderColor: themes.default.palette.primary.light,
        textTransform: 'none !important',
        marginRight: '30px !important'
    });

    return { StyledTypography, StyledButton };
}