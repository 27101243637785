import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { HighlightOff as CancelIcon } from "@mui/icons-material";

//components
import { AddIncidentForm } from "../../components/Dispatcher/AddIncidentForm";

//context
import { useDispatcherData } from "../../context/DispatcherContext";

export default function OpenIncident() {
    const { incidentDrpDwnValues, allRoutesInfo } = useDispatcherData();
    const { StyledCancelButton } = useStyles();
    const navigate = useNavigate();

    return (
        <Grid container>

            {/* Cancel button */}
            <Grid item xs={12} >
                <StyledCancelButton sx={{ marginLeft: 4, marginBottom: 0, paddingBottom: 0 }} startIcon={<CancelIcon />} onClick={() => { navigate(-1) }}>Cancel</StyledCancelButton>
            </Grid>

            {/* Form */}
            <Grid item xs={12}>
                <AddIncidentForm dropDownValues={incidentDrpDwnValues} allRoutes={allRoutesInfo} />
            </Grid>
        </Grid>
    );
}