import { useState } from "react";
import {
    Alert,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Select,
    MenuItem,
    Tooltip,
    IconButton
} from "@mui/material";
import useStyles from "./styles";

//components
import { getFormattedDate, getFormattedTime } from "../../utils/GetFormattedDateTimeDay";
import { ConfirmationDialog } from "./ConfirmationDialog";

//constants
import { PatrollerConstants } from "../../utils/Constants";

//icons
import { FileUploadOutlined as UploadIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';

//theme
import themes from "../../theme/defaultTheme";

//context
import { useNetworkStatus } from "../../context/NetworkStatusContext";

export default function VehicleRefuelingDialog({ open, onClose, onSave, title, uploadedByName }) {
    const { StyledDialogButton, StyledLabel, StyledDialogTitle, StyledInputRequired } = useStyles();
    const [milage, setMilage] = useState('');
    const [fuel, setFuel] = useState('');
    const [fuelType, setFuelType] = useState('');
    const currentDateTime = new Date();
    const formattedDate = getFormattedDate(currentDateTime);
    const formattedTime = getFormattedTime(currentDateTime);
    const [errors, setErrors] = useState({});
    const [images, setImages] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const isOffline = useNetworkStatus();

    const handleChange = (event, fieldName) => {
        const { value } = event.target;

        if (fieldName === 'milage') {
            setMilage(value);
        } else if (fieldName === 'fuel') {
            setFuel(value);
        }
        // Validate input to allow only numbers
        const isValidInput = /^\d*\.?\d*$/.test(value);
        let errorMsg = '';
        if (!isValidInput)
            errorMsg = 'Invalid input - enter only numbers';
        setErrors(prevErrors => ({ ...prevErrors, [fieldName]: errorMsg }));
    };

    //Upload image.
    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);

        const filePromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve({
                        file: reader.result, // base64 string
                        fileName: file.name,
                        previewUrl: URL.createObjectURL(file),
                    });
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
        try {
            const newImages = await Promise.all(filePromises);
            setImages([...newImages]);
        } catch (error) {
            console.error("Error reading file:", error);
        }
    };

    //Validate dialog fields.
    const validateFields = () => {
        const errors = {};
        const isValidMilage = /^\d*\.?\d*$/.test(milage);
        const isValidFuel = /^\d*\.?\d*$/.test(fuel);
        if (!milage)
            errors["milage"] = 'Required field';
        if (!isValidMilage)
            errors["milage"] = 'Invalid input - enter only numbers';
        if (!fuel)
            errors["fuel"] = 'Required field';
        if (!isValidFuel)
            errors["fuel"] = 'Invalid input - enter only numbers';
        if (!fuelType)
            errors["fuelType"] = 'Required field';
        if (images.length === 0)
            errors["images"] = 'Required field';

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }
        return true;
    };

    //Triggered when save button is clicked.
    const handleSave = () => {

        //Validate input
        if (validateFields()) {
            const data = {
                "mileage": parseInt(milage),
                "gallonsOfFuel": parseInt(fuel),
                "dateTime": currentDateTime,
                "fuelType": fuelType,
                "receiptImages": images
            }
            onSave(data);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <StyledDialogTitle>{title}</StyledDialogTitle>
            {isOffline && <Grid>
                <StyledInputRequired variant="body2" color="error">
                    <Alert severity="error">This feature is currently unavailable. Please try submitting again when you're back online.</Alert>
                </StyledInputRequired>
            </Grid>}
            <DialogContent>
                <Grid container spacing={2} sx={{ paddingBottom: 5 }}>

                    {/* Milage */}
                    <Grid container spacing={15} sx={{ px: 3, pt: 4 }}>
                        <Grid item xs={5}>
                            <StyledLabel>Milage<em style={{ color: 'red' }}> *</em></StyledLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                autoFocus
                                name="milage"
                                value={milage}
                                onChange={(event) => handleChange(event, 'milage')}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Only accept numeric input
                                sx={errors['milage']
                                    ? { borderColor: 'red', borderWidth: '1px', borderStyle: 'solid', borderRadius: '4px' }
                                    : {}}
                            />
                            {errors?.['milage'] && (
                                <StyledInputRequired variant="body2" color="error">
                                    {errors['milage']}
                                </StyledInputRequired>
                            )}
                        </Grid>
                    </Grid>

                    {/* Gallons of fuel */}
                    <Grid container spacing={15} sx={{ px: 3, pt: 4 }}>
                        <Grid item xs={5}>
                            <StyledLabel>Gallons of fuel<em style={{ color: 'red' }}>*</em></StyledLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                name="fuel"
                                value={fuel}
                                onChange={(event) => handleChange(event, 'fuel')}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Only accept numeric input
                                sx={errors['fuel']
                                    ? { borderColor: 'red', borderWidth: '1px', borderStyle: 'solid', borderRadius: '4px' }
                                    : {}}
                            />
                            {errors?.['fuel'] && (
                                <StyledInputRequired variant="body2" color="error">
                                    {errors['fuel']}
                                </StyledInputRequired>
                            )}
                        </Grid>
                    </Grid>

                    {/* Fuel Type */}
                    <Grid container spacing={15} sx={{ px: 3, pt: 4 }}>
                        <Grid item xs={5}>
                            <StyledLabel>Fuel type<em style={{ color: 'red' }}>*</em></StyledLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <Select
                                value={fuelType || ""}
                                error={!!errors['fuelType']}
                                sx={errors['fuelType']
                                    ? { width: '92%', borderColor: 'red', borderWidth: '1px', borderStyle: 'solid', borderRadius: '4px' }
                                    : { width: '92%' }}
                                onChange={event => setFuelType(event.target.value)}
                            >
                                {PatrollerConstants?.FUEL_TYPE?.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors?.['fuelType'] && (
                                <StyledInputRequired variant="body2" color="error">
                                    {errors['fuelType']}
                                </StyledInputRequired>
                            )}
                        </Grid>
                    </Grid>

                    {/* Receipt Image */}
                    <Grid container sx={{ px: 3, pt: 6, pb: 2 }}>

                        {/* Label */}
                        <Grid item xs={4}>
                            <StyledLabel>Receipt image<em style={{ color: 'red' }}>*</em></StyledLabel>
                        </Grid>

                        {/* Image preview */}
                        <Grid item xs={6} sx={{ paddingLeft: 12 }}>
                            <Tooltip title="Click to preview image(s)">
                                <div>
                                    <StyledDialogButton sx={{ paddingLeft: 0, paddingTop: 1 }}
                                        disabled={images.length === 0}
                                        onClick={() => setPreviewOpen(true)}>
                                        {images.length === 0 ? 'No file(s) uploaded'
                                            : `${images.length} file(s) uploaded`}
                                    </StyledDialogButton>
                                </div>
                            </Tooltip>
                            {errors?.['images'] && (
                                <StyledInputRequired variant="body2" color="error">
                                    {errors['images']}
                                </StyledInputRequired>
                            )}
                        </Grid>

                        {/* Upload image(s) button */}
                        <Grid item xs={1}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id={`upload-img`}
                                type="file"
                                multiple
                                onChange={event => handleFileChange(event)}
                            />
                            <label htmlFor={`upload-img`}>
                                <Tooltip title="Click to upload image(s)">
                                    <IconButton component="span" aria-label="upload image">
                                        <UploadIcon style={{ color: themes.default.palette.primary.contrastText }} />
                                    </IconButton>
                                </Tooltip>
                            </label>
                        </Grid>

                        {/* Delete image(s) button */}
                        {images.length > 0 && (
                            <Grid item xs={1}>
                                <Tooltip title="Delete uploaded images">
                                    <IconButton onClick={() => {
                                        setIsDialogOpen(true);
                                    }} aria-label="delete image">
                                        <DeleteIcon style={{ color: themes.default.palette.error.main }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>

                    {/* Date */}
                    <Grid container spacing={15} sx={{ px: 3, pt: 4 }}>
                        <Grid item xs={5}>
                            <StyledLabel>Date</StyledLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                name="date"
                                value={formattedDate}
                                disabled
                            />
                        </Grid>
                    </Grid>

                    {/* Time */}
                    <Grid container spacing={15} sx={{ px: 3, pt: 4 }}>
                        <Grid item xs={5}>
                            <StyledLabel>Time</StyledLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                name="time"
                                value={formattedTime}
                                disabled
                            />
                        </Grid>
                    </Grid>

                    {/* Uploaded by */}
                    <Grid container spacing={15} sx={{ px: 3, pt: 4 }}>
                        <Grid item xs={5}>
                            <StyledLabel>Uploaded by</StyledLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                name="uploadedBy"
                                value={uploadedByName}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            {/* Delete Image Confirmation Dialog */}
            <ConfirmationDialog
                open={isDialogOpen}
                title="Delete Confirmation"
                content="Are you sure you want to delete uploaded image(s)?"
                onConfirm={() => {
                    setImages([]);
                    setIsDialogOpen(false)
                }}
                onClose={() => { setIsDialogOpen(false) }}
            />

            {/* Preview Image Dialog */}
            <Dialog open={previewOpen} onClose={() => { setPreviewOpen(false) }}>
                <StyledDialogTitle>Preview Image(s)</StyledDialogTitle>
                <DialogContent>
                    {images.map((image, index) => (
                        <img key={index}
                            src={image.previewUrl}
                            alt={`Preview image ${index + 1}`}
                            style={{ width: '100%', marginBottom: '10px' }} />
                    ))}
                </DialogContent>
                <DialogActions>
                    <StyledDialogButton onClick={() => { setPreviewOpen(false) }}>
                        Close
                    </StyledDialogButton>
                </DialogActions>
            </Dialog>
            <DialogActions>
                <StyledDialogButton onClick={() => onClose(null)}>Cancel</StyledDialogButton>
                <StyledDialogButton onClick={() => handleSave()}>Save</StyledDialogButton>
            </DialogActions>
        </Dialog >
    );
}