
//Generate a 14 charatcer length password consisting of uppercase, lowecase, number and special characters.
export default function CustomPasswordGenerator() {

    let charSet = '';
    for (let i = 0; i < 94; i++) {
        charSet += String.fromCharCode(33 + i);
    }

    //Create an array of size 14
    const values = new Uint8Array(14);

    //Populate 14 random numbers in the array
    window.crypto.getRandomValues(values);
    let password = '';
    const length = charSet.length;
    for (let i = 0; i < 14; i++) {
        password += charSet[values[i] % length];
    }
    return password;
}