import React, { createContext, useContext, useState, useEffect } from "react";

//custom hook
import { useFetch } from "../hooks/useFetch";

//context
import { useAuth } from "../context/AuthContext";

//constants
import { ApiEndpoints } from "../utils/Constants";

//custom logging
import { customLog } from "../utils/Logger";
import { customRequestLog } from "../utils/RequestLogger";

const PatrollerDataContext = createContext();

//Context specific for patroller role
export const PatrollerDataProvider = ({ children, componentName }) => {
  let [incidentsData, setIncidentsData] = useState([]);
  const [loggedInUsername, setLoggedInUsername] = useState([]);
  const [route, setRoute] = useState(null);
  const [shiftInfoDrpDwn, setShiftInfoDrpDwn] = useState({});
  const [incidentDrpDwnValues, setIncidentDrpDwnValues] = useState({});
  const [routeRelatedDrpDwnVal, setRouteRelatedDrpDwnVal] = useState([]);
  const { auth, logout } = useAuth();
  const getFetcher = useFetch("get"); //for all get requests
  const postFetcher = useFetch("post"); //for add, edit and delete requests

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (componentName === "ShiftInformation") {
          //get shift drop down values

          //set username from auth or session storage in case of page refresh
          const loggedInUser =
            auth && Object.keys(auth)?.length > 0
              ? auth
              : JSON.parse(sessionStorage.getItem("user"));
          setLoggedInUsername(
            loggedInUser?.firstName + " " + loggedInUser?.lastName || ""
          );

          const [shiftResponse, incidentDropdownResponse, routesResponse, patrollerIncidentsResponse] = await Promise.all([
            getFetcher.setRequest({
              endpoint: ApiEndpoints["API_GET_SHIFT_DROP_DWNS"],
              cacheToSessionStorage: true
            }),
            getFetcher.setRequest({
              endpoint: "/patroller" + ApiEndpoints["API_GET_ALL_INCIDENT_DROP_DWNS"],
              cacheToSessionStorage: true,
            }),
            getFetcher.setRequest({
              endpoint: "/patroller" + ApiEndpoints["API_GET_ALL_ROUTES_INFO"],
              cacheToSessionStorage: true,
            }),
            getFetcher.setRequest({
              endpoint: ApiEndpoints["API_GET_PATROLLER_INCIDENTS"],
              cacheToSessionStorage: true,
            })
          ]);

          if (incidentDropdownResponse && !incidentDropdownResponse.error && incidentDropdownResponse.data)
            setIncidentDrpDwnValues(incidentDropdownResponse.data);
          else if (incidentDropdownResponse.error && (incidentDropdownResponse.errorStatus || incidentDropdownResponse.errorMessage))
            customLog(
              "error",
              `Error ${incidentDropdownResponse.errorStatus || "unknown"}: ${incidentDropdownResponse.errorMessage || "No error message provided."
              }`
            );

          //handle routes drop-down values response
          if (routesResponse && !routesResponse.error && routesResponse?.data?.routes)
            setRouteRelatedDrpDwnVal(routesResponse.data.routes);
          else if (routesResponse.error && (routesResponse.errorStatus || routesResponse.errorMessage))
            customLog(
              "error",
              `Error ${routesResponse.errorStatus || "unknown"}: ${routesResponse.errorMessage || "No error message provided."
              }`
            );

          if (shiftResponse) {
            if (!shiftResponse.error && shiftResponse.data) {
              //if no error
              setShiftInfoDrpDwn(shiftResponse.data);
            }
            else if (shiftResponse.error) {
              if (shiftResponse.errorStatus || shiftResponse.errorMessage)
                customLog(
                  "error",
                  `Error ${shiftResponse.errorStatus || "unknown"}: ${shiftResponse.errorMessage || "No error message provided."
                  }`
                );
              if (shiftResponse.errorStatus === 403 || shiftResponse.errorStatus === 401)
                //logout if forbidden or unauthorized
                logout();
            }
          } else
            customLog(
              "error",
              `No response for ${ApiEndpoints["API_GET_SHIFT_DROP_DWNS"]}`
            );

          if (patrollerIncidentsResponse) {
            if (!patrollerIncidentsResponse.error && patrollerIncidentsResponse?.data?.incidents)//if no error
              setIncidentsData(patrollerIncidentsResponse.data.incidents);
            else if (patrollerIncidentsResponse.error) {
              if (patrollerIncidentsResponse.errorStatus || patrollerIncidentsResponse.errorMessage)
                customLog(
                  "error",
                  `Error ${patrollerIncidentsResponse.errorStatus || "unknown"}: ${patrollerIncidentsResponse.errorMessage || "No error message provided."
                  }`
                );
              if (patrollerIncidentsResponse.errorStatus === 403 || patrollerIncidentsResponse.errorStatus === 401) //logout if forbidden or unauthorized
                logout();
            }
          } else
            customLog(
              "error",
              `No response for ${ApiEndpoints["API_GET_PATROLLER_INCIDENTS"]}`
            );
        }
        else if (componentName === "ViewOpenIncidents" || componentName === "ViewCompleteIncidents") {//get complete, open, and closed incidents for patroller
          const response = await getFetcher.setRequest({
            endpoint: ApiEndpoints["API_GET_PATROLLER_INCIDENTS"],
            cacheToSessionStorage: true,
          });
          if (response) {
            if (!response.error && response?.data?.incidents)//if no error
              setIncidentsData(response.data.incidents);
            else if (response.error) {
              if (response.errorStatus || response.errorMessage)
                customLog(
                  "error",
                  `Error ${response.errorStatus || "unknown"}: ${response.errorMessage || "No error message provided."
                  }`
                );
              if (response.errorStatus === 403 || response.errorStatus === 401) //logout if forbidden or unauthorized
                logout();
            }
          } else
            customLog(
              "error",
              `No response for ${ApiEndpoints["API_GET_PATROLLER_INCIDENTS"]}`
            );
        } else if (componentName === "AddIncident" || componentName === "EditCompleteIncident") {//get incident drop down values
          const [incidentResponse, routesResponse] = await Promise.all([
            getFetcher.setRequest({
              endpoint: "/patroller" + ApiEndpoints["API_GET_ALL_INCIDENT_DROP_DWNS"],
              cacheToSessionStorage: true,
            }),
            getFetcher.setRequest({
              endpoint: "/patroller" + ApiEndpoints["API_GET_ALL_ROUTES_INFO"],
              cacheToSessionStorage: true,
            }),
          ]);

          //handle incident drop-down values response
          if (incidentResponse && !incidentResponse.error && incidentResponse.data)
            setIncidentDrpDwnValues(incidentResponse.data);
          else if (incidentResponse.error && (incidentResponse.errorStatus || incidentResponse.errorMessage))
            customLog(
              "error",
              `Error ${incidentResponse.errorStatus || "unknown"}: ${incidentResponse.errorMessage || "No error message provided."
              }`
            );

          //handle routes drop-down values response
          if (routesResponse && !routesResponse.error && routesResponse?.data?.routes)
            setRouteRelatedDrpDwnVal(routesResponse.data.routes);
          else if (routesResponse.error && (routesResponse.errorStatus || routesResponse.errorMessage))
            customLog(
              "error",
              `Error ${routesResponse.errorStatus || "unknown"}: ${routesResponse.errorMessage || "No error message provided."
              }`
            );
        }
      } catch (error) {
        console.error("Error fetching data for patroller:", error);
      }
    };
    fetchData();

    // Every 3 minutes, update the data for ViewOpenIncidents component
    const intervalId = setInterval(() => {
      if (componentName === "ViewOpenIncidents") {
        fetchData();
      }
    }, 180000); // 3 minutes in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [componentName]);

  //Update data as per component.
  const updatePatrollerData = async (newData, mode = null) => {
    if (componentName === "ShiftInformation") { //post shift start details
      const response = await postFetcher.setRequest({
        endpoint: ApiEndpoints["API_POST_SHIFT_START"],
        body: JSON.stringify(newData),
      });
      if (response) {
        if (!response.error && response.data?.shift?.id) { //if no error
          const loggedInUser =
            auth && Object.keys(auth)?.length > 0
              ? auth
              : JSON.parse(sessionStorage.getItem("user"));

          //on successful shift start
          customLog(
            "info",
            `${response.data.message}:${response.data.shift.id}`
          );
          if (loggedInUser)
            sessionStorage.setItem(
              "user",
              JSON.stringify({
                ...loggedInUser,
                shift: response.data.shift,
              })
            );
          else logout();
        } else if (response.error) {
          if (response.errorStatus || response.errorMessage)
            customLog(
              "error",
              `Error ${response.errorStatus || "unknown"}: ${response.errorMessage || "No error message provided."
              }`
            );
          if (response.errorStatus === 403 || response.errorStatus === 401)
            //logout if forbidden or unauthorized
            logout();
        }
      } else
        customLog(
          "error",
          `No response for ${ApiEndpoints["API_POST_SHIFT_START"]}`
        );
    } else if (componentName === "AddIncident") {
      //add or edit incident
      let customEndpoint = "";
      if (mode === "add")
        customEndpoint = "/patroller" + ApiEndpoints["API_POST_ADD_INCIDENT"];
      else if (mode === "edit")
        customEndpoint = "/patroller" + ApiEndpoints["API_POST_EDIT_INCIDENT"];

      //call api
      customLog("info", `Adding or editing incident :${mode}`);

      //get shiftId
      const shiftId =
        JSON.parse(sessionStorage.getItem("user"))?.shift?.id || null;
      const response = await postFetcher.setRequest({
        endpoint: customEndpoint,
        body: JSON.stringify(newData),
      });
      if (response) {
        if (response.error) {
          if (response.errorStatus || response.errorMessage) {
            customLog(
              "error",
              `Error ${response.errorStatus || "unknown"}: ${response.errorMessage || "No error message provided."
              }`
            );
            customRequestLog(
              "error",
              `Error ${response.errorStatus || "unknown"} : ${response.errorMessage || "No error message provided."
              } 
                \n Error for shiftId : ${shiftId}
                \n Request body: ${JSON.stringify(newData)}`
            );
          }
          if (response.errorStatus === 403 || response.errorStatus === 401)
            //logout if forbidden or unauthorized
            logout();
        } else if (!response.error && response?.data?.incident)
          customLog(
            "info",
            `${response.data.message}:${response.data.incident.id}`
          );
      } else customLog("error", `No response for ${customEndpoint}`);
    } else if (componentName === "EditCompleteIncident") {
      //edit complete/closed incidents
      const response = await postFetcher.setRequest({
        endpoint: "/patroller" + ApiEndpoints["API_POST_EDIT_INCIDENT"],
        body: JSON.stringify(newData),
      });
      if (response) {
        if (response.error) {
          if (response.errorStatus || response.errorMessage)
            customLog(
              "error",
              `Error ${response.errorStatus || "unknown"}: ${response.errorMessage || "No error message provided."
              }`
            );
          if (response.errorStatus === 403 || response.errorStatus === 401)
            //logout if forbidden or unauthorized
            logout();
        } else if (!response.error && response?.data?.incident)
          customLog(
            "info",
            `${response.data.message}:${response.data.incident.id}`
          );
      } else
        customLog(
          "error",
          `No response for /patroller${ApiEndpoints["API_POST_EDIT_INCIDENT"]}`
        );
    } else if (componentName === "ViewOpenIncidents") {
      //post vehicle refueling details
      const response = await postFetcher.setRequest({
        endpoint: ApiEndpoints["API_POST_VEHICLE_REFUELING"],
        body: JSON.stringify(newData),
      });
      if (response) {
        if (response.error) {
          if (response.errorStatus || response.errorMessage)
            customLog(
              "error",
              `Error ${response.errorStatus || "unknown"}: ${response.errorMessage || "No error message provided."
              }`
            );
          if (response.errorStatus === 403 || response.errorStatus === 401)
            //logout if forbidden or unauthorized
            logout();
        } else if (!response.error && response?.data?.message)
          customLog("info", `${response.data.message}`);
      } else
        customLog(
          "error",
          `No response for ${ApiEndpoints["API_POST_VEHICLE_REFUELING"]}`
        );
    }
  };
  return (
    <PatrollerDataContext.Provider
      value={{
        loggedInUsername,
        incidentDrpDwnValues,
        routeRelatedDrpDwnVal,
        shiftInfoDrpDwn,
        incidentsData,
        route,
        setRoute,
        updatePatrollerData,
      }}
    >
      {children}
    </PatrollerDataContext.Provider>
  );
};

// Custom hook to access the data and update function.
export const usePatrollerData = () => {
  const context = useContext(PatrollerDataContext);
  if (!context) {
    throw new Error(
      "usePatrollerData must be used within a PatrollerDataProvider"
    );
  }
  return context;
};
