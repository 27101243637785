import { openDB } from "idb";
import AuthHeader from "./services/AuthHeader";

let root = `${process.env.REACT_APP_DCSO_CP_MGMT_API}`;

const dbPromise = openDB("request-queue", 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains("requests")) {
      db.createObjectStore("requests", { keyPath: "id" });
    }
    if (!db.objectStoreNames.contains("shiftRequest")) {
      db.createObjectStore("shiftRequest", {
        keyPath: "id",
      });
    }
    if (!db.objectStoreNames.contains("tempFormData")) {
      db.createObjectStore("tempFormData", { keyPath: "id" });
    }
  },
});

// Function to check if a store is empty
async function isStoreEmpty(storeName) {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readonly");
  const store = tx.objectStore(storeName);
  const count = await store.count();
  return count === 0;
}

export async function getRequest(id, storeName) {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readonly");
  const store = tx.objectStore(storeName);
  const request = await store.get(id);
  await tx.done;
  return request;
}

// Function to process all records in a store
async function processStore(storeName) {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readonly");
  const store = tx.objectStore(storeName);
  const allRecords = await store.getAll();

  console.log(`Processing data from ${storeName}:`, allRecords);

  for (const r of allRecords) {
    let newRequest = r;
    if (r.url === "/patroller/postAddIncident" && !r.request.shiftId) {
      let shiftId = JSON.parse(sessionStorage.getItem("user"))?.shift?.id;
      newRequest = {
        ...r,
        request: {
          ...r.request,
          shiftId: shiftId, // Update the `status` key in the data object
        },
      };
    }
    const success = await sendAndDelete(newRequest, storeName);
    if (!success) {
      console.error("Failed to process request, retrying later.");
      break; // Stop further processing to retry later if any request fails
    }
  }
}

export async function saveRequest(url, data, id, storeName = "requests") {
  const db = await dbPromise;
  let objectStore = storeName;

  if (url === "/login") {
    return {
      error: true,
      errorStatus: -1,
      errorMessage: "Retry logging in when you are back online.",
    };
  }
  if (url === "/patroller/postShiftEnd") {
    return {
      error: true,
      errorStatus: -1,
      errorMessage: "Retry ending shift when you are back online.",
    };
  }
  if (!data) {
    return {
      error: true,
      errorStatus: 405,
      errorMessage: "Not a post request.",
    };
  }
  if (url === "/patroller/postShiftStart") {
    objectStore = "shiftRequest";
  }
  const tx = db.transaction(objectStore, "readwrite");
  const store = tx.objectStore(objectStore);
  await store.put({ id: id, request: data, url: url }); // Add the request data to the store
  await tx.done;
  return "Request saved successfully.";
}

// Function to process the request queue when online
export async function processQueue() {
  const requestsEmpty = await isStoreEmpty("requests");
  const shiftRequestEmpty = await isStoreEmpty("shiftRequest");
  if (!shiftRequestEmpty) {
    await processStore("shiftRequest");
  }
  if (!requestsEmpty) {
    await processStore("requests");
  }
}

async function sendAndDelete(data, storeName) {
  try {
    const response = await fetch(`${root}${data.url}`, {
      method: "POST",
      headers: { "Content-Type": "application/json", ...AuthHeader() },
      body: JSON.stringify(data.request),
      credentials: "same-origin",
    });

    if (response.ok) {
      if (data.url === "/patroller/postShiftStart") {
        let res = await response.json();
        let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            ...loggedInUser,
            shift: res.shift,
          })
        );
      }
      return deleteRequest(data, storeName);
    }
  } catch (error) {
    console.error("Error sending data:", error);
  }
  return false;
}

export async function deleteRequest(request, storeName) {
  const db = await dbPromise;
  const deleteTx = db.transaction(storeName, "readwrite");
  const store = deleteTx.objectStore(storeName);
  await store.delete(request.id); // Delete successfully sent record
  await deleteTx.done;
  return true;
}

export async function deleteIndexedDb() {
  const request = indexedDB.open("request-queue"); // Open the database to get the connection

  request.onsuccess = (event) => {
      const db = event.target.result;
      db.close(); // Close the connection

      const deleteRequest = indexedDB.deleteDatabase("request-queue"); // Now attempt to delete

      deleteRequest.onsuccess = () => {
          console.log('Database successfully deleted');
      };

      deleteRequest.onerror = (event) => {
          console.error('Error deleting database:', event);
      };

      deleteRequest.onblocked = () => {
          console.warn('Delete request is blocked. Close all tabs using the database.');
      };
  };

  request.onerror = (event) => {
      console.error('Error opening database:', event);
  };
}
