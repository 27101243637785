import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";

const CustomToolbar = ({ recordsLength }) => {
    return (
        <GridToolbarContainer>
            <div style={{ marginRight: "15px" }}>Total Records: {recordsLength}</div>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}
export default CustomToolbar;