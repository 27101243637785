import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthService from '../services/AuthService';
import { deleteIndexedDb } from "../db";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState(null);
    const { login } = AuthService();

    useEffect(() => {
        const user = sessionStorage.getItem("user");
        if (user) {
            try {
                const parsedUser = JSON.parse(user);
                setAuth(parsedUser);
            } catch (e) {
                console.error('Error parsing user data from sessionStorage:', e);
                sessionStorage.removeItem("user");
            }
        }
    }, []);

    const handleLogin = async (username, password) => {
        const user = await login(username, password);
        if (user) {
            setAuth(user);
            return user;
        }
        else
            return null;
    };

    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.clear();
        deleteIndexedDb();
        setAuth(null);
        navigate('/');
    };

    return (
        <AuthContext.Provider value={{ auth, login: handleLogin, logout: handleLogout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};