import { Grid } from "@mui/material";
import useStyles from "./styles";

export default function DetailsPanel({ data, displayCols }) {
    const { FormLabel, FormValue } = useStyles();

    if (data?.row?.original) {
        const rowData = data.row.original;
        return (
            <Grid container sx={{ p: 3 }}>
                {displayCols?.length > 0 && displayCols.map((column) => (
                    <Grid item xs={6} key={column.accessorKey} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={6}>
                            <FormLabel variant="body2">{column.header}:</FormLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <FormValue variant="body2">{rowData[column.accessorKey] || ""}</FormValue>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        )
    }
    else
        return null;
}