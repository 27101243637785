import React, { useState, useLayoutEffect, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Stack, Grid, CardContent, Alert } from "@mui/material";
import useStyles from "./styles";

//Icons
import {
    AddCircleOutline as AddIcon
} from "@mui/icons-material";

//Forms
import { TimeLocationForm } from "./Forms/TimeLocationForm";
import { VehicleInfoForm } from "./Forms/VehicleInfoForm";
import { AddImages } from "./Forms/AddImages";

//context
import { usePatrollerData } from "../../context/PatrollerContext";

//components
import PageTitle from "../Titles/PageTitle";

// local database
import { deleteRequest, getRequest, saveRequest } from "../../db";

export const SinglePageForm = ({ existingIncidentData }) => {
    const { StyledCard, StyledButton, StyledButtonGrid } = useStyles();
    const navigate = useNavigate();
    //context
    const { setRoute, updatePatrollerData } = usePatrollerData();
    const storageValues = async () => {
      const r = await getRequest("pi0","tempFormData")
      if (r) {
        return r.request
      } else {
        return undefined
      }
    }
    const methods = useForm({defaultValues: storageValues})
    const { setValue, getValues } = methods;
    const numberOfVehicles = localStorage.getItem('numberOfVehicles') ?? '1'
    localStorage.setItem('numberOfVehicles', numberOfVehicles)
    const [showFormError, setShowFormError] = useState(false)
    const [vehicles, setVehicles] = useState(() => {
      let vehicleObjects = []
      for (let i=0; i<parseInt(numberOfVehicles); i++) {
        vehicleObjects.push({
          "make": "",
          "model": "",
          "year": "",
          "color": "",
          "licensePlateNum": "",
          "typeOfAssist": "",
          "serviceProvided": "",
          "gallonsGiven": 0,
          "comments": ""
        })
      }
      return vehicleObjects
    })

    const [isAddImgClicked, setIsAddImgClicked] = useState(
      sessionStorage.getItem('showImageForm') === 'true'
    );

    //Function called when add another vehicle button is clicked
    const handleAddVehicle = () => {
        setVehicles(prev => [...prev, {
            "make": "",
            "model": "",
            "year": "",
            "color": "",
            "licensePlateNum": "",
            "typeOfAssist": "",
            "serviceProvided": "",
            "gallonsGiven": 0,
            "comments": ""
        }])
        localStorage.setItem('numberOfVehicles',(parseInt(numberOfVehicles)+1).toString())
    };

    //Function called when delete vehicle button is clicked
    const handleDeleteVehicle = (vehicleNumber) => {
        const vehiclesLst = [...getValues("vehicles")]; // Create a new array
        vehiclesLst.splice(vehicleNumber, 1);
        setValue("vehicles", vehiclesLst);
        saveRequest(
          "/patroller/postAddIncident",
          getValues(),
          "pi0",
          "tempFormData"
        );
        setVehicles(vehiclesLst);
        localStorage.setItem('numberOfVehicles', (
          // make sure that numberOfVehicles is non-negative
          parseInt(numberOfVehicles)-1 < 0 ? 0 : parseInt(numberOfVehicles)-1
        ).toString())
    };

    // Function called when submit incident button is clicked
    const handleSubmitIncident = async (e) => {
      e.preventDefault();
      // Trigger form validation
      setShowFormError(false)
      const isValid = await methods.trigger();

      const formData = getValues();
      if (isValid && formData) {
        let mode = "add";

        // Filter out empty images
        const filteredImages = formData.images
          ? formData.images.filter(
              (image) => image.length > 0 && image[0]?.file
            )
          : [];
        const newIncident = { ...formData, images: filteredImages };

        // Add shift id
        newIncident.shiftId =
          JSON.parse(sessionStorage.getItem("user"))?.shift?.id || null;

        //Add status
        newIncident.status = "complete";

        //for edit
        if (
          existingIncidentData &&
          Object.keys(existingIncidentData)?.length > 0 &&
          existingIncidentData.id
        ) {
          newIncident.id = existingIncidentData.id;
          mode = "edit";
        }

        // remove showImageForm from sessionStorage
        sessionStorage.removeItem('showImageForm')

        //Set route value
        setRoute(newIncident.location.route);

        //Navigate to view open incidents page
        navigate("/patroller/view-open-incidents");

        // Update Patroller data in DB
        await updatePatrollerData(newIncident, mode);

        // Delete temp form data on submission for IndexedDB storage
        deleteRequest({id:"pi0"}, "tempFormData")
      } else {
        setShowFormError(true)
      }
    };

    useLayoutEffect(() => {
      if (vehicles.length === 0) {
        handleAddVehicle();
      }
    }, [vehicles]);

    // When an incident is opened by a dispatcher and details are already present.
    useEffect(() => {
      if (
        existingIncidentData &&
        Object.keys(existingIncidentData).length > 0
      ) {
        const fields = [
          {
            field: "time.detectMethod",
            value: existingIncidentData.time?.detectMethod,
          },
          {
            field: "location.route",
            value: existingIncidentData.location?.route,
          },
          {
            field: "location.freeway",
            value: existingIncidentData.location?.freeway,
          },
          {
            field: "location.crossStreet",
            value: existingIncidentData.location?.crossStreet,
          },
          {
            field: "location.travelDirection",
            value: existingIncidentData.location?.travelDirection,
          },
          {
            field: "location.lanesBlocked",
            value: existingIncidentData.location?.lanesBlocked,
          },
          { field: "isCritical", value: existingIncidentData?.isCritical },
          {
            field: "location.latitude",
            value: existingIncidentData.location?.latitude,
          },
          {
            field: "location.longitude",
            value: existingIncidentData.location?.longitude,
          },
        ];

        fields.forEach(({ field, value }) => {
          if (value !== undefined) {
            setValue(field, value);
          }
        });

        if (existingIncidentData?.vehicles?.length > 0) {
          setVehicles(existingIncidentData.vehicles);
          existingIncidentData.vehicles.map((vehicle, index) => {
            setValue(`vehicles[${index}].make`, vehicle?.make);
            setValue(`vehicles[${index}].model`, vehicle?.model);
            setValue(`vehicles[${index}].year`, vehicle?.year);
            setValue(`vehicles[${index}].color`, vehicle?.color);
            setValue(
              `vehicles[${index}].licensePlateNum`,
              vehicle?.licensePlateNum
            );
            setValue(`vehicles[${index}].typeOfAssist`, vehicle?.typeOfAssist);
            setValue(
              `vehicles[${index}].serviceProvided`,
              vehicle?.serviceProvided
            );
            setValue(`vehicles[${index}].gallonsGiven`, vehicle?.gallonsGiven);
            setValue(`vehicles[${index}].comments`, vehicle?.comments);
          });
        }
      }
    }, [existingIncidentData]);

    return (
      <FormProvider {...methods}>
        <Stack>
          <StyledCard>
            <CardContent>
              <Grid item xs={12}>
                <PageTitle title="Add Incident Details" />
              </Grid>
              <Grid item xs={12}>
                <TimeLocationForm existingIncidentData={existingIncidentData} />
              </Grid>
              <Grid item xs={12}>
                {vehicles?.map((vehicle, index) => (
                  <VehicleInfoForm
                    key={index}
                    vehicleNumber={index}
                    onDeleteVehicle={handleDeleteVehicle}
                  />
                ))}
              </Grid>
              {isAddImgClicked && (
                <Grid item xs={12}>
                  <AddImages />
                </Grid>
              )}
            </CardContent>
          </StyledCard>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          {/* Add Another Vehicle Button */}
          <StyledButtonGrid item xs={12} style={{ textAlign: "center" }}>
            <StyledButton onClick={handleAddVehicle} startIcon={<AddIcon />}>
              Add another vehicle
            </StyledButton>
            <StyledButton
              onClick={() => {
                setIsAddImgClicked(true);
                sessionStorage.setItem('showImageForm', 'true')
              }}
              startIcon={<AddIcon />}
            >
              Add Image(s)
            </StyledButton>
          </StyledButtonGrid>
        </Stack>

        {/* Error message if form is not valid */}
        { showFormError &&
          <Grid item xs={12} sx={{marginLeft: '35px', marginRight: '35px'}}>
            <Alert severity="error">Submission failed. Not all required fields are filled.</Alert>
          </Grid>
        }

        {/* Submit Button */}
        <StyledButtonGrid item xs={12} style={{ textAlign: "center" }}>
          <StyledButton
            variant="contained"
            onClick={(e) => handleSubmitIncident(e)}
          >
            Submit Incident
          </StyledButton>
        </StyledButtonGrid>
      </FormProvider>
    );
};