import { Routes, Route, Navigate } from "react-router-dom";

//components
import BasicLayout from "./components/Layout/BasicLayout";

//context
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<BasicLayout />} />
      </Routes>
    </AuthProvider>
  );
}
export default App;