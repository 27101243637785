import { styled } from "@mui/system";
import { Typography } from "@mui/material";

export default function useStyles() {


    const FormLabel = styled(Typography)({
        marginLeft: "17px",
        marginTop: "15px",
        marginRight: "4px",
        fontWeight: "bold"
    });

    const FormValue = styled(Typography)({
        marginTop: "15px"
    });


    return {
        FormLabel, FormValue
    };
}