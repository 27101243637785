import { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Import default styles
import 'react-date-range/dist/theme/default.css'; // Import default theme
import { Paper, Box, Button } from '@mui/material';

const customStyles = `
    .rdrDefinedRangesWrapper { /*styling to remove left panel*/
        display: none;
    }
    .rdrDateDisplayItemActive {
        border: 1px solid #CC7B29; /* Border color of active input date display */
    }
    .rdrMonthAndYearPickers {
        color: #CC7B29; /* Color of month year heading */
    }
    .rdrStartEdge{
        background-color: #CC7B29; /* Color of start range day */
        color: #fffff; /* Text color of start range day */
    }
    .rdrInRange{
        background-color: #CC7B29; /* Color of selected days */
        color: #fffff; /* Text color of selected days */
    }
    .rdrEndEdge{
        background-color: #CC7B29; /* Color of end range day */
        color: #fffff; /* Text color of end range day */
    }
    .rdrDayStartPreview{
        border-top-width: 1px;
        border-bottom-width: 1px;        
        border-color: #CC7B29; /* Border color of start preview */
    }
    .rdrDayInPreview{
        border-top-width: 1px;
        border-bottom-width: 1px;        
        border-color: #CC7B29; /* Border color of preview range */
    }
    .rdrDayEndPreview{
        border-top-width: 1px;
        border-bottom-width: 1px;        
        border-color: #CC7B29; /* Border color of end preview */
    }
    .rdrDayToday .rdrDayNumber span:after {
        background: #CC7B29; /* Line under current day *
    }
`;

export default function CustomDateRangePicker({ setSelectedDateRange, setPickerOpen }) {
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleChange = (ranges) => {
        setDateRange([ranges.selection]);
    };

    // Handle OK button click
    const handleOkClick = () => {
        const { startDate, endDate } = dateRange[0];

        //set start date to start of the day and end date to end of day
        const updatedStartDate = new Date(startDate).setHours(0, 0, 0, 0);
        const updatedEndDate = new Date(endDate).setHours(23, 59, 59, 999);
        setSelectedDateRange({ startDate: updatedStartDate, endDate: updatedEndDate }); // Set date range
        setPickerOpen(false); // Close the date picker
    };

    // Handle Reset button click
    const handleResetClick = () => {

        // Reset date range
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ])
        setSelectedDateRange({});
        setPickerOpen(false); // Close the date picker
    };

    return (
        <Box>
            <style>{customStyles}</style>
            <Paper sx={{
                position: 'relative',
                padding: '10px',
                backgroundColor: 'white',
                boxShadow: '0px 2px 8px rgba(0,0,0,0.32)',
                overflow: 'visible',
                mt: 1.5,
                '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 10,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)'
                },
            }}>
                <DateRangePicker
                    ranges={dateRange}
                    onChange={handleChange}
                    showMonthAndYearPickers={false}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                />
                <Box sx={{ marginTop: '10px', display: "flex", justifyContent: "right" }}>
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{ textTransform: "none", fontWeight: 'bold' }}
                        onClick={handleResetClick}
                    >
                        Reset
                    </Button>
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{ textTransform: "none", fontWeight: 'bold' }}
                        onClick={handleOkClick}
                    >
                        Ok
                    </Button>
                </Box>
            </Paper>
        </Box>

    )
}