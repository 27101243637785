import React from 'react';
import { Paper, Checkbox, AccordionSummary, Accordion, AccordionDetails, Typography } from '@mui/material';
import { StyledTypography, StyledOuterDiv, StyledImage } from './styles';
import { ExpandMore as ExpandIcon } from '@mui/icons-material';
import RoutesColorLegend from "./RoutesColorLegend";

const IncidentsTypeLegend = ({ style, type, routeColorInfo = null, showCompleted, setShowCompleted }) => {
    return (
        <Paper style={{ ...style }}>
            <Accordion aria-label="incidents type legend">
                <AccordionSummary expandIcon={<ExpandIcon />}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Incidents Type</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 0 }}>

                    {/* Open Incidents */}
                    <StyledOuterDiv sx={{ paddingTop: 0 }}>
                        <StyledImage src='/images/markers/open-incident-icon.svg' alt="Open incidents marker" sx={{ paddingTop: 0 }} />
                        <StyledTypography variant="body2" sx={{ paddingTop: 0.6 }}>Open</StyledTypography>
                    </StyledOuterDiv>

                    {/* Critical Open Incidents */}
                    <StyledOuterDiv>
                        <StyledImage src='/images/markers/critical-open-incident-icon.svg' alt="Critical Open Incidents marker" sx={{ height: "22px" }} />
                        <StyledTypography variant="body2">Critical Open</StyledTypography>
                    </StyledOuterDiv>

                    {/* Assigned Incidents */}
                    <StyledOuterDiv>
                        <StyledImage src='/images/markers/assigned-incident-icon.svg' alt="Assigned incidents marker" />
                        <StyledTypography variant="body2" sx={{ paddingTop: 1.3 }}>Assigned</StyledTypography>
                    </StyledOuterDiv>

                    {/* Complete Incidents */}
                    <StyledOuterDiv>
                        <StyledImage src='/images/markers/complete-incident-icon.svg' alt="Complete Incidents marker" sx={{ height: "22px" }} />
                        <StyledTypography variant="body2">Complete</StyledTypography>
                    </StyledOuterDiv>

                    {/* Current Location */}
                    {type === "patroller-map" && (
                        <StyledOuterDiv>
                            <StyledImage src='/images/markers/current-location-icon.svg' alt="Current location marker" />
                            <StyledTypography variant="body2" sx={{ paddingTop: 1.2 }}>Current Location</StyledTypography>
                        </StyledOuterDiv>
                    )}

                    {/* Toggle Switch */}
                    <StyledOuterDiv sx={{ paddingTop: 1.2 }}>
                        <Checkbox checked={showCompleted} onChange={(event) => setShowCompleted(event.target.checked)} sx={{ p: 0, paddingLeft: 0.5 }} />
                        <StyledTypography variant="body2" sx={{
                            paddingTop: 0.4,
                            paddingRight: 2,
                            paddingLeft: 1.2
                        }}>Show complete incidents</StyledTypography>
                    </StyledOuterDiv>
                </AccordionDetails>
            </Accordion>
            {type === "dispatcher-map" && routeColorInfo && (
                <RoutesColorLegend routeColorInfo={routeColorInfo} />
            )}
        </Paper>
    );
};
export default IncidentsTypeLegend;