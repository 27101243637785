import { useState } from 'react';
import { MRT_ToggleFiltersButton } from 'material-react-table';
import { Box, Typography, IconButton, Popper } from '@mui/material';
import { mkConfig, generateCsv } from 'export-to-csv';
import {
    FileDownload as DownloadIcon,
    ViewColumn as SelectColumnsIcon,
    DateRange as FilterByDateRangeIcon,
    Summarize as ReportIcon,
    OfflineBolt as QuickMonthlyReportIcon,
    Replay as ReloadDataIcon
} from '@mui/icons-material';

//components
import IconsWithTooltip from '../Icons/IconsWithTooltip';
import { getFormattedDateTime } from '../../utils/GetFormattedDateTimeDay';
import SelectTableColumnsDialog from '../Dialog/SelectTableColumnsDialog';
import CustomDateRangePicker from '../Filters/CustomDateRangePicker';
import GenerateReportDialog from "../Dialog/GenerateReportDialog";
import GenerateMonthlyReportDialog from '../Dialog/GenerateMonthlyReportDialog';

//constants
import { ApiEndpoints } from '../../utils/Constants';

//context
import { useAuth } from '../../context/AuthContext';

//custom hooks
import { useFetch } from '../../hooks/useFetch';

//themes
import themes from '../../theme/defaultTheme';

const toSentenceCase = (str) => {
    return str
        .replace(/([A-Z])/g, ' $1') // Insert space before capital letters
        .replace(/^./, str[0].toUpperCase()) // Capitalize the first letter
        .replace(/_/g, ' '); // Replace underscores with spaces
};

const CustomToolbarWithExport = ({
    table,
    primaryCols = null,
    setPrimaryCols = null,
    secondaryCols = null,
    setSecondaryCols = null,
    setSelectedDateRange = null,
    type,
    setTableData = null,
    loading = null,
    setLoading = null,
    handleReloadData = null
}) => {
    const { logout } = useAuth();
    const postFetcher = useFetch("post");
    const [svcCallsDialogOpen, setSvcCallsDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [pickerOpen, setPickerOpen] = useState(false);
    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
    });

    //React hooks for generate report dialog
    const [reportDialogOpen, setReportDialogOpen] = useState(false);
    const [monthlyReportDialogOpen, setMonthlyReportDialogOpen] = useState(false);

    //Rendered when "Download all as csv" is clicked
    const handleExportData = (rows, type) => {

        // Get the original keys from the first row and skip lastModifiedOn as it is in date format and will give error.
        let originalKeys = Object.keys(rows[0].original || {}).filter(key => key !== "lastModifiedOn" && key !== "id");

        //Reorder columns for service call summaries
        if (type === "serviceCallSummaries") {
            const priorityColumns = ['primaryRow', 'subRow'];
            originalKeys = priorityColumns.concat(originalKeys.filter(key => !priorityColumns.includes(key)));
        }

        // Transform headers to sentence case
        const headers = originalKeys.map(toSentenceCase);

        // Map data to match the sentence-case headers
        const rowData = rows.map((row) => {
            const originalRow = row.original || {};
            return headers.reduce((acc, header, index) => {
                const key = originalKeys[index]; // Original key
                if (originalRow[key]) {
                    if (typeof (originalRow[key]) === "object")
                        acc[header] = originalRow[key].join(", ")
                    else
                        acc[header] = originalRow[key]
                }
                else if (typeof (originalRow[key]) === "string")
                    acc[header] = "";
                else
                    acc[header] = 0;
                return acc;
            }, {});
        });
        const csv = generateCsv(csvConfig)(rowData);

        //custom file name, remove timezone
        const formattedDateTime = getFormattedDateTime(new Date())?.split(" ")?.splice(0, 2)?.join("_") || new Date();
        const fileStart = type === "allServiceCalls" ? "Service_Calls" : type === "serviceCallSummaries" ? "Summaries" : "Manage_Information";
        const filename = `${fileStart}_${formattedDateTime}.csv`;

        // Create a Blob and trigger download with the custom filename
        const url = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    //Rendered when "Generate report" button is clicked
    const handleGenerateReport = ({ dialogDateTimeRange, dialogColumns, dialogRows }) => {
        if (dialogColumns && dialogRows) {

            //close the report dialog
            setReportDialogOpen(false);
            setLoading(true);
            const requestBody = {
                dateTimeRange: dialogDateTimeRange,
                columns: dialogColumns?.length > 0 ? dialogColumns.map(col => col.id) : null,
                rows: dialogRows?.length > 0 ? dialogRows.map(col => col.id) : null,
            }

            const fetchData = async () => {
                try {
                    const response = await postFetcher.setRequest({
                        endpoint: ApiEndpoints["API_GENERATE_REPORT"],
                        body: JSON.stringify(requestBody)
                    });
                    if (response?.error && (response.errorStatus === 403 || response.errorStatus === 401))
                        logout();
                    else if (response?.data)
                        setTableData(response.data);
                }
                catch (error) {
                    console.error("Error in handleGenerateReport", error)
                }
                finally {
                    setLoading(false);
                }
            }
            fetchData();
        }
    };

    //Rendered when "Generate report" button is clicked
    const handleGenerateMonthlyReport = ({ selectedMonthId }) => {
        if (selectedMonthId) {
            setLoading(true);

            //close the report dialog
            setMonthlyReportDialogOpen(false);

            //calculate year
            let reportYear;
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;
            const currentYear = currentDate.getFullYear();

            //if selected month is before current month, pick current year
            if (selectedMonthId < currentMonth)
                reportYear = currentYear;
            else if (selectedMonthId >= currentMonth)
                reportYear = currentYear - 1;

            const requestBody = {
                reportMonth: selectedMonthId,
                reportYear: reportYear
            }

            const fetchData = async () => {
                try {
                    const response = await postFetcher.setRequest({
                        endpoint: ApiEndpoints["API_GENERATE_MONTHLY_REPORT"],
                        body: JSON.stringify(requestBody)
                    });
                    if (response?.error && (response.errorStatus === 403 || response.errorStatus === 401))
                        logout();
                    else if (response?.data) {
                        setTableData(response.data);
                    }
                }
                catch (error) {
                    console.error("Error in handleGenerateMonthlyReport", error)
                }
                finally {
                    setLoading(false);
                }
            }
            fetchData();
        }
    };

    return (
        <>
            {table && (
                <Box display="flex" alignItems="center" justifyContent="space-between">

                    {/* Total Records */}
                    <Typography variant="body1">
                        Total Records: {table.getRowCount()}
                    </Typography>

                    {/* Toolbar options for manage information page */}
                    {type === "manageInfo" && (
                        <MRT_ToggleFiltersButton table={table} sx={{ color: themes.default.palette.primary.main, p: 0, paddingLeft: "4px" }} />
                    )}

                    {/* Toolbar options for all service calls page */}
                    {type === "allServiceCalls" && (
                        <>
                            {/* Show/Hide Filters button */}
                            <MRT_ToggleFiltersButton table={table} sx={{ color: themes.default.palette.primary.main, p: 0, paddingLeft: "4px" }} />

                            {/* Select table columns button */}
                            <IconButton
                                onClick={() => setSvcCallsDialogOpen(true)}
                                sx={{ p: 0, paddingLeft: "4px" }}
                            >
                                <IconsWithTooltip
                                    title="Select table columns"
                                    icon={<SelectColumnsIcon sx={{ color: themes.default.palette.primary.main }} />} />
                            </IconButton>

                            {/* Filter by date range button */}
                            <IconButton
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setPickerOpen((prev) => !prev);
                                }}
                                sx={{ p: 0, paddingLeft: "4px" }}
                            >
                                <IconsWithTooltip title="Filter/Reset date range" icon={<FilterByDateRangeIcon sx={{ color: themes.default.palette.primary.main }} />} />
                            </IconButton>

                            {/* Popper for date range picker */}
                            {anchorEl && (
                                <Popper open={pickerOpen} anchorEl={anchorEl} placement="bottom-start" sx={{ zIndex: 1000 }}>
                                    <CustomDateRangePicker setSelectedDateRange={setSelectedDateRange} setPickerOpen={setPickerOpen} />
                                </Popper>
                            )}
                        </>
                    )}

                    {/* Toolbar options for view service call summaries page */}
                    {type === "serviceCallSummaries" && (
                        <>

                            {/* Generate report button */}
                            <IconButton
                                onClick={() => setReportDialogOpen(true)}
                                sx={{ p: 0, paddingLeft: "4px" }}
                            >
                                <IconsWithTooltip
                                    title="Generate custom report"
                                    icon={<ReportIcon sx={{ color: themes.default.palette.primary.main }} />} />
                            </IconButton>

                            {/* Quick link for NCTCOG monthly report */}
                            <IconButton
                                onClick={() => setMonthlyReportDialogOpen(true)}
                                sx={{ p: 0, paddingLeft: "4px" }}
                            >
                                <IconsWithTooltip
                                    title="Generate monthly report"
                                    icon={<QuickMonthlyReportIcon sx={{ color: themes.default.palette.primary.main }} />} />
                            </IconButton>
                        </>
                    )}

                    {/* Download all as csv Button */}
                    <IconButton
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        onClick={() => handleExportData(table.getPrePaginationRowModel().rows, type)}
                        sx={{ p: 0, paddingLeft: "4px", paddingTop: "3px" }}
                    >
                        <IconsWithTooltip
                            title="Download all as csv"
                            icon={<DownloadIcon sx={{ color: themes.default.palette.primary.main }} />} />
                    </IconButton>

                    {/* Reload data button */}
                    {type === "allServiceCalls" && (
                        <IconButton
                            onClick={handleReloadData}
                            sx={{ p: 0, paddingLeft: "4px" }}
                        >
                            <IconsWithTooltip title="Reload data" icon={<ReloadDataIcon sx={{ color: themes.default.palette.primary.main }} />} />
                        </IconButton>
                    )}
                </Box>
            )}

            {/* Select table columns dialog for view all service calls page */}
            {type === "allServiceCalls" && (
                <SelectTableColumnsDialog
                    open={svcCallsDialogOpen}
                    primaryCols={primaryCols}
                    secondaryCols={secondaryCols}
                    onClose={() => { setSvcCallsDialogOpen(false) }}
                    onSave={
                        ({ localPrimaryCols, localSecondaryCols }) => {
                            setPrimaryCols(localPrimaryCols);
                            setSecondaryCols(localSecondaryCols);
                            setSvcCallsDialogOpen(false);
                        }}
                />
            )}

            {/* Generate report dialog for view service call summaries */}
            {type === "serviceCallSummaries" && (
                <GenerateReportDialog
                    open={reportDialogOpen}
                    onClose={() => { setReportDialogOpen(false) }}
                    onGenerateClick={handleGenerateReport}
                />
            )}

            {/* Generate monthly report dialog for view service call summaries */}
            {type === "serviceCallSummaries" && (
                <GenerateMonthlyReportDialog
                    open={monthlyReportDialogOpen}
                    onClose={() => { setMonthlyReportDialogOpen(false) }}
                    onGenerateClick={handleGenerateMonthlyReport}
                />
            )}
        </>
    );
}
export default CustomToolbarWithExport;