import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import themes from './theme/defaultTheme';
import { NetworkProvider } from "./context/NetworkStatusContext";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <NetworkProvider>
      <ThemeProvider theme={themes.default}>
          <App />
      </ThemeProvider>
    </NetworkProvider>
  </Router>
);
