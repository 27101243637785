import React from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import useStyles from "./styles";

export const ConfirmationDialog = ({ open, title, content, onConfirm, onClose }) => {
    const { StyledDialogButton, StyledDialogTitle } = useStyles();

    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        onClose();
        onConfirm();
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <StyledDialogTitle>{title}</StyledDialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={handleCancel} >
                    No
                </StyledDialogButton>
                <StyledDialogButton onClick={handleConfirm} >
                    Yes
                </StyledDialogButton>
            </DialogActions>
        </Dialog>
    );
};
