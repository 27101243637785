import { ManagerConstants } from "./Constants";

function processColumns(columns) {
    const res = [];
    if (columns?.length > 0) {

        columns.forEach(col => {
            if (Object.keys(col).includes('columns'))
                res.push(...processColumns(col.columns));
            else {
                res.push({
                    label: col.accessorKey?.split("_").join(" "),
                    value: col.accessorKey
                });
            }
        });
    }
    return res;
}

function processRowsAndSeries(xAxis, rows) {
    const data = [];
    const series = [];

    //fetch data for y axis
    xAxis.forEach(col => {
        const yAxis = {};
        rows.forEach(row => {
            const keys = Object.keys(row);
            if (keys.includes(col.value))
                yAxis[row.subRow] = row[col.value];
        });
        data.push(
            {
                "xAxis": col.label,
                ...yAxis
            })
    });

    //fetch data for legend and series 
    rows.forEach((row, index) => {
        series.push({
            dataKey: row.subRow,
            label: row.subRow,
            stack: row.primaryRow,
            color: ManagerConstants.CHART_COLOR_PALETTE[index]
        });
    });
    return { data, series };
}

function ConvertToChartDataFormat(data) {
    let res = {};
    if (data.columns?.length > 0 && data.rows?.length > 0) {

        //remove primaryRow and subRow from columns
        let updatedColumns = data.columns.filter(col => col.accessorKey !== "primaryRow" && col.accessorKey !== "subRow");

        //for large amount of data pick first 8 rows and first 5 columns
        let updatedRows = data.rows;
        if (updatedColumns?.length > 5)
            updatedColumns = updatedColumns.slice(0, 5);
        if (updatedRows?.length > 8)
            updatedRows = updatedRows.slice(0, 8);
        const xAxis = processColumns(updatedColumns);
        res = processRowsAndSeries(xAxis, updatedRows);
    }
    return res;
}

export { ConvertToChartDataFormat }