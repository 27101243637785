import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import useStyles from "./styles";
import { useState } from "react";
import { useNetworkStatus } from "../../context/NetworkStatusContext";

//Renders close incident dialog
export default function EndShiftDialog({ open, title, onClose, onSubmit }) {
    const { StyledDialogButton, StyledLabel, StyledDialogTitle, StyledInputRequired } = useStyles();
    const [endingMiles, setEndingMiles] = useState('');
    const [comments, setComments] = useState('');
    const [markForMaintenance, setMarkForMaintenance] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const isOffline = useNetworkStatus();

    //Validate ending miles input.
    const handleChange = (event) => {
        const { value } = event.target;
        setEndingMiles(value);

        // Validate input to allow only numbers
        if (/^\d*\.?\d*$/.test(value)) {
            setErrorMessage('');
        } else {
            setErrorMessage('Invalid input - enter only numbers');
        }
    };

    const handleSubmit = () => {
        if (!endingMiles)
            setErrorMessage('Required field');
        else {

            //fetch shiftId from session.
            const shiftId = JSON.parse(sessionStorage.getItem('user'))?.shift?.id || null;

            let endShiftData = null;
            if (shiftId) {
                endShiftData = {
                    'shiftId': shiftId,
                    'endingMiles': endingMiles,
                    'comments': comments,
                    'shiftEndDateTime': new Date(),
                    'markForMaintenance': markForMaintenance
                }
            }
            setEndingMiles('');
            setComments('');
            setMarkForMaintenance(false);
            onSubmit(endShiftData);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <StyledDialogTitle style={{ textAlign: "center" }}>{title}</StyledDialogTitle>
            <DialogContent>

                {/* Ending Miles */}
                <Grid container spacing={2} sx={{ paddingBottom: 5, px: 2 }}>
                    <Grid item xs={5}>
                        <StyledLabel sx={{ marginTop: "13px" }}>Ending Miles <em style={{ color: 'red' }}> *</em></StyledLabel>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            autoFocus
                            required
                            name="endingMiles"
                            value={endingMiles}
                            onChange={handleChange}
                            fullWidth
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            sx={{ margin: 0 }}
                        />
                        {errorMessage && (
                            <StyledInputRequired variant="body2" color="error">
                                {errorMessage}
                            </StyledInputRequired>
                        )}
                    </Grid>
                </Grid>

                {/* Comments */}
                <Grid container spacing={2} sx={{ paddingBottom: 5, px: 2 }}>
                    <Grid item xs={5}>
                        <StyledLabel sx={{ marginTop: "13px" }}>Comments</StyledLabel>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            label="Add comments (optional)"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={comments}
                            onChange={(event) => { setComments(event.target.value) }}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                {/* Mark for maintenance checkbox */}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={markForMaintenance}
                                onChange={(event) => { setMarkForMaintenance(event.target.checked) }}
                            />
                        }
                        label="Mark for maintenance"
                        sx={{
                            marginLeft: "15px"
                        }}
                    />
                </Grid>
                {isOffline && <StyledInputRequired variant="body2" color="error" sx={{ paddingTop: "16px" }} >
                    <Alert severity="error">Sorry for the inconvenience. Please retry ending shift when you are back online.</Alert>
                </StyledInputRequired>}
            </DialogContent>
            <DialogActions>
                <DialogActions>
                    <StyledDialogButton onClick={onClose}>Cancel</StyledDialogButton>
                    <StyledDialogButton onClick={handleSubmit}>End Shift</StyledDialogButton>
                </DialogActions>
            </DialogActions>
        </Dialog>
    );
}