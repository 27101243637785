import { Typography } from "@mui/material";
import themes from "../../theme/defaultTheme";

export default function PageTitle({ title }) {
    return (
        <Typography color="inherit" variant="h5" mt={2} align='center'
            sx={{ marginBottom: "28px", fontWeight: "bold", color: themes.default.palette.text.primary }}>
            {title}
        </Typography>
    );
}