// NetworkStatusContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';

const NetworkStatusContext = createContext();

export const NetworkProvider = ({ children }) => {
    const [isOffline, setIsOffline] = useState(false);

    useEffect(() => {
        const updateNetworkMode = () => {
            if (!navigator.onLine) {
                setIsOffline(true);
            } else {
                import("../db").then(({ processQueue }) => processQueue());
                setTimeout(() => setIsOffline(false), 1000); // Clear after a second
            }
        };

        updateNetworkMode(); // Initial check
        window.addEventListener('offline', updateNetworkMode);
        window.addEventListener('online', updateNetworkMode);

        return () => {
            window.removeEventListener('offline', updateNetworkMode);
            window.removeEventListener('online', updateNetworkMode);
        };
    }, []);

    return (
        <NetworkStatusContext.Provider value={isOffline}>
            {children}
        </NetworkStatusContext.Provider>
    );
}

// Custom hook to access the data and update function.
export const useNetworkStatus = () => {
    return useContext(NetworkStatusContext);
};