import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';

export const StyledButton = styled(Button)({
    textTransform: 'None',
    margin: '10px'
});

export const StyledInputRequired = styled(Typography)({
    color: 'red',
    fontStyle: 'italic',
    fontSize: '14px'
});