import {
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Typography
} from "@mui/material";
import useStyles from "./styles";
import { useState } from "react";

//Renders close incident dialog
export default function CloseIncidentDialog({ open, onClose, onSubmit, title }) {
    const { StyledDialogButton, StyledDialogTitle } = useStyles();
    const [comment, setComment] = useState('');

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleSubmit = () => {
        onSubmit(comment);
        setComment('');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <StyledDialogTitle style={{ textAlign: "center" }}>{title}</StyledDialogTitle>
            <DialogContent>
                <Typography style={{ paddingTop: "8px", paddingBottom: "10px" }}>Are you sure you want to close this incident ?</Typography>
                <TextField
                    label="Any comments (optional)"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={comment}
                    onChange={handleCommentChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <DialogActions>
                    <StyledDialogButton onClick={onClose}>No</StyledDialogButton>
                    <StyledDialogButton onClick={handleSubmit}>Yes</StyledDialogButton>
                </DialogActions>
            </DialogActions>
        </Dialog>
    );
}