import React from 'react';
import { Paper, AccordionSummary, Accordion, AccordionDetails, Typography } from '@mui/material';
import { ExpandMore as ExpandIcon } from '@mui/icons-material';

const RoutesColorLegend = ({ routeColorInfo }) => {
    return (
        <Paper>
            <Accordion aria-label="incidents type legend">
                <AccordionSummary expandIcon={<ExpandIcon />}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Routes</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 0 }}>
                    {routeColorInfo?.map((route, index) => {
                        return (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', paddingTop: index === 0 ? 0 : 8 }}>
                                <div style={{ width: 20, height: 2, backgroundColor: route.color, marginRight: 14 }}></div>
                                <Typography variant="body2">{route.name}</Typography>
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
};
export default RoutesColorLegend;