import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Avatar,
    Grid,
    Box,
    Typography,
    Paper,
    InputAdornment,
    IconButton,
    CircularProgress
} from "@mui/material";

// Import icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Components
import LoginAsDialog from '../../components/Dialog/LoginAsDialog';
import { useAuth } from '../../context/AuthContext';
import { useNetworkStatus } from '../../context/NetworkStatusContext';

//Styles
import { StyledButton, StyledInputRequired } from "./styles";

export default function LoginUser() {
    const navigate = useNavigate();
    const isOffline = useNetworkStatus();
    const { login } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [roles, setRoles] = useState([]);
    const [loadBtn, setLoadBtn] = useState(false);

    useEffect(() => {
        if(isOffline) { 
            setErrorMessage("Sorry for the inconvenience. Please retry logging in when you are back online.")
        } else {
            setErrorMessage(" ")
        }
    },[isOffline])

    // Show Password
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        // Validate user input data.
        const username = data.get('username');
        const password = data.get('password');
        if (username && password) {
            try {
                setLoadBtn(true);
                const response = await login(username, password);
                if (response?.error && response.errorMessage)
                    setErrorMessage(response.errorMessage);
                else if (response && !response.error && response.roles?.length > 0) {
                    setErrorMessage("");
                    setIsDialogOpen(true);
                    const expectedOrder = ["Patroller", "Dispatcher", "Manager", "Admin"].filter(role => response.roles.includes(role.toLowerCase()));
                    setRoles(["Select a role", ...expectedOrder]);
                }
                setLoadBtn(false);
            }
            catch (error) {
                console.error('Login error:', error);
            };
        } else
            setErrorMessage("");
    };

    const handleCloseDialog = (loginAs) => {
        setIsDialogOpen(false);
        if (loginAs && typeof loginAs === 'string') {
            const path = '/' + loginAs.toLowerCase();
            navigate(path);
        }
    };

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                item
                xs={false}
                sm={7}
                md={7}
                sx={{
                    backgroundImage: 'url(images/LoginPage.jpg)',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            />
            <Grid item xs={12} sm={5} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Log in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            required
                            autoComplete="username"
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            required
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        {errorMessage && <StyledInputRequired sx={{
                            color: 'red',
                            fontStyle: 'italic',
                            fontSize: '12px'
                        }}>{errorMessage}</StyledInputRequired>}
                        {loadBtn && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress aria-label="loading indicator" />
                            </Box>
                        )}
                        {!loadBtn && (
                            <StyledButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, marginLeft: 0, marginRight: 0 }}
                            >
                                Log In
                            </StyledButton>
                        )}
                        <LoginAsDialog
                            open={isDialogOpen}
                            onClose={handleCloseDialog}
                            title="Login As"
                            menuItems={roles}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}