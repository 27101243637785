import { styled } from "@mui/system";
import { Button, Select, Typography, DialogTitle, Autocomplete } from "@mui/material";
import themes from "../../theme/defaultTheme";

export default function useStyles() {
    const StyledDialogButton = styled(Button)({
        textTransform: 'None !important'
    });

    const ErrorField = styled(Select)({
        border: props => props.error ? '1px solid red' : 'none',
    });

    const StyledLabel = styled(Typography)({
        fontWeight: 'bold',
        marginLeft: "15px",
        marginTop: "14px"
    });

    const StyledAutocomplete = styled(Autocomplete)({
        marginTop: "13px"
    });

    const StyledInputRequired = styled(Typography)({
        color: 'red',
        fontStyle: 'italic',
        fontSize: "10px"
    });

    const StyledDialogTitle = styled(DialogTitle)({
        padding: 30,
        fontWeight: 'bold',
        color: themes.default.palette.primary.main,
        textAlign: 'center'
    });

    const AdditionalInfoLabel = styled(Typography)({
        fontStyle: 'italic',
        fontSize: '12px',
        marginLeft: "15px",
    });

    return {
        StyledDialogButton, ErrorField, StyledLabel,
        StyledInputRequired, StyledDialogTitle, StyledAutocomplete,
        AdditionalInfoLabel
    };
}