import { styled } from "@mui/system";
import { Card, Grid, Typography, TextField, Button, Select, CardContent, Chip, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import themes from "../../theme/defaultTheme";

export default function useStyles() {
    const StyledCard = styled(Card)({
        maxWidth: '100%',
        marginTop: 40,
        marginBottom: 40,
        marginLeft: 35,
        marginRight: 35,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2) !important'
    });

    const StyledGridContainer = styled(Grid)({
        padding: '30px'
    });

    const StyledLabel = styled(Typography)({
        paddingTop: '14px',
        fontWeight: 'bold'
    });

    const StyledField = styled(Select)({
        width: '230px',
        maxWidth: '100%'
    });

    const StyledTextField = styled(TextField)({
        width: '230px',
        maxWidth: '100%'
    });

    const StyledErrorField = styled(TextField)({
        border: '1px solid red',
    });

    const StyledInputRequired = styled(Typography)({
        color: 'red',
        fontStyle: 'italic',
        fontSize: '10px'
    });

    const StyledCancelButton = styled(Button)({
        textTransform: 'None !important',
        marginLeft: '60px',
        marginTop: '35px',
        marginBottom: '20px',
        color: themes.default.palette.primary.contrastText
    });

    const StyledGrid = styled(Grid)({
        paddingLeft: "17px",
        paddingRight: "17px",
        paddingTop: "17px"
    });

    const StyledCardContent = styled(CardContent)({
        paddingTop: "40px"
    });

    const StyledDataGrid = styled(DataGrid)({
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold'
        },
    });

    const StyledChip = styled(Chip)({
        backgroundColor: themes.default.palette.primary.dark,
        color: themes.default.palette.primary.contrastText,
        '&:hover': { backgroundColor: themes.default.palette.primary.dark }
    });

    const StyledTypography = styled(Typography)({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingTop: 15
    });

    const StyledButton = styled(Button)({
        color: themes.default.palette.primary.contrastText,
        textTransform: 'None !important',
        margin: '10px !important'
    });

    const StyledBox = styled(Box)({
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        WebkitLineClamp: 2,
        maxHeight: '3.0em',
        paddingTop: '6px'
    });

    return {
        StyledCard, StyledGridContainer, StyledLabel, StyledField,
        StyledTextField, StyledErrorField, StyledInputRequired,
        StyledGrid, StyledCardContent, StyledDataGrid, StyledChip,
        StyledTypography, StyledCancelButton, StyledButton, StyledBox
    };
}