import { useCallback } from "react";
import { DebugHelper } from "../utils/Debug";
import AuthHeader from "../services/AuthHeader";
import { saveRequest, deleteRequest } from "../db";
import { ApiEndpoints } from "../utils/Constants";
/**
 * @typedef {Object} useFetchReturn
 * @property {function} setRequest - function to call api
 * @property {boolean} isLoading - loading status
 * @property {Object} serverResponse - server response
 * @property {Object} serverError - server error
 */

/**
 * Customized hook for calling api
 * @param {string} method - Http request method, could be get/post.
 * @param {string} url - Http request url
 * @returns {useFetchReturn} - returns a function to call api, loading status, server response and server error
 */

export const useFetch = (method) => {
  const singleRequest = useCallback(
    async (endpoint, body, credentials, cacheToSessionStorage) => {
      const url = process.env.REACT_APP_DCSO_CP_MGMT_API + endpoint;

      try {
        if (navigator.onLine) {
          const response = await fetch(url, {
            method: method,
            body: body,
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              ...AuthHeader(),
            },
            credentials: credentials,
          });

          let data = null;
          if (response) {
            try {
              data = await response.json();
            } catch (error) {
              console.error("Failed to parse JSON: ", error);
              return {
                error: true,
                errorStatus: -1,
                errorMessage: "Invalid JSON response",
              };
            }
            if (response.status === 200) {
              //when response is successful
              DebugHelper.log(
                "The request is successful and the response is ",
                data
              );
              if (cacheToSessionStorage) {
                sessionStorage.setItem(
                  endpoint,
                  JSON.stringify({ error: false, data })
                );
                DebugHelper.log("Data cached to session storage:", data);
              }
              return { error: false, data };
            }
            switch (response.status) {
              case 404: //not found
                console.error(
                  `404 : Error in ${method} request to ${endpoint}: `,
                  data
                );
                break;
              case 500: //internal server error
                console.error(
                  `500 : Error in ${method} request to ${endpoint}: `,
                  data
                );
                break;
              case 401: //unauthorized
                console.error(
                  `401 : Error in ${method} request to ${endpoint}: `,
                  data
                );
                break;
              case 403: //forbidden
                console.error(
                  `403 : Error in ${method} request to ${endpoint}: `,
                  data
                );
                break;
              default:
                console.error(
                  `Error in ${method} request to ${endpoint}:`,
                  response.statusText
                );
            }
          }
          return {
            error: true,
            errorStatus: response?.status || -1,
            errorMessage: data?.error || "An unknown error occurred",
          };
        } else {
          if (method === "get" && cacheToSessionStorage) {
            const cachedData = sessionStorage.getItem(endpoint);
            if (cachedData) {
              DebugHelper.log(
                "no internet, getting from session storage...",
                cachedData
              );
              return JSON.parse(cachedData);
            } else {
              return {
                error: true,
                errorStatus: -1,
                errorMessage: "Unable to retrieve cache from sessionStorage",
              };
            }
          } else if (method === "post"){
              if(endpoint === `/patroller${ApiEndpoints["API_POST_ADD_INCIDENT"]}`){

                //Increment local storage for offline requests
                const piNumber = localStorage.getItem('patrollerFormKey') ? parseInt(localStorage.getItem('patrollerFormKey').substring(2)) : 1;
                const key =  `pi${piNumber}`
                
                //Parse payload data
                const dataObject = typeof body === "string" ? JSON.parse(body) : body;
    
                const res = await saveRequest(
                  endpoint,
                  dataObject,
                  key,
                  "requests"
                );
                !res?.error ? localStorage.setItem("patrollerFormKey", `pi${piNumber+1}`) : console.error(res.errorMessage);
              }
              if(endpoint === ApiEndpoints["API_POST_SHIFT_START"]){
                //Save to IndexedDB if offline
                const currentIDBKey = localStorage.getItem("idbKey") || "0";
                const dataObject = typeof body === "string" ? JSON.parse(body) : body;
                const res = await saveRequest(endpoint, dataObject, currentIDBKey,"shiftRequest");
                res?.error ? console.error(res.errorMessage): localStorage.setItem("idbKey", parseInt(currentIDBKey) + 1);
              }
          } else {
            console.error("You are offline. Some features may be unavailable.")
          }
        }
      } catch (e) {
        DebugHelper.log("Fetch Error:", e);
        console.error(
          `Error in ${method} request to ${endpoint}:`,
          "The server is not reachable, please contact the admin."
        );
        return {
          error: true,
          errorStatus: -1,
          errorMessage: `Error in ${method} request to ${endpoint}`,
        };
      }
    },
    [method]
  );

  const setRequest = useCallback(
    async ({
      endpoint,
      body,
      credentials = "same-origin",
      cacheToSessionStorage = false,
    }) => {
      DebugHelper.log("Call api:", endpoint);
      DebugHelper.log("setRequest(body)-body:", body);
      DebugHelper.log("cache to sessionStorage:", cacheToSessionStorage);
      return singleRequest(endpoint, body, credentials, cacheToSessionStorage);
    },
    [singleRequest]
  );

  return { setRequest };
};
