import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, Box } from "@mui/material";
import useStyles from "./styles";
import { MaterialReactTable } from "material-react-table";

//components
import PageTitle from "../../components/Titles/PageTitle";
import CustomToolbarWithExport from "../../components/CustomDataGridOptions/CustomToolbarWithExport";
import CustomBarChart from "../../components/Charts/CustomBarChart";

//context
import { useManagerData } from "../../context/ManagerContext";

// Recursive function to process sub columns
const processColumns = (cols) => {
    return cols.map(col => {
        if (col.columns?.length > 0) {
            return {
                ...col,
                columns: processColumns(col.columns)
            };
        } else {
            return {
                ...col,
                size: 50
            };
        }
    });
};

export default function ViewServiceCallSummaries() {
    const { StyledCard, StyledGrid, StyledCardContent, StyledChip } = useStyles();
    const { allSummariesData, summariesDropDowns, loading } = useManagerData();
    const navigate = useNavigate();
    const [viewAs, setViewAs] = useState("table");
    const [tableData, setTableData] = useState({ columns: [], rows: [] });
    const [loadingTable, setLoadingTable] = useState(true);

    useLayoutEffect(() => {
        setLoadingTable(true);
        if (allSummariesData && Object.keys(allSummariesData).length > 0) {
            setTableData({
                columns: allSummariesData?.columns || [],
                rows: allSummariesData?.rows || []
            });
            setLoadingTable(false);
        }
    }, [allSummariesData]);

    useLayoutEffect(() => {
        if (tableData.rows.length > 0) {
            const countOccurrences = {};

            // Count occurrences of each primaryRow (category) to manage row spans 
            tableData.rows.forEach(row => {
                countOccurrences[row.primaryRow] = (countOccurrences[row.primaryRow] || 0) + 1;
            });

            // Extract columns
            const columns = [
                {
                    header: 'Category',
                    accessorKey: 'primaryRow', // Primary row header
                    size: 120,
                    Cell: ({ row }) => {
                        const rowIndex = row.index;
                        const primaryRowValue = row.original.primaryRow;
                        const categoryRowSpan = countOccurrences[primaryRowValue];

                        // Only render the category in the first row of each group
                        if (rowIndex === 0 || tableData.rows[rowIndex - 1].primaryRow !== primaryRowValue) {
                            return (
                                <div rowSpan={categoryRowSpan} style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>
                                    {primaryRowValue}
                                </div>
                            );
                        }
                        return null;
                    },
                },
                {
                    header: 'Sub Category',
                    accessorKey: 'subRow', // Sub row header
                    size: 120
                },
                ...processColumns(tableData.columns.filter(col => col.accessorKey !== 'primaryRow' && col.accessorKey !== 'subRow'))
            ];

            setTableData(prev => ({
                ...prev,
                columns
            }));
        }
    }, [tableData.rows])

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* View service calls link */}
                    <StyledGrid item xs={12} sm={5} md={5} lg={5}>
                        <StyledChip label="All service calls" onClick={() => navigate('/manager/view-service-calls')} />
                    </StyledGrid>

                    {/* Page Title */}
                    <Grid item xs={12} sm={7} md={7} lg={7} sx={{ display: 'flex', alignItems: "center" }}>
                        <PageTitle aria-label="View Service Call Summaries" title="View Service Call Summaries" />
                    </Grid>

                    {loading || loadingTable ? (
                        <StyledGrid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress aria-label="loading indicator" />
                            </Box>
                        </StyledGrid>
                    ) : (
                        <>
                            {/* View as radio buttons */}
                            <StyledGrid item xs={12} sx={{ display: 'flex', alignItems: "center" }}>
                                <FormLabel component="legend" sx={{ paddingRight: "15px" }}>View as:</FormLabel>
                                <RadioGroup
                                    row
                                    value={viewAs}
                                    onChange={(event) => { setViewAs(event.target.value) }}
                                >
                                    <FormControlLabel value="table" control={<Radio />} label="Table" />
                                    <FormControlLabel value="chart" control={<Radio />} label="Chart" />
                                </RadioGroup>
                            </StyledGrid>

                            {/* Table */}
                            {viewAs === "table" && (
                                <StyledGrid item xs={12} sx={{ overflow: "hidden" }}>
                                    <MaterialReactTable
                                        columns={tableData.columns}
                                        data={tableData.rows}
                                        muiTableBodyCellProps={({ row, cell }) => ({
                                            sx: {
                                                border: '1px solid rgba(224, 224, 224, 0.5)',
                                            },
                                        })}
                                        muiTableHeadCellProps={({ row, cell }) => ({
                                            sx: {
                                                border: '1px solid rgba(224, 224, 224, 0.5)'
                                            },
                                        })}
                                        enableColumnActions={false} //Disable column headers actions
                                        enableToolbarInternalActions={false} //Disable default toolbar actions
                                        renderTopToolbarCustomActions={({ table }) =>
                                            <CustomToolbarWithExport
                                                table={table}
                                                type="serviceCallSummaries"
                                                setTableData={setTableData}
                                                summariesDropDowns={summariesDropDowns}
                                                loading={loadingTable}
                                                setLoading={setLoadingTable}
                                            />}
                                        initialState={{ showGlobalFilter: true }}
                                        getRowId={(row) => row.id}
                                    />
                                </StyledGrid>
                            )}

                            {/* Custom Bar Chart */}
                            {viewAs === "chart" && (
                                <StyledGrid item xs={12}>
                                    <CustomBarChart data={tableData} />
                                </StyledGrid>
                            )}
                        </>
                    )}
                </Grid>
            </StyledCardContent>
        </StyledCard>
    )
}