import { useMap } from 'react-leaflet';
import { IconButton, Paper, Tooltip } from "@mui/material";

//icons
import { MyLocation as RecenterIcon } from '@mui/icons-material';

//theme
import themes from "../../theme/defaultTheme";

export const RecenterButton = ({ lat, lng, zoomLevel }) => {
    const map = useMap();
    return (
        <Paper style={{
            position: 'absolute',
            top: '77px',
            left: '12px',
            zIndex: 600
        }}>
            <Tooltip title="Click to recenter">
                <IconButton style={{ color: themes.default.palette.primary.main }}
                    onClick={() => { map.setView([lat, lng], zoomLevel); }} size='small'>
                    <RecenterIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Paper>
    );
};