import { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Autocomplete,
    TextField
} from "@mui/material";
import {
    InfoRounded as InfoIcon,
    CalendarMonth as CalendarIcon
} from "@mui/icons-material";
import { MobileDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import useStyles from "./styles";

//components
import IconsWithTooltip from "../Icons/IconsWithTooltip";

//context
import { useManagerData } from "../../context/ManagerContext";
import themes from "../../theme/defaultTheme";


export default function GenerateReportDialog({ open, onClose, onGenerateClick }) {
    const { StyledDialogButton, StyledLabel, StyledDialogTitle, AdditionalInfoLabel, StyledInputRequired } = useStyles();
    const { summariesDropDowns } = useManagerData();
    const [dialogDateTimeRange, setDialogDateTimeRange] = useState({ from: null, to: null });
    const [dialogColumns, setDialogColumns] = useState([]);
    const [dialogRows, setDialogRows] = useState([]);
    const [errors, setErrors] = useState({});

    const handleGenerateReport = () => {
        const allErrors = {};
        const errorMsg = "Required field";

        //validation for rows
        if (dialogRows.length === 0)
            allErrors["rows"] = errorMsg;

        //validation for date time range
        if (!dialogDateTimeRange.from || !dialogDateTimeRange.to)
            allErrors["dateTimeRange"] = errorMsg;

        //validation for columns
        if (dialogColumns.length === 0)
            allErrors["columns"] = errorMsg;
        else if (dialogColumns.length > 2)
            allErrors["columns"] = "Select upto two values";

        //set errors in state
        setErrors(allErrors);

        //Call onGenerateClick parent method if there are no errors
        if (Object.keys(allErrors).length === 0)
            onGenerateClick({ dialogDateTimeRange, dialogColumns, dialogRows });
    }

    const handleCancel = () => {
        setDialogColumns([]);
        setDialogRows([]);
        setDialogDateTimeRange({ from: null, to: null });
        setErrors({});
        onClose();
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <StyledDialogTitle>Generate Report</StyledDialogTitle>
            <DialogContent>
                <Grid container>

                    {/* Date Range */}
                    <Grid container item xs={12} sx={{ paddingBottom: "40px", paddingTop: "10px", paddingRight: "20px" }} spacing={4}>
                        <Grid item xs={5}>
                            <StyledLabel style={{ display: 'flex' }}>
                                Date range <em style={{ color: 'red' }}> *</em>
                                <IconsWithTooltip title="Select duration of the report"
                                    icon={<InfoIcon sx={{ fontSize: "16px", marginLeft: "4px", marginTop: "4px" }} />} />
                            </StyledLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid container spacing={2}>

                                    {/* DateTime From */}
                                    <Grid item xs={12}>
                                        <MobileDateTimePicker
                                            label="Start date"
                                            value={dialogDateTimeRange.from || null}
                                            onChange={(newValue) => setDialogDateTimeRange((prev) => ({ ...prev, from: newValue }))}
                                            orientation="landscape"
                                            maxDate={new Date()} //restrict to today
                                            slotProps={{
                                                textField: {
                                                    variant: "standard",
                                                    fullWidth: true,
                                                    error: !!errors?.dateTimeRange,
                                                    InputProps: {
                                                        endAdornment: <CalendarIcon />
                                                    },
                                                },
                                                toolbar: {
                                                    hidden: false, //hide toolbar
                                                    sx: {
                                                        '& .MuiTypography-overline': {
                                                            display: 'none'
                                                        },
                                                        '& .MuiDateTimePickerToolbar-ampmLabel': {
                                                            paddingRight: "4px"
                                                        },
                                                        '& .MuiDateTimePickerToolbar-ampmLabel.Mui-selected': { //selected AM PM label
                                                            color: themes.default.palette.primary.contrastText
                                                        }
                                                    }
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* DateTime To */}
                                    <Grid item xs={12}>
                                        <MobileDateTimePicker
                                            label="End date"
                                            value={dialogDateTimeRange.to || null}
                                            onChange={(newValue) => setDialogDateTimeRange((prev) => ({ ...prev, to: newValue }))}
                                            orientation="landscape"
                                            minDate={dialogDateTimeRange.from || null} //restrict to start date
                                            maxDate={new Date()} //restrict to today
                                            slotProps={{
                                                textField: {
                                                    variant: "standard",
                                                    fullWidth: true,
                                                    error: !!errors?.dateTimeRange,
                                                    InputProps: {
                                                        endAdornment: <CalendarIcon />
                                                    },
                                                },
                                                toolbar: {
                                                    hidden: false, //hide toolbar
                                                    sx: {
                                                        '& .MuiTypography-overline': {
                                                            display: 'none'
                                                        },
                                                        '& .MuiDateTimePickerToolbar-ampmLabel': {
                                                            paddingRight: "4px"
                                                        },
                                                        '& .MuiDateTimePickerToolbar-ampmLabel.Mui-selected': { //selected AM PM label
                                                            color: themes.default.palette.primary.contrastText
                                                        }
                                                    }
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                            </LocalizationProvider>
                            {errors?.dateTimeRange && (
                                <StyledInputRequired>{errors.dateTimeRange}</StyledInputRequired>
                            )}
                        </Grid>
                    </Grid>

                    {/* Time interval */}
                    <Grid container item xs={12} sx={{ paddingBottom: "40px", paddingRight: "20px" }} spacing={4}>
                        <Grid item xs={5}>
                            <StyledLabel style={{ display: 'flex' }}>
                                Time interval <em style={{ color: 'red' }}> *</em>
                                <IconsWithTooltip title="Select time intervals for the report"
                                    icon={<InfoIcon sx={{ fontSize: "16px", marginLeft: "4px", marginTop: "4px" }} />} />
                            </StyledLabel>
                            <AdditionalInfoLabel>Select upto two</AdditionalInfoLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <Autocomplete
                                multiple
                                value={dialogColumns || null}
                                onChange={(event, value) => setDialogColumns(value)}
                                options={summariesDropDowns?.columns || []}
                                getOptionLabel={(option) => option?.name || ""}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors?.columns}
                                        variant="standard"
                                    />
                                )}
                            />
                            {errors?.columns && (
                                <StyledInputRequired>{errors.columns}</StyledInputRequired>
                            )}
                        </Grid>
                    </Grid>

                    {/* Data metrics */}
                    <Grid container item xs={12} sx={{ paddingRight: "20px" }} spacing={4}>
                        <Grid item xs={5}>
                            <StyledLabel style={{ display: 'flex' }}>
                                Data metrics <em style={{ color: 'red' }}> *</em>
                                <IconsWithTooltip title="Select metrics you want to display in the report"
                                    icon={<InfoIcon sx={{ fontSize: "16px", marginLeft: "4px", marginTop: "4px" }} />} />
                            </StyledLabel>
                            <AdditionalInfoLabel>Select all that apply</AdditionalInfoLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <Autocomplete
                                multiple
                                value={dialogRows || null}
                                onChange={(event, value) => setDialogRows(value)}
                                options={summariesDropDowns?.rows || []}
                                getOptionLabel={(option) => option?.name || ""}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors?.rows}
                                        variant="standard"
                                    />
                                )}
                            />
                            {errors?.rows && (
                                <StyledInputRequired>{errors.rows}</StyledInputRequired>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={handleCancel}>Cancel</StyledDialogButton>
                <StyledDialogButton onClick={handleGenerateReport}>Generate</StyledDialogButton>
            </DialogActions>
        </Dialog >
    );
}