import { styled } from '@mui/system';
import { Typography, Button } from '@mui/material';

export const StyledTypography = styled(Typography)({
    paddingTop: 8
});

export const StyledOuterDiv = styled('div')({
    display: 'flex',
    paddingTop: 2,
    paddingBottom: 0
});

export const StyledImage = styled('img')({
    width: '28px',
    height: "28px",
    paddingTop: 6,
    paddingRight: 10
});

export const StyledButton = styled(Button)({
    textTransform: 'none',
    fontSize: '12px',
    p: 0,
    marginLeft: 3
});