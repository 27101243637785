import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Chip, Autocomplete } from "@mui/material";
import useStyles from "./styles";

//utils
import { getFormattedDate, getCustomDay, getFormattedTime } from '../../utils/GetFormattedDateTimeDay';
import { PatrollerConstants } from '../../utils/Constants';

//Components
import PageTitle from "../../components/Titles/PageTitle";
import VehicleInspectionDialog from '../../components/Dialog/VehicleInspectionDialog';

//Context
import { usePatrollerData } from '../../context/PatrollerContext';

//theme
import themes from '../../theme/defaultTheme';

export default function ShiftInformation() {

    //React hooks
    const { loggedInUsername, shiftData, shiftInfoDrpDwn, updatePatrollerData } = usePatrollerData();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedTruck, setSelectedTruck] = useState(shiftData?.truckNumber || null);
    const [selectedPhone, setSelectedPhone] = useState(shiftData?.phoneNumber || null);
    const [selectedPatroller2, setSelectedPatroller2] = useState(shiftData?.otherPatrollers || []);
    const [isTruckValid, setIsTruckValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);

    //Local Constants
    const { StyledCard, StyledLabel, StyledGridContainer, StyledInputRequired, StyledTextField, StyledButton, StyledCardContent, AdditionalInfoLabel } = useStyles();
    const navigate = useNavigate();
    const truckNumbers = shiftInfoDrpDwn && Object.keys(shiftInfoDrpDwn).length > 0 ? shiftInfoDrpDwn.truckNumbers : [];
    const phoneNumbers = shiftInfoDrpDwn && Object.keys(shiftInfoDrpDwn).length > 0 ? shiftInfoDrpDwn.phoneNumbers : [];
    const patrollers = shiftInfoDrpDwn && Object.keys(shiftInfoDrpDwn).length > 0 ? shiftInfoDrpDwn.patrollers : [];

    //Date, Day and Time values
    const [currentDate, setCurrentDate] = useState();
    const [formattedDate, setFormattedDate] = useState();
    const [formattedDay, setFormattedDay] = useState();
    const [formattedStartTime, setFormattedStartTime] = useState();

    //Vehicle Inspection
    const [inspectionStatus, setInspectionStatus] = useState(shiftData?.vehicleInspection?.status || 'pending');
    const [vehicleInspectionData, setVehicleInspectionData] = useState(shiftData?.vehicleInspection || {});

    const handleBtnClick = async (path, isContinueBtn = false) => {

        // Perform data validations.
        const isTruckValid = selectedTruck !== null;
        const isPhoneValid = selectedPhone !== null;

        // Update state variables for validation.
        setIsTruckValid(isTruckValid);
        setIsPhoneValid(isPhoneValid);

        // If any field is invalid, return without navigating.
        if (!isTruckValid || !isPhoneValid) {
            return;
        }

        //Store input data in an object.
        const inputData = {
            "truckNumber": selectedTruck,
            "phoneNumber": selectedPhone,
            "otherPatrollers": selectedPatroller2,
            "day": formattedDay,
            "vehicleInspection": vehicleInspectionData,
            "shiftStartDateTime": shiftData?.shiftStartDateTime || currentDate
        };
        await updatePatrollerData(inputData);

        //Navigate to appropriate page.
        const updatedPath = isContinueBtn ? -1 : '/patroller/' + path;
        navigate(updatedPath);
    }

    const handleVehicleInspection = () => {
        setIsDialogOpen(true);
    }

    const handleCloseDialog = (formData) => {
        setIsDialogOpen(false);
        if (formData?.beginningMiles) {
            setInspectionStatus('complete');
            formData["status"] = "complete";
            setVehicleInspectionData(formData);
        }
    }

    useEffect(() => {
        const currentDate = new Date();
        setCurrentDate(currentDate);
        setFormattedDate(shiftData?.date || getFormattedDate(currentDate));
        setFormattedDay(shiftData?.day || getCustomDay(currentDate));
        setFormattedStartTime(getFormattedTime(shiftData?.shiftStartDateTime ? new Date(shiftData.shiftStartDateTime) : currentDate));
    }, [shiftData])

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* Page Title */}
                    <Grid item xs={12}>
                        <PageTitle aria-label="Shift Information" title="Shift Information" />
                    </Grid>

                    {/* Mandatory fields label */}
                    <StyledGridContainer item xs={12}>
                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} color="inherit" variant="caption">All <span style={{ color: 'red' }}>*</span> marked fields are required.</Typography>
                    </StyledGridContainer>

                    <StyledGridContainer container spacing={3}>

                        {/* Truck Number Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <StyledLabel variant="body1">Truck No. <span style={{ color: 'red' }}>*</span></StyledLabel>
                            {!isTruckValid && <StyledInputRequired>Required field</StyledInputRequired>}
                        </Grid>

                        {/* Truck Number Drop Down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            {truckNumbers?.length > 0 && (
                                <Autocomplete
                                    options={truckNumbers || []}
                                    getOptionLabel={(option) => String(option?.truckNumber) || ""}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={selectedTruck || null}
                                    onChange={(event, newValue) => {
                                        setSelectedTruck(newValue);
                                        setIsTruckValid(!!newValue);
                                    }}
                                    renderInput={(params) => (
                                        <StyledTextField
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            )}
                        </Grid>

                        {/* Phone Number Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <StyledLabel variant="body1">Phone No. <span style={{ color: 'red' }}>*</span></StyledLabel>
                            {!isPhoneValid && <StyledInputRequired>Required field</StyledInputRequired>}
                        </Grid>

                        {/* Phone Number Drop Down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            {phoneNumbers?.length > 0 && (
                                <Autocomplete
                                    options={phoneNumbers || []}
                                    getOptionLabel={(option) => option?.name || ""}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={selectedPhone}
                                    onChange={(event, newValue) => {
                                        setSelectedPhone(newValue);
                                        setIsPhoneValid(!!newValue);
                                    }}
                                    renderInput={(params) => (
                                        <StyledTextField
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            )}
                        </Grid>

                    </StyledGridContainer>

                    <StyledGridContainer container spacing={3}>

                        {/* Primary Patroller Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <StyledLabel variant="body1">Primary Patroller</StyledLabel>
                        </Grid>

                        {/* Primary Patroller Drop Down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Typography sx={{ paddingTop: '14px' }}>{loggedInUsername}</Typography>
                        </Grid>

                        {/* Other Patroller(s) Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <StyledLabel variant="body1">Other Patroller(s)</StyledLabel>
                            <AdditionalInfoLabel>Select 0 or more</AdditionalInfoLabel>
                        </Grid>

                        {/* Other Patroller(s) Drop Down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            {patrollers?.length > 0 && (
                                <Autocomplete
                                    multiple
                                    options={patrollers || []}
                                    getOptionLabel={(option) => option?.name || ""}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={selectedPatroller2}
                                    onChange={(event, newValue) => {
                                        setSelectedPatroller2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <StyledTextField
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                    </StyledGridContainer>

                    <StyledGridContainer container spacing={3}>

                        {/* Date Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <StyledLabel variant="body1">Date</StyledLabel>
                        </Grid>

                        {/* Date TextField */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <StyledTextField
                                defaultValue={formattedDate}
                                disabled
                            />
                        </Grid>

                        {/* Day of Week Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <StyledLabel variant="body1">Day of Week</StyledLabel>
                        </Grid>

                        {/* Date TextField */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <StyledTextField
                                defaultValue={formattedDay}
                                disabled
                            />
                        </Grid>
                    </StyledGridContainer>

                    <StyledGridContainer container spacing={3}>

                        {/* Start Time Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <StyledLabel variant="body1">Start Time</StyledLabel>
                        </Grid>

                        {/* Start Time TextField */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <StyledTextField
                                defaultValue={formattedStartTime}
                                disabled
                            />
                        </Grid>
                    </StyledGridContainer>

                    <StyledGridContainer container spacing={3}>

                        {/* Vehicle Inspection Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <StyledLabel variant="body1">Vehicle Inspection<span style={{ color: 'red' }}>*</span></StyledLabel>
                        </Grid>

                        {/* Status label */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            {inspectionStatus && inspectionStatus.toLowerCase() === "pending" && (
                                <Chip label="Pending (Click to complete)" variant="outlined" onClick={handleVehicleInspection} sx={{
                                    backgroundColor: themes.default.palette.primary.contrastText,
                                    color: themes.default.palette.primary.dark,
                                    marginTop: "10px"
                                }} />
                            )}
                            {inspectionStatus && inspectionStatus.toLowerCase() === "complete" && (
                                <Chip label="Complete" variant="outlined"
                                    sx={{
                                        backgroundColor: '#89ce00',
                                        color: 'black',
                                        marginTop: "10px"
                                    }} />
                            )}
                        </Grid>
                    </StyledGridContainer>

                    {/* Buttons */}
                    <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '18px' }}>

                        {/* Start Shift Button */}
                        {inspectionStatus.toLowerCase() === "complete" && (
                            <StyledButton variant="contained" onClick={() => { handleBtnClick("view-open-incidents") }}>
                                {PatrollerConstants.START_SHIFT_BTN}
                            </StyledButton>
                        )}
                    </Grid>
                </Grid>
            </StyledCardContent>

            {/* Vehicle Inspection Dialog */}
            <VehicleInspectionDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                title="Complete Vehicle Inspection"
                vehicleInspectionList={shiftInfoDrpDwn && Object.keys(shiftInfoDrpDwn).length > 0 ? shiftInfoDrpDwn.vehicleInspectionList : []} />
        </StyledCard >
    )
}