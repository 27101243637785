import React, { useState, useMemo, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, Grid, Typography, Autocomplete, Box } from "@mui/material";
import useStyles from "../styles"

// Icons
import { RemoveCircleOutline as ClearIcon } from "@mui/icons-material";

// Components
import SectionTitle from "../../Titles/SectionTitle";
import { ConfirmationDialog } from "../../Dialog/ConfirmationDialog";

//Context
import { usePatrollerData } from "../../../context/PatrollerContext";

//theme
import themes from "../../../theme/defaultTheme";

// local database
import { saveRequest } from "../../../db";

export const VehicleInfoForm = ({ vehicleNumber, onDeleteVehicle }) => {
    const { control, getValues, setValue, formState: { errors }, watch } = useFormContext();
    const { StyledMandatoryGrid, FormLabel, StyledTextField, StyledInputRequired, StyledButton, AdditionalInfoLabel } = useStyles();
    const { incidentDrpDwnValues } = usePatrollerData();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const serviceProvided = watch(`vehicles[${vehicleNumber}].serviceProvided`, []);
    const isFuelSelected = serviceProvided.map(svc => svc.name.toLowerCase()).includes("fuel");

    useEffect(() => {
        if (!isFuelSelected) {
            setValue(`vehicles[${vehicleNumber}].gallonsGiven`, 0);
        }
    }, [isFuelSelected, setValue, vehicleNumber]);

    const confirmDeleteVehicle = () => {
        setIsDialogOpen(false);
        onDeleteVehicle(vehicleNumber);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    //drop down values
    const makeOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues).length > 0)
            return incidentDrpDwnValues.vehicle?.make || [];
    }, [incidentDrpDwnValues]);

    const modelOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues).length > 0)
            return incidentDrpDwnValues.vehicle?.model || [];
    }, [incidentDrpDwnValues]);

    const yearOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues).length > 0)
            return incidentDrpDwnValues.vehicle?.year || [];
    }, [incidentDrpDwnValues]);

    const colorOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues).length > 0)
            return incidentDrpDwnValues.vehicle?.color || [];
    }, [incidentDrpDwnValues]);

    const typeOfAssistOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues).length > 0)
            return incidentDrpDwnValues.vehicle?.typeOfAssist || [];
    }, [incidentDrpDwnValues]);

    const serviceProvidedOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues).length > 0)
            return incidentDrpDwnValues.vehicle?.serviceProvided || [];
    }, [incidentDrpDwnValues]);

    return (
      <Grid container spacing={2}>
        {/* Section Title and clear button */}
        <Grid item xs={12} style={{ marginTop: "35px" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              color="inherit"
              variant="h5"
              mt={2}
              style={{
                marginTop: "12px",
                marginRight: "8px",
                marginLeft: "17px",
                fontWeight: "bold",
                color: themes.default.palette.text.primary,
              }}
            >
              {`Vehicle ${vehicleNumber + 1} Information`}
            </Typography>
            <StyledButton
              startIcon={<ClearIcon />}
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              Delete Vehicle {vehicleNumber + 1}
            </StyledButton>
          </Box>
          <hr
            style={{
              marginTop: "12px",
              marginRight: "8px",
              marginLeft: "17px",
              color: "#1976d2",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <form>
            {/* Vehicle Information */}
            <Grid container spacing={4}>
              {/* Make Label */}
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <FormLabel>Make</FormLabel>
              </Grid>

              {/* Make field */}
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Controller
                  name={`vehicles[${vehicleNumber}].make`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value || null}
                      freeSolo
                      options={makeOptions || []}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          variant="outlined"
                          label="Enter or select make"
                        />
                      )}
                      onChange={(event, value) => {
                        field.onChange(value);
                        saveRequest(
                          "/patroller/postAddIncident",
                          getValues(),
                          "pi0",
                          "tempFormData"
                        );
                      }}
                      onInputChange={(event, value) => field.onChange(value)}
                    />
                  )}
                />
              </Grid>

              {/* Model Label */}
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <FormLabel>Model</FormLabel>
              </Grid>

              {/* Model field */}
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Controller
                  name={`vehicles[${vehicleNumber}].model`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value || null}
                      freeSolo
                      options={modelOptions || []}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          variant="outlined"
                          label="Enter or select model"
                        />
                      )}
                      onChange={(event, value) => {
                        field.onChange(value);
                        saveRequest(
                          "/patroller/postAddIncident",
                          getValues(),
                          "pi0",
                          "tempFormData"
                        );
                      }}
                      onInputChange={(event, value) => field.onChange(value)}
                    />
                  )}
                />
              </Grid>

              {/* Year Label */}
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <FormLabel>Year</FormLabel>
              </Grid>

              {/* Year field */}
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Controller
                  name={`vehicles[${vehicleNumber}].year`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value || null}
                      freeSolo
                      options={yearOptions || []}
                      getOptionLabel={(option) => String(option)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          variant="outlined"
                          label="Enter or select year"
                        />
                      )}
                      onChange={(event, value) => {
                        field.onChange(value);
                        saveRequest(
                          "/patroller/postAddIncident",
                          getValues(),
                          "pi0",
                          "tempFormData"
                        );
                      }}
                      onInputChange={(event, value) => field.onChange(value)}
                    />
                  )}
                />
              </Grid>

              {/* Color Label */}
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <FormLabel>Color</FormLabel>
              </Grid>

              {/* Color field */}
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Controller
                  name={`vehicles[${vehicleNumber}].color`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value || null}
                      freeSolo
                      options={colorOptions || []}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          variant="outlined"
                          label="Enter or select color"
                        />
                      )}
                      onChange={(event, value) => {
                        field.onChange(value);
                        saveRequest(
                          "/patroller/postAddIncident",
                          getValues(),
                          "pi0",
                          "tempFormData"
                        );
                      }}
                      onInputChange={(event, value) => field.onChange(value)}
                    />
                  )}
                />
              </Grid>

              {/* License Plate Label */}
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <FormLabel>License Plate</FormLabel>
              </Grid>

              {/* License Plate field */}
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Controller
                  name={`vehicles[${vehicleNumber}].licensePlateNum`}
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      variant="outlined"
                      label="Enter license plate #"
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        saveRequest(
                          "/patroller/postAddIncident",
                          getValues(),
                          "pi0",
                          "tempFormData"
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* Assist Information */}
            <Grid container spacing={4}>
              <Grid item xs={12} style={{ marginTop: "48px" }}>
                <SectionTitle title={`Assist Information`} isSubTitle={true} />
              </Grid>

              {/* Type of assist Label */}
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <FormLabel>
                  Type of Assist<em style={{ color: "red" }}>*</em>
                </FormLabel>
                {errors.vehicles &&
                  !!errors.vehicles[vehicleNumber]?.typeOfAssist && (
                    <StyledInputRequired>Required field</StyledInputRequired>
                  )}
              </Grid>

              {/* Type of assist field */}
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Controller
                  name={`vehicles[${vehicleNumber}].typeOfAssist`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value || null}
                      options={typeOfAssistOptions || []}
                      getOptionLabel={(option) => option?.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(_, value) => {
                        field.onChange(value);
                        saveRequest(
                          "/patroller/postAddIncident",
                          getValues(),
                          "pi0",
                          "tempFormData"
                        );
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          error={
                            !!errors.vehicles &&
                            !!errors.vehicles[vehicleNumber]?.typeOfAssist
                          }
                          label="Select type of assist"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* Service Provided */}
            <Grid container spacing={4} sx={{ marginTop: 1 }}>
              {/* Service Provided Label */}
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <FormLabel>
                  Service Provided<em style={{ color: "red" }}>*</em>
                </FormLabel>
                <AdditionalInfoLabel>Select one or more</AdditionalInfoLabel>
                {errors.vehicles &&
                  !!errors.vehicles[vehicleNumber]?.serviceProvided && (
                    <StyledInputRequired>Required field</StyledInputRequired>
                  )}
              </Grid>

              {/* Service Provided field */}
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Controller
                  name={`vehicles[${vehicleNumber}].serviceProvided`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      value={field.value || []}
                      options={serviceProvidedOptions || []}
                      getOptionLabel={(option) => option?.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) => {
                        field.onChange(value);
                        saveRequest(
                          "/patroller/postAddIncident",
                          getValues(),
                          "pi0",
                          "tempFormData"
                        );
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          error={
                            !!errors.vehicles &&
                            !!errors.vehicles[vehicleNumber]?.serviceProvided
                          }
                          label="Select service provided"
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              {isFuelSelected && (
                <>
                  {/* Gallons Given Label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>
                      Gallons Given<em style={{ color: "red" }}>*</em>
                    </FormLabel>
                    {errors.vehicles &&
                      !!errors.vehicles[vehicleNumber]?.gallonsGiven && (
                        <StyledInputRequired>
                          {errors.vehicles[vehicleNumber].gallonsGiven.message}
                        </StyledInputRequired>
                      )}
                  </Grid>

                  {/* Gallons Given field */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Controller
                      name={`vehicles[${vehicleNumber}].gallonsGiven`}
                      control={control}
                      rules={{
                        required: isFuelSelected ? "Required field" : false,
                        validate: (value) => {
                          const floatValue = parseFloat(value);
                          if (isNaN(floatValue)) {
                            return "Must be a number";
                          } else if (floatValue <= 0) {
                            return "Must be greater than 0";
                          }
                          return true;
                        },
                      }}
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          variant="outlined"
                          type="number"
                          label="Enter gallons given"
                          onChange={(e) => {
                            const floatValue = parseFloat(e.target.value);
                            setValue(
                              `vehicles[${vehicleNumber}].gallonsGiven`,
                              isNaN(floatValue) ? 0.0 : floatValue
                            );
                            saveRequest(
                              "/patroller/postAddIncident",
                              getValues(),
                              "pi0",
                              "tempFormData"
                            );
                          }}
                          error={
                            !!errors.vehicles &&
                            !!errors.vehicles[vehicleNumber]?.gallonsGiven
                          }
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {/* Comments */}
            <Grid container spacing={4}>
              <Grid item xs={12} style={{ marginTop: "48px" }}>
                <SectionTitle title="Comments" isSubTitle={true} />
              </Grid>

              {/* Comments Label */}
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <FormLabel>Comments</FormLabel>
              </Grid>

              {/* Comments field */}
              <Grid
                item
                xs={12}
                sm={10}
                md={10}
                lg={10}
                style={{ marginBottom: "32px" }}
              >
                <Controller
                  name={`vehicles[${vehicleNumber}].comments`}
                  control={control}
                  defaultValue={getValues(
                    `vehicles[${vehicleNumber}].comments`
                  )}
                  render={({ field }) => (
                    <TextField
                      multiline
                      rows={2}
                      variant="outlined"
                      label="Add comments"
                      value={field.value || ""}
                      sx={{ width: "97%" }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        saveRequest(
                          "/patroller/postAddIncident",
                          getValues(),
                          "pi0",
                          "tempFormData"
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>

        {/* Delete Confirmation Dialog */}
        <ConfirmationDialog
          open={isDialogOpen}
          title="Delete Confirmation"
          content="Are you sure you want to delete selected vehicle?"
          onConfirm={confirmDeleteVehicle}
          onClose={handleCloseDialog}
        />
      </Grid>
    );
};