import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Select,
    MenuItem,
    Typography
} from "@mui/material";
import useStyles from "./styles";

//constants
import { ManagerConstants } from "../../utils/Constants";

export default function GenerateMonthlyReportDialog({ open, onClose, onGenerateClick }) {
    const { StyledDialogButton } = useStyles();
    const [selectedMonthId, setSelectedMonthId] = useState(ManagerConstants.MONTH_LIST[0].id);

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogContent sx={{ paddingTop: "40px", paddingBottom: "40px" }}>

                {/* Select month */}
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={{
                            fontWeight: 'bold',
                            marginTop: "14px"
                        }}>
                            Select month:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            value={selectedMonthId}
                            onChange={(event) => { setSelectedMonthId(event.target.value) }}
                            fullWidth
                        >
                            {ManagerConstants.MONTH_LIST?.map(month => (
                                <MenuItem key={month.id} value={month.id}>
                                    {month.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={() => onClose()}>Cancel</StyledDialogButton>
                <StyledDialogButton onClick={() => onGenerateClick({ selectedMonthId })}>Generate</StyledDialogButton>
            </DialogActions>
        </Dialog >
    );
}