//Returns date formatted as "MM-MM-YYYY".
function getFormattedDate(inputDateTime) {
    const year = inputDateTime.getFullYear();
    const month = String(inputDateTime.getMonth() + 1).padStart(2, '0');
    const day = String(inputDateTime.getDate()).padStart(2, '0');
    return `${month}-${day}-${year}`;
}

//Returns day of the week.
function getCustomDay(inputDateTime) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = inputDateTime.getDay();
    return daysOfWeek[dayIndex];
}

//Returns time formatted as "HH:MM:SS" 24 hour format with timezone.
function getFormattedTime(inputDateTime) {
    const formattedTime = inputDateTime.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZoneName: 'short'
    }).replace(',', '')

    return formattedTime;
}

//Return date time formated as "MM-MM-YYYY HH:MM:SS" 24 hour format with timezone
function getFormattedDateTime(inputDateTime) {
    return getFormattedDate(inputDateTime) + ' ' + getFormattedTime(inputDateTime);
}

export { getFormattedDate, getCustomDay, getFormattedTime, getFormattedDateTime };