import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Alert, Box, IconButton, CircularProgress, Button } from "@mui/material";
import useStyles from "./styles";

//icons
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Replay as ReloadDataIcon
} from "@mui/icons-material";

//components
import PageTitle from "../../components/Titles/PageTitle";
import { DispatcherMap } from "../../components/Maps/DispatcherMap";
import { ReactComponent as CriticalOpenIcon } from "../../components/Dispatcher/critical-open-incident-icon.svg";
import { ConfirmationDialog } from "../../components/Dialog/ConfirmationDialog";
import CustomToolbar from "../../components/CustomDataGridOptions/CustomToolbar";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";

//context
import { useDispatcherData } from "../../context/DispatcherContext";
import { useAuth } from "../../context/AuthContext";

//utils
import { getFormattedDateTime } from "../../utils/GetFormattedDateTimeDay";
import { ApiEndpoints } from "../../utils/Constants";

//custom hook
import { useFetch } from "../../hooks/useFetch";

//themes
import themes from "../../theme/defaultTheme";

export default function IncidentManagement() {
    const { StyledCard, StyledGrid, StyledCardContent, StyledChip, StyledDataGrid, StyledTypography } = useStyles();
    const { incidentsData, allRoutesInfo, updateDispatcherData, loading } = useDispatcherData();
    const [dispIncidentsData, setDispIncidentsData] = useState([]);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [showCompleted, setShowCompleted] = useState(false);
    const [mappingLoad, setMappingLoad] = useState(false);
    const { logout } = useAuth();
    const getFetcher = useFetch("get");

    const handleDeleteConfirm = async () => {
        if (selectedRow?.length > 0) {
            await updateDispatcherData({
                id: selectedRow[0]
            }, "delete")
            setSelectedRow([]);
        }
    }

    const columns = [

        //Show route.
        {
            field: "route",
            headerName: "Route",
            width: 250,
            renderCell: (params) => {
                const incidentId = params.row.id;
                const status = params.row.status.toLowerCase();
                const isCritical = params.row.isCritical;
                const routeName = params.row.route;
                if (status === "open") {
                    return (
                        <Box display="flex" alignItems="center">
                            <StyledTypography aria-label="Route Name" variant="body2" key={params.id} sx={{ paddingRight: 1 }}>
                                {routeName}
                            </StyledTypography>

                            {/* For critical incidents */}
                            {isCritical &&
                                (
                                    <IconsWithTooltip
                                        title="Critical incident"
                                        icon={<CriticalOpenIcon style={{ width: '18px', height: '18px', paddingRight: 4, paddingTop: 15 }} />}
                                    />
                                )
                            }

                            {/* Edit incident button */}
                            <IconsWithTooltip
                                title="Click to edit incident"
                                icon={
                                    <IconButton sx={{ p: 0, paddingTop: 1.65 }} onClick={() => navigate('/dispatcher/open-incident', { state: { incidentId } })}>
                                        <EditIcon sx={{ color: themes.default.palette.primary.contrastText }} />
                                    </IconButton>
                                }
                            />

                            {/* Delete incident button */}
                            <IconsWithTooltip
                                title="Click to delete incident"
                                icon={
                                    <IconButton sx={{ p: 0, paddingTop: 1.65 }} onClick={() => setIsDialogOpen(true)} >
                                        <DeleteIcon sx={{ color: themes.default.palette.primary.contrastText }} />
                                    </IconButton>
                                }
                            />
                        </Box>
                    )
                }
                else {
                    return (
                        <StyledTypography aria-label="Route Name" variant="body2" key={params.id}>
                            {routeName}
                        </StyledTypography>
                    );
                }
            }
        },

        //Show freeway.
        {
            field: "freeway",
            headerName: "Freeway",
            width: 250
        },

        //Show cross street.
        {
            field: "crossStreet",
            headerName: "Cross Street",
            width: 290,
        },

        //Show truck number
        {
            field: "truckNumber",
            headerName: "Truck #",
            width: 120
        },

        //Show status
        { field: "status", headerName: "Status", width: 120 },

        //Last Modified
        {
            field: "lastModifiedOn",
            headerName: "Last Modified",
            filterable: false,
            width: 200,
            sortComparator: (a, b) => (a ? new Date(a) : null) - (b ? new Date(b) : null),
            renderCell: (params) => {
                const formattedLastModified = params.row.lastModifiedOn ? getFormattedDateTime(new Date(params.row.lastModifiedOn)) : "";
                return (
                    <StyledTypography variant="body2">{formattedLastModified}</StyledTypography>
                )
            }
        }
    ];

    useEffect(() => {
        setDispIncidentsData(incidentsData);
    }, [incidentsData])

    useEffect(() => {
        if (dispIncidentsData?.length > 0) {
            setMappingLoad(true);

            //remove closed incidents
            const updatedIncidents = dispIncidentsData.filter(incident => incident.status.toLowerCase() !== "closed");
            let mappedIncidents = updatedIncidents?.map(incident => ({
                ...incident,
                "route": incident.location?.route?.name || "",
                "freeway": incident.location?.freeway?.name || "",
                "crossStreet": incident.location?.crossStreet?.name || "",
                "truckNumber": incident.shiftInfo?.truckNumber || ""
            }));
            if (mappedIncidents?.length > 0) {
                if (!showCompleted)
                    mappedIncidents = mappedIncidents.filter(incident => incident.status.toLowerCase() !== "complete");
            }
            setRows(mappedIncidents);
            setMappingLoad(false);
        }
    }, [dispIncidentsData]);

    useEffect(() => {
        let updatedRows = dispIncidentsData
            ?.filter(incident => incident.status.toLowerCase() !== "closed")
            ?.map(incident => ({
                ...incident,
                route: incident.location?.route?.name || "",
                freeway: incident.location?.freeway?.name || "",
                crossStreet: incident.location?.crossStreet?.name || "",
                truckNumber: incident.shiftInfo?.truckNumber || ""
            }));

        if (showCompleted) {
            setRows(updatedRows);
        } else {
            setRows(updatedRows.filter(incident => incident.status.toLowerCase() !== "complete"));
        }
    }, [showCompleted, dispIncidentsData]);

    //fetch data from API when reload data button is clicked
    const handleReloadDataBtnClick = async () => {
        setMappingLoad(true);

        const response = await getFetcher.setRequest({
            endpoint: ApiEndpoints["API_GET_DISPATCHER_INCIDENTS"]
        });
        if (response) {
            if (!response.error && response?.data?.incidents) //if no error
                setDispIncidentsData(response.data.incidents);
            else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                logout();
        }
        setMappingLoad(false);
    }

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* View complete incidents link */}
                    <StyledGrid item xs={12} sm={5} md={5} lg={5}>
                        <StyledChip label="View Completed / Closed incidents" onClick={() => { navigate('/dispatcher/view-all-complete-incidents') }} />
                    </StyledGrid>

                    {/* Page Title */}
                    <Grid item xs={12} sm={7} md={7} lg={7} sx={{ display: 'flex', alignItems: "center" }}>
                        <PageTitle aria-label="Incident Management" title="Incident Management" />
                    </Grid>

                    {loading || mappingLoad ? (
                        <StyledGrid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress aria-label="loading indicator" />
                            </Box>
                        </StyledGrid>
                    ) : (
                        <>
                            {/* Alert */}
                            <StyledGrid container>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Alert severity="info" sx={{ p: 0, paddingLeft: 2, margin: 0, width: "198%" }}>Click on the map to open an incident.</Alert>
                                </Grid>

                                {/* Reload data button */}
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                    <Button
                                        startIcon={<ReloadDataIcon />}
                                        sx={{ textTransform: 'None', color: themes.default.palette.primary.contrastText }}
                                        onClick={handleReloadDataBtnClick}>
                                        Reload Data
                                    </Button>
                                </Grid>
                            </StyledGrid>

                            {/* Map */}
                            <StyledGrid container sx={{ paddingTop: 1 }}>
                                <DispatcherMap
                                    incidentsData={dispIncidentsData.filter(incident => incident.status !== "closed")} allRoutes={allRoutesInfo}
                                    showCompleted={showCompleted} setShowCompleted={setShowCompleted} />
                            </StyledGrid>

                            {/* Incidents table */}
                            <StyledGrid container>
                                <Grid item xs={12}>
                                    <StyledDataGrid
                                        aria-label="Incidents Data Table"
                                        rows={rows}
                                        columns={columns}
                                        autoHeight
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 10 },
                                            },
                                        }}
                                        pageSizeOptions={[10, 15, 20, 25]}
                                        slots={{
                                            toolbar: CustomToolbar
                                        }}
                                        slotProps={{
                                            toolbar: { recordsLength: rows.length }
                                        }}
                                        onRowSelectionModelChange={(newRowSelectionModel) => {
                                            setSelectedRow(newRowSelectionModel);
                                        }}
                                        selectedRow={selectedRow}
                                    />
                                </Grid>
                            </StyledGrid>
                        </>
                    )}
                </Grid>

                {/* Delete Confirmation Dialog */}
                <ConfirmationDialog
                    open={isDialogOpen}
                    title="Delete Confirmation"
                    content="Are you sure you want to delete selected incident?"
                    onConfirm={handleDeleteConfirm}
                    onClose={() => { setIsDialogOpen(false) }}
                />
            </StyledCardContent>
        </StyledCard >
    )
}