import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Alert } from "@mui/material";
import { StyledButton } from './styles';

//leaflet imports
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

//components
import IncidentsTypeLegend from './IncidentsTypeLegend';
import { ConfirmationDialog } from '../Dialog/ConfirmationDialog';
import { RecenterButton } from './RecenterButton';

//context
import { useDispatcherData } from '../../context/DispatcherContext';
import { useAuth } from '../../context/AuthContext';

//constants
import { ApiEndpoints } from '../../utils/Constants';

//custom hooks
import { useFetch } from '../../hooks/useFetch';

// Custom Icons
const assignedIcon = new L.Icon({
    iconUrl: '/images/markers/assigned-incident-icon.svg',
    iconSize: [45, 81],
    iconAnchor: [22.5, 81], //half of icon's width and full height
    popupAnchor: [0, -40.5]
});

const completeIcon = new L.Icon({
    iconUrl: '/images/markers/complete-incident-icon.svg',
    iconSize: [27, 45],
    iconAnchor: [13.5, 45], //half of icon's width and full height
    popupAnchor: [0, -27.5]
});

const openIcon = new L.Icon({
    iconUrl: '/images/markers/open-incident-icon.svg',
    iconSize: [45, 81],
    iconAnchor: [22.5, 81], //half of icon's width and full height
    popupAnchor: [0, -40.5]
});

const criticalOpenIcon = new L.Icon({
    iconUrl: '/images/markers/critical-open-incident-icon.svg',
    iconSize: [30, 51],
    iconAnchor: [15, 25.5], //half of icon Size
    popupAnchor: [0, -12.75]
});

const pinLocationIcon = new L.Icon({
    iconUrl: '/images/markers/current-location-icon.svg',
    iconSize: [30, 30],
    iconAnchor: [15, 30], //half of icon's width and full height
    popupAnchor: [0, -30]
});

//Generate random markers, later on replace with API call.
const generateMarkers = (incidentsData = []) => {
    const markers = [];

    //Set markers for already logged incidents
    if (incidentsData?.length > 0) {
        incidentsData.forEach(incident => {
            let lat, lng, icon = null, type = null, incidentId = null;

            //Set incidentId
            if (incident?.id)
                incidentId = incident.id;
            if (incident?.location?.latitude && incident?.location?.longitude) {
                lat = incident.location.latitude;
                lng = incident.location.longitude;

                if (incident.status === "complete") {
                    icon = completeIcon;
                    type = 'complete';
                }
                else if (incident.status === "assigned") {
                    icon = assignedIcon;
                    type = 'assigned'
                }
                else if (incident.status === "open" && !incident.isCritical) {
                    icon = openIcon;
                    type = 'open'
                }
                else if (incident.isCritical) {
                    icon = criticalOpenIcon;
                    type = 'critical'
                }
                markers.push({
                    incidentId,
                    position: [lat, lng],
                    icon,
                    type: type
                });
            }
        })
    }
    return markers;
};

// Component to handle map click events
const MapClickHandler = ({ onClick }) => {
    useMapEvents({
        click(e) {
            onClick(e.latlng);
        }
    });
    return null;
};

export const DispatcherMap = ({ incidentsData, setIncidentsData, allRoutes, showCompleted, setShowCompleted }) => {
    const { updateDispatcherData } = useDispatcherData();
    const [markers, setMarkers] = useState([]);
    const [dallasLatitude, setDallasLatitude] = useState(null);
    const [dallasLongitude, setDallasLongitude] = useState(null);
    const [popupPosition, setPopupPosition] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [incidentIdToDel, setIncidentIdToDel] = useState(null);
    const [completeMarkers, setCompleteMarkers] = useState([]);
    const [load, setLoad] = useState(false);
    const { logout } = useAuth();
    const getFetcher = useFetch('get');
    const navigate = useNavigate();

    useEffect(() => {
        const lat = 32.779167;
        const lng = -96.808891;
        setDallasLatitude(lat);
        setDallasLongitude(lng);

        //filter out complete markers
        if (incidentsData?.length > 0) {
            let markers = generateMarkers(incidentsData);
            if (markers?.length > 0) {

                //Store complete markers
                setCompleteMarkers(markers.filter(marker => marker.type.toLowerCase() === "complete"))
                if (!showCompleted)
                    markers = markers.filter(marker => marker.type.toLowerCase() !== "complete");
            }
            setMarkers(markers);

        }
    }, [incidentsData, load]);

    useEffect(() => {
        if (showCompleted && markers?.length > 0)
            setMarkers([...completeMarkers, ...markers]);
        else if (markers?.length > 0)
            setMarkers(markers.filter(marker => marker.type.toLowerCase() !== "complete"));
    }, [showCompleted]);

    const handleMapClick = (latlng) => {
        setPopupPosition(latlng);
    };

    const handleOpenIncident = (event, latLng) => {
        navigate('/dispatcher/open-incident', { state: { latLng } });
        event.stopPropagation();
        setPopupPosition(null);
    };
    const handleDeleteIncident = async () => {

        setLoad(true);
        if (incidentIdToDel) {
            await updateDispatcherData({
                id: incidentIdToDel
            }, "delete")
            setTimeout(() => {
                setLoad(false);
            }, 2000); //sleep for 2 seconds
            setIncidentIdToDel(null);
        }
    };
    const handleEditIncident = (e, incidentId) => {
        navigate('/dispatcher/open-incident', { state: { incidentId } });
        e.stopPropagation();
    }

    //Repen and assigned incident
    const handleReopenIncident = async (e, incidentId) => {
        setLoad(true);
        if (incidentId) {
            try {
                const response = await getFetcher.setRequest({
                    endpoint: "/dispatcher" + ApiEndpoints["API_GET_INCIDENT_BY_ID"] + "?id=" + incidentId
                });
                if (response) {
                    if (!response.error && response.data?.incident) {
                        const updatedData = {
                            ...response.data.incident,
                            "status": "open"
                        }
                        await updateDispatcherData(updatedData, "edit")
                        setTimeout(() => {
                            setLoad(false);
                        }, 2000); //sleep for 2 seconds
                    }
                    else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                        logout();
                }
            }
            catch (error) {
                console.error('Error fetching data for incident :', incidentId, " : ", error)
            }
        }
        e.stopPropagation();
    }

    if (!dallasLatitude || !dallasLongitude) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MapContainer center={[0, 0]} zoom={17} minZoom={6} style={{ height: "100vh", width: "100%", zIndex: 1 }}>
                        <Alert severity="info">Loading map. Thank you for your patience.</Alert>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                    </MapContainer>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
                    <MapContainer center={[dallasLatitude, dallasLongitude]} zoom={10} minZoom={6}
                        style={{ height: "100vh", width: "100%", zIndex: 1 }}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {markers?.length > 0 && markers.map((marker) => {
                            return (
                                <Marker key={marker.incidentId} position={marker.position} icon={marker.icon}>
                                    <Popup>
                                        {marker.type === 'open' && (
                                            <>
                                                <p>This is an open incident.</p>
                                                <StyledButton variant="text" onClick={(e) => {
                                                    e.stopPropagation();
                                                    setPopupPosition(null);
                                                    setIsDialogOpen(true);
                                                    setIncidentIdToDel(marker.incidentId)
                                                }}>
                                                    Delete
                                                </StyledButton>
                                                <StyledButton variant="text" onClick={(e) => {
                                                    setPopupPosition(null);
                                                    handleEditIncident(e, marker.incidentId)
                                                }} >Edit</StyledButton>
                                            </>
                                        )}
                                        {marker.type === "complete" && (
                                            <p>This incident is complete</p>
                                        )}
                                        {marker.type === "critical" && (
                                            <>
                                                <p>This incident is <strong>critical.</strong></p>
                                                <StyledButton variant="text" onClick={(e) => {
                                                    e.stopPropagation();
                                                    setPopupPosition(null);
                                                    setIsDialogOpen(true);
                                                    setIncidentIdToDel(marker.incidentId)
                                                }}>
                                                    Delete
                                                </StyledButton>
                                                <StyledButton variant="text" onClick={(e) => {
                                                    setPopupPosition(null);
                                                    handleEditIncident(e, marker.incidentId)
                                                }} >Edit</StyledButton>
                                            </>
                                        )}
                                        {marker.type === "assigned" && (
                                            <>
                                                <p>Someone is working on this incident.<br />
                                                    Are you sure you want to <strong>re open</strong> this incident ?</p>
                                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                                    <StyledButton variant="text" onClick={(e) => { handleReopenIncident(e, marker.incidentId) }}>
                                                        Yes
                                                    </StyledButton>
                                                </div>
                                            </>
                                        )}
                                    </Popup>
                                </Marker>
                            );
                        })}

                        {/* Polylines */}
                        {allRoutes?.length > 0 && allRoutes.map(route => {
                            return route.freeways?.length > 0 && route.freeways.map((fwy, index) => {
                                if (Object.keys(fwy).length > 0 && Object.keys(fwy).includes("coordinates") && fwy["coordinates"].length > 0)
                                    return <Polyline key={index} positions={fwy["coordinates"]} color={route.color} />;
                                return null;
                            });
                        })}

                        {/* Popup to open an incident */}
                        {popupPosition && (
                            <Marker position={popupPosition} icon={pinLocationIcon}>
                                <Popup sx={{ width: 25 }}>
                                    <div style={{ textAlign: "center" }}>
                                        <p style={{ fontSize: "15px", padding: 0 }}>What do you want to do ?</p>
                                        <StyledButton variant="text" onClick={(e) => { setPopupPosition(null); e.stopPropagation(); }}>Remove pin</StyledButton>
                                        <StyledButton variant="text" onClick={(event) => handleOpenIncident(event, popupPosition)} >Open Incident</StyledButton>
                                    </div>
                                </Popup>
                            </Marker>
                        )}
                        <MapClickHandler onClick={handleMapClick} />
                        <RecenterButton lat={dallasLatitude} lng={dallasLongitude} zoomLevel={10} />
                    </MapContainer>
                    <IncidentsTypeLegend style={{ position: 'absolute', top: '12px', right: '10px', zIndex: 2 }}
                        type="dispatcher-map"
                        routeColorInfo={allRoutes ? allRoutes.map(route => {
                            return {
                                name: route.name,
                                color: route.color
                            }
                        }) : null}
                        showCompleted={showCompleted}
                        setShowCompleted={setShowCompleted}
                    />
                </div>

                {/* Delete Confirmation Dialog */}
                <ConfirmationDialog
                    open={isDialogOpen}
                    title="Delete Confirmation"
                    content="Are you sure you want to delete this incident?"
                    onConfirm={handleDeleteIncident}
                    onClose={(e) => { setIsDialogOpen(false); }}
                />
            </Grid>
        </Grid >
    );
};