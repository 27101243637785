import React, { createContext, useContext, useEffect, useState } from "react";

//custom hook
import { useFetch } from "../hooks/useFetch";

//context
import { useAuth } from "../context/AuthContext";

//constants
import { ApiEndpoints } from "../utils/Constants";

const DispatcherDataContext = createContext();

//Context specific for dispatcher
export const DispatcherDataProvider = ({ children, componentName }) => {
    const [incidentsData, setIncidentsData] = useState([]);
    const [allRoutesInfo, setAllRoutesInfo] = useState([]);
    const [incidentDrpDwnValues, setIncidentDrpDwnValues] = useState({});
    const { logout } = useAuth();
    const getFetcher = useFetch("get"); //for all get requests
    const postFetcher = useFetch("post"); //for add, edit and delete requests
    const [loadGet, setLoadGet] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (componentName === "IncidentManagement") { //get all incidents for past 24 hours and get routes info

                    setLoading(true);
                    const [allIncidentsResponse, routesResponse] = await Promise.all([
                        getFetcher.setRequest({
                            endpoint: ApiEndpoints["API_GET_DISPATCHER_INCIDENTS"]
                        }),
                        getFetcher.setRequest({
                            endpoint: '/dispatcher' + ApiEndpoints["API_GET_ALL_ROUTES_INFO"]
                        }),
                    ]);

                    //handle all incidents response
                    if (allIncidentsResponse) {
                        if (!allIncidentsResponse.error && allIncidentsResponse?.data?.incidents) //if no error
                            setIncidentsData(allIncidentsResponse.data.incidents);
                        else if (allIncidentsResponse.error && (allIncidentsResponse.errorStatus === 403 || allIncidentsResponse.errorStatus === 401)) //logout if forbidden or unauthorized
                            logout();
                    }

                    //handle routes drop-down values response
                    if (routesResponse && !routesResponse.error && routesResponse?.data?.routes)
                        setAllRoutesInfo(routesResponse.data.routes);

                    setLoading(false);
                }
                else if (componentName === "OpenIncident" || componentName === "EditIncident") { //get drop down values for incident
                    const response = await getFetcher.setRequest({
                        endpoint: '/dispatcher' + ApiEndpoints["API_GET_ALL_INCIDENT_DROP_DWNS"]
                    });
                    if (response) {
                        if (!response.error && response.data) //if no error
                            setIncidentDrpDwnValues(response.data);
                        else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                            logout();
                    }
                }
                else if (componentName === "ViewAllCompleteIncidents") { //get dispatcher incidents
                    setLoading(true);
                    const response = await getFetcher.setRequest({
                        endpoint: ApiEndpoints["API_GET_DISPATCHER_INCIDENTS"]
                    });
                    if (response) {
                        if (!response.error && response.data?.incidents) //if no error
                            setIncidentsData(response.data.incidents);
                        else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                            logout();
                    }
                    setLoading(false);
                }
            }
            catch (error) {
                console.error('Error fetching data for dispatcher:', error)
            }
        };
        fetchData();

        // Every 10 minutes, update data
        const intervalId = setInterval(() => {
            if (componentName === "IncidentManagement" || componentName === "ViewAllCompleteIncidents") {
                fetchData();
            }
        }, 600000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);

    }, [componentName, loadGet])

    //Update data as per component.
    const updateDispatcherData = (newData, mode = null) => {
        const postData = async () => {
            if (componentName === "OpenIncident") { //open/add incident or edit open incident
                let customEndpoint = "";
                if (mode === "open")
                    customEndpoint = "/dispatcher" + ApiEndpoints["API_POST_ADD_INCIDENT"];
                else if (mode === "edit")
                    customEndpoint = "/dispatcher" + ApiEndpoints["API_POST_EDIT_INCIDENT"];

                //call api
                const response = await postFetcher.setRequest({
                    endpoint: customEndpoint,
                    body: JSON.stringify(newData)
                });
                if (response?.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                    logout();
            }
            else if (componentName === "EditIncident") { //edit incident
                const response = await postFetcher.setRequest({
                    endpoint: "/dispatcher" + ApiEndpoints["API_POST_EDIT_INCIDENT"],
                    body: JSON.stringify(newData)
                });
                if (response?.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                    logout();
            }
            else if (componentName === "IncidentManagement" || componentName === "ViewAllCompleteIncidents" || componentName === "DispatcherMap") { //delete incident
                let customEndpoint = "";
                if (mode === "delete")
                    customEndpoint = ApiEndpoints["API_POST_DELETE_INCIDENT"];
                else if (mode === "edit")
                    customEndpoint = "/dispatcher" + ApiEndpoints["API_POST_EDIT_INCIDENT"];

                const response = await postFetcher.setRequest({
                    endpoint: customEndpoint,
                    body: JSON.stringify(newData)
                });
                if (response?.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                    logout();
                else
                    setLoadGet(prev => !prev)
            }
        };
        postData();
    };
    return (
        <DispatcherDataContext.Provider value={{ incidentsData, allRoutesInfo, incidentDrpDwnValues, loading, updateDispatcherData }}>
            {children}
        </DispatcherDataContext.Provider>
    );
};

// Custom hook to access the data and update function.
export const useDispatcherData = () => {
    const context = useContext(DispatcherDataContext);
    if (!context) {
        throw new Error("useDispatcherData must be used within a DispatcherDataProvider");
    }
    return context;
};