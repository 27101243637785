import { createContext, useContext, useState, useEffect } from "react";

//custom hooks
import { useFetch } from "../hooks/useFetch";

//constants
import { ApiEndpoints } from "../utils/Constants";

//context
import { useAuth } from "../context/AuthContext";

const ManagerDataContext = createContext();
export const ManagerDataProvider = ({ children, componentName }) => {
    const [serviceCallsData, setServiceCallsData] = useState([]);
    const [allRoutesInfo, setAllRoutesInfo] = useState([]);
    const [incidentDrpDwnValues, setIncidentDrpDwnValues] = useState({});
    const [summariesDropDowns, setSummariesDropDowns] = useState({});
    const [allSummariesData, setAllSummariesData] = useState({});
    const { logout } = useAuth();
    const [loadGet, setLoadGet] = useState(true);
    const [loading, setLoading] = useState(false);
    const getFetcher = useFetch("get"); //for all get requests
    const postFetcher = useFetch("post"); //for add, edit and delete requests

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (componentName === "ViewServiceCalls") { //get service calls for last 30 days
                    setLoading(true)
                    const response = await getFetcher.setRequest({
                        endpoint: ApiEndpoints["API_GET_ALL_SERVICE_CALLS"]
                    });
                    if (response) {
                        if (!response.error && response?.data?.incidents) //if no error
                            setServiceCallsData(response.data.incidents);
                        else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                            logout();
                    }
                    setLoading(false);
                }
                else if (componentName === "EditServiceCall") { //get incident and route drop down values

                    const [incidentResponse, routesResponse] = await Promise.all([
                        getFetcher.setRequest({
                            endpoint: '/manager' + ApiEndpoints["API_GET_ALL_INCIDENT_DROP_DWNS"]
                        }),
                        getFetcher.setRequest({
                            endpoint: '/manager' + ApiEndpoints["API_GET_ALL_ROUTES_INFO"]
                        })
                    ]);

                    //handle incident drop-down values response
                    if (incidentResponse && !incidentResponse.error && incidentResponse.data)
                        setIncidentDrpDwnValues(incidentResponse.data);

                    //handle routes drop-down values response
                    if (routesResponse && !routesResponse.error && routesResponse?.data?.routes)
                        setAllRoutesInfo(routesResponse.data.routes);
                }
                else if (componentName === "ViewServiceCallSummaries") { //get default summaries and drop downs

                    setLoading(true);
                    const [defaultSummariesResponse, summariesDropDownsResponse] = await Promise.all([
                        getFetcher.setRequest({
                            endpoint: ApiEndpoints["API_GENERATE_REPORT"]
                        }),
                        getFetcher.setRequest({
                            endpoint: ApiEndpoints["API_GET_SUMMARIES_DROP_DOWNS"]
                        }),
                    ]);

                    //handle default summaries response (summary will be for the last previous month by week showing all data metrics)
                    if (defaultSummariesResponse) {
                        if (!defaultSummariesResponse.error && defaultSummariesResponse?.data) //if no error
                            setAllSummariesData(defaultSummariesResponse.data);
                        else if (defaultSummariesResponse.error && (defaultSummariesResponse.errorStatus === 403 || defaultSummariesResponse.errorStatus === 401)) //logout if forbidden or unauthorized
                            logout();
                    }

                    //handle summaries drop-down values response
                    if (summariesDropDownsResponse && !summariesDropDownsResponse.error && summariesDropDownsResponse?.data)
                        setSummariesDropDowns(summariesDropDownsResponse.data);
                    setLoading(false);
                }
            }
            catch (error) {
                console.error('Error fetching data:', error)
            }
        };
        fetchData();
    }, [componentName, loadGet]);

    //Update data as per component.
    const updateServiceCalls = async (newData) => {
        if (componentName === "ViewServiceCalls") { //delete service call
            const response = await postFetcher.setRequest({
                endpoint: ApiEndpoints["API_POST_DELETE_SERVICE_CALL"],
                body: JSON.stringify(newData)
            });
            if (response?.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                logout();
            else
                setLoadGet(prev => !prev)
        }
        else if (componentName === "EditServiceCall") { //edit a service call
            const response = await postFetcher.setRequest({
                endpoint: ApiEndpoints["API_POST_EDIT_SERVICE_CALL"],
                body: JSON.stringify(newData)
            });
            if (response?.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                logout();
        }
        return newData;
    };
    return (
        <ManagerDataContext.Provider
            value={{ serviceCallsData, allRoutesInfo, incidentDrpDwnValues, summariesDropDowns, allSummariesData, loading, updateServiceCalls }}>
            {children}
        </ManagerDataContext.Provider>
    );
};

// Custom hook to access the data and update function.
export const useManagerData = () => {
    const context = useContext(ManagerDataContext);
    if (!context) {
        throw new Error("useManagerData must be used within a ManagerDataProvider");
    }
    return context;
};
