import React from 'react'
import { CardContent, Grid } from '@mui/material'

//import Styles
import useStyles from './styles';

//components
import SectionTitle from '../Titles/SectionTitle';

//context
import { usePatrollerData } from "../../context/PatrollerContext";

const ShiftInfo = () => {
    const { StyledCard, FormLabel, FormValue } = useStyles();
    const { shiftData } = usePatrollerData();
    return (
        <>
            {
                shiftData && Object.keys(shiftData).length > 0 && (
                    <StyledCard sx={{ marginTop: '30px' }}>
                        <CardContent>
                            {/* Section Title */}
                            <Grid item xs={12}>
                                <SectionTitle title="Shift Information" />
                            </Grid>
                            <Grid container spacing={2}>

                                {/* Truck Number Label and value */}
                                <Grid item xs={6} sx={{ display: 'flex' }}>
                                    <FormLabel sx={{ marginRight: 1 }}>Truck Number : </FormLabel>
                                    <FormValue>{shiftData.truckNumber["truckNumber"]}</FormValue>
                                </Grid>

                                {/* Primary Patroller Label and value */}
                                <Grid item xs={6} sx={{ display: 'flex' }}>
                                    <FormLabel sx={{ marginRight: 1 }}>Primary Patroller : </FormLabel>
                                    <FormValue>{shiftData.loggedInPatroller["name"]}</FormValue>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>

                                {/* Phone Number Label and value */}
                                <Grid item xs={6} sx={{ display: 'flex' }}>
                                    <FormLabel sx={{ marginRight: 1 }}>Phone Number : </FormLabel>
                                    <FormValue>{shiftData.phoneNumber["name"]}</FormValue>
                                </Grid>

                                {/* Other Patroller(s) Label and value */}
                                <Grid item xs={6} sx={{ display: 'flex' }}>
                                    <FormLabel sx={{ marginRight: 1 }}>Other Patroller(s) : </FormLabel>
                                    <FormValue>{shiftData.otherPatrollers?.length > 0
                                        ? shiftData.otherPatrollers?.map(pat => pat.name).join(", ")
                                        : ""}
                                    </FormValue>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </StyledCard>
                )
            }
        </>
    )
}
export default ShiftInfo;