import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Box, CircularProgress } from "@mui/material";
import useStyles from "./styles";
import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";

//components
import PageTitle from "../../components/Titles/PageTitle";
import { getFormattedDateTime } from "../../utils/GetFormattedDateTimeDay";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";
import CustomToolbarWithExport from "../../components/CustomDataGridOptions/CustomToolbarWithExport";
import DetailsPanel from "../../components/Panels/DetailsPanel";
import { ConfirmationDialog } from "../../components/Dialog/ConfirmationDialog";

//custom hook
import { useFetch } from "../../hooks/useFetch";

//context
import { useManagerData } from "../../context/ManagerContext";
import { useAuth } from "../../context/AuthContext";

//constants
import { ManagerConstants, ApiEndpoints } from "../../utils/Constants";

//themes
import themes from "../../theme/defaultTheme";

const MapIncidentsData = (serviceCallsData) => {
    const mappedData = serviceCallsData?.map(incident => ({
        "id": incident.id,
        "truckNumber": incident.shiftInfo?.truckNumber || "",
        "phoneNumber": incident.shiftInfo?.phoneNumber || "",
        "primaryPatroller": incident.shiftInfo?.loggedInPatroller || "",
        "otherPatrollers": incident.shiftInfo?.otherPatrollers?.join(", ") || "",
        "route": incident.location?.route?.name || "",
        "freeway": incident.location?.freeway?.name || "",
        "crossStreet": incident.location?.crossStreet?.name || "",
        "startTime": incident.time.startTime ? getFormattedDateTime(new Date(incident.time.startTime)) : "",
        "endTime": incident.completedOn ? getFormattedDateTime(new Date(incident.completedOn)) : "",
        "lanesBlocked": incident.location.lanesBlocked ? incident.location.lanesBlocked.map(lane => lane.name).join(", ") : "",
        "detectMethod": incident.time?.detectMethod || "",
        "typeOfAssist": incident.vehicles?.length > 0 ? [...new Set(incident.vehicles.map(vehicle => vehicle.typeOfAssist?.name))].join(", ") : "",
        "serviceProvided": incident.vehicles?.length > 0
            ? [...new Set(incident.vehicles.flatMap(vehicle => vehicle.serviceProvided?.map(service => service.name) || []))].join(", ")
            : "",
        "lastModified": incident?.lastModifiedOn ? getFormattedDateTime(new Date(incident.lastModifiedOn)) : "",
        "lastModifiedOn": incident?.lastModifiedOn ? new Date(incident.lastModifiedOn) : null,
        "status": incident.status
    }))
    return mappedData;
}

export default function ViewServiceCalls() {
    const { StyledCard, StyledGrid, StyledCardContent, StyledChip } = useStyles();
    const { serviceCallsData, updateServiceCalls, loading } = useManagerData();
    const [rows, setRows] = useState([]);
    const [primaryCols, setPrimaryCols] = useState([]);
    const [secondaryCols, setSecondaryCols] = useState([]);
    const [selectedDateRange, setSelectedDateRange] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [mappingLoad, setMappingLoad] = useState(false);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const getFetcher = useFetch("get");

    useEffect(() => {
        if (serviceCallsData?.length > 0) {
            setMappingLoad(true);

            //map data
            const mappedData = MapIncidentsData(serviceCallsData);
            setRows(mappedData);
            setMappingLoad(false);
        }
    }, [serviceCallsData]);

    useEffect(() => {
        if (serviceCallsData?.length > 0) {
            const mappedData = MapIncidentsData(serviceCallsData);

            //Filter rows as per date range
            if (selectedDateRange && Object.keys(selectedDateRange)?.length > 0
                && selectedDateRange.startDate && selectedDateRange.endDate) {
                const filteredRows = mappedData.filter(row => row.lastModifiedOn >= selectedDateRange.startDate && row.lastModifiedOn <= selectedDateRange.endDate);
                setRows(filteredRows);
            }
            else
                setRows(mappedData);

        }
    }, [selectedDateRange])

    // Memoizing the actions column
    const actionsCol = useMemo(() => ({
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
            <Box>
                <IconButton sx={{ p: 0 }}>
                    <IconsWithTooltip
                        title="Click to edit service call"
                        icon={<EditIcon
                            sx={{ color: themes.default.palette.primary.contrastText }}
                            onClick={() => { navigate('/manager/edit-service-call', { state: { serviceCallId: row.original.id } }) }}
                        />}
                    />
                </IconButton>
                <IconButton>
                    <IconsWithTooltip
                        title="Click to delete service call"
                        icon={<DeleteIcon sx={{ color: themes.default.palette.primary.contrastText }}
                            onClick={() => { setIsDialogOpen(true); setSelectedRow([row.original.id]); }}
                        />} />
                </IconButton>
            </Box>
        ),
        size: 80,
    }), []);

    useEffect(() => {
        if (primaryCols.length === 0) //Set default columns if primary cols are empty
            setPrimaryCols([actionsCol, ...ManagerConstants.SERVICE_CALL_DEFAULT_PRIMARY_COLUMNS]);
        else if (!primaryCols.some(col => col.accessorKey === 'actions'))
            setPrimaryCols(prevCols => [actionsCol, ...prevCols]);
    }, [primaryCols, actionsCol]);

    useEffect(() => {
        if (secondaryCols.length === 0) //Set all columns as default if secondary cols are empty
            setSecondaryCols([...ManagerConstants.SERVICE_CALL_ALL_COLUMNS]);
    }, [secondaryCols]);

    const handleDeleteConfirm = async () => {
        if (selectedRow?.length > 0) {
            await updateServiceCalls({
                id: selectedRow[0]
            })
            setSelectedRow([]);
        }
    }

    //Fetch data from API when reload data button is clicked
    const handleReloadServiceCallsData = async () => {
        setMappingLoad(true);
        const response = await getFetcher.setRequest({
            endpoint: ApiEndpoints["API_GET_ALL_SERVICE_CALLS"]
        });
        if (response) {
            if (!response.error && response?.data?.incidents) { //if no error
                const mappedData = MapIncidentsData(response.data.incidents);
                setRows(mappedData);
            }
            else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                logout();
        }
        setMappingLoad(false);
    }

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* View service call summaries link */}
                    <StyledGrid item xs={12} sm={5} md={5} lg={5}>
                        <StyledChip label="Service Call Summaries" onClick={() => navigate('/manager/service-call-summaries')} />
                    </StyledGrid>

                    {/* Page Title */}
                    <Grid item xs={12} sm={7} md={7} lg={7} sx={{ display: 'flex', alignItems: "center" }}>
                        <PageTitle aria-label="View All Service Calls" title="View All Service Calls" />
                    </Grid>

                    {loading || mappingLoad ? (
                        <StyledGrid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress aria-label="loading indicator" />
                            </Box>
                        </StyledGrid>
                    ) : (
                        <StyledGrid item xs={12} sx={{ overflow: "hidden" }}>
                            <MaterialReactTable
                                columns={primaryCols}
                                data={rows}
                                enableToolbarInternalActions={false} //Disable default toolbar actions
                                renderTopToolbarCustomActions={({ table }) =>
                                    <CustomToolbarWithExport
                                        table={table}
                                        primaryCols={primaryCols}
                                        setPrimaryCols={setPrimaryCols}
                                        secondaryCols={secondaryCols}
                                        setSecondaryCols={setSecondaryCols}
                                        setSelectedDateRange={setSelectedDateRange}
                                        type="allServiceCalls"
                                        handleReloadData={handleReloadServiceCallsData}
                                    />}
                                initialState={{ showGlobalFilter: true }}
                                renderDetailPanel={rows?.length > 0 ? (row) => <DetailsPanel data={row} displayCols={secondaryCols} /> : null}
                            />
                        </StyledGrid>
                    )}
                </Grid>
            </StyledCardContent>

            {/* Delete Confirmation Dialog */}
            <ConfirmationDialog
                open={isDialogOpen}
                title="Delete Confirmation"
                content="Are you sure you want to delete selected service call?"
                onConfirm={handleDeleteConfirm}
                onClose={() => { setIsDialogOpen(false) }}
            />
        </StyledCard>
    )
}