import { useState, useEffect } from "react";
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Checkbox,
    FormControlLabel,
    Grid
} from "@mui/material";
import useStyles from "./styles";

export default function VehicleInspectionDialog({ open, onClose, title, vehicleInspectionList }) {
    const { StyledDialogButton, StyledLabel, StyledDialogTitle, StyledInputRequired } = useStyles();
    const [beginningMiles, setBeginningMiles] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [checkedItems, setCheckedItems] = useState([]);

    //Validate veginning miles input to be a number.
    const handleChange = (event) => {
        const { value } = event.target;
        setBeginningMiles(value);

        // Validate input to allow only numbers
        if (/^\d*\.?\d*$/.test(value)) {
            setErrorMessage('');
        } else {
            setErrorMessage('Invalid input - enter only numbers');
        }
    };

    //Handle checkbox change
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        const updatedItems = checkedItems.map(item =>
            item.id === parseInt(value) ? { ...item, checked } : item
        );
        setCheckedItems(updatedItems);
    };

    // Set all checkboxes to checked by default
    useEffect(() => {
        if (vehicleInspectionList?.length > 0) {
            const defaultCheckedItems = vehicleInspectionList.map(item => ({
                ...item,
                checked: true
            }));
            setCheckedItems(defaultCheckedItems);
        }
    }, [vehicleInspectionList]);


    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    let formJson = Object.fromEntries(formData.entries());

                    // Add checkedItems to formJson
                    formJson = {
                        ...formJson,
                        "checklist": checkedItems
                    };
                    onClose(formJson);
                },
            }}
        >
            <StyledDialogTitle>{title}</StyledDialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ paddingBottom: 5 }}>
                    <Grid item xs={5}>
                        <StyledLabel sx={{ marginTop: "13px" }}>Beginning Miles<em style={{ color: 'red' }}> *</em></StyledLabel>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            autoFocus
                            required
                            name="beginningMiles"
                            value={beginningMiles}
                            onChange={handleChange}
                            fullWidth
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Only accept numeric input
                            sx={{ margin: 0 }}
                        />
                        {errorMessage && (
                            <StyledInputRequired variant="body2" color="error">
                                {errorMessage}
                            </StyledInputRequired>
                        )}
                    </Grid>
                </Grid>

                {checkedItems?.length > 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ paddingBottom: 1 }}>
                            <StyledLabel>Checklist</StyledLabel>
                        </Grid>
                        {checkedItems.map((item) => (
                            <Grid item xs={6} key={item.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.checked}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                            value={item.id}
                                        />
                                    }
                                    label={item.name}
                                    sx={{ marginLeft: "7px" }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={() => onClose(null)}>Cancel</StyledDialogButton>
                <StyledDialogButton type="submit">Save</StyledDialogButton>
            </DialogActions>
        </Dialog>
    );
}