import { BarChart } from "@mui/x-charts";
import { useState, useEffect } from "react";

//utils
import { ConvertToChartDataFormat } from "../../utils/ConvertToChartDataFormat";

export default function CustomBarChart({ data }) {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (data && Object.keys(data)?.length > 0) {
            const mappedData = ConvertToChartDataFormat(data);
            setChartData(mappedData)
        }
    }, [data])

    return (
        <div style={{ width: '100%', height: '500px' }}>
            <BarChart
                grid={{ horizontal: true }}
                dataset={chartData.data || []}
                series={chartData.series || []}
                xAxis={[{ dataKey: 'xAxis', scaleType: 'band', label: 'Time Period' }]}
                yAxis={[{ label: 'Number of incidents' }]}
                padding={{ top: 50 }}
            />
        </div>
    )
}