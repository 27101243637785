import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent
} from "@mui/material";
import useStyles from "./styles";

export default function InformationDialog({
    open,
    onClose,
    title,
    message,
}) {
    const { StyledDialogButton, StyledDialogTitle } = useStyles();
    const messageList = message?.split("\n") || [];
    return (
        <Dialog aria-label="Information Dialog" open={open}>
            <StyledDialogTitle aria-label="Dialog Title" id="alert-dialog-title" style={{ textAlign: "center", fontWeight: "bold" }}>{title}</StyledDialogTitle>
            <DialogContent>
                {messageList.length > 0 && (
                    <>
                        <span>
                            {messageList[0]}
                            <br />
                        </span>
                        <ul aria-label="Message List">
                            {messageList.slice(1).map((line, index) => (
                                <li key={index} aria-label={line}>
                                    {line}
                                    <br />
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <StyledDialogButton aria-label="Okay" onClick={onClose} color="primary">
                    Ok
                </StyledDialogButton>
            </DialogActions>
        </Dialog>
    );
}
