import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Alert } from "@mui/material";
import useStyles from "./styles";

//icons
import { LocalGasStation as RefuelingIcon } from "@mui/icons-material";

//components
import PageTitle from "../../components/Titles/PageTitle";
import { OpenIncidentMap } from "../../components/Maps/OpenIncidentMap";
import VehicleRefuelingDialog from "../../components/Dialog/VehicleRefuelingDialog";

//constants
import { PatrollerConstants } from '../../utils/Constants';

//context
import { usePatrollerData } from "../../context/PatrollerContext";

export default function ViewOpenIncidents() {
    const { StyledCard, StyledButton, StyledGrid, StyledCardContent, StyledChip } = useStyles();
    const { incidentsData, updatePatrollerData } = usePatrollerData();
    const [showCompleted, setShowCompleted] = useState(false);
    const [filteredIncidents, setFilteredIncidents] = useState([]);
    const loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    const navigate = useNavigate();

    //React hooks
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleSaveDialog = async (dialogData) => {
        setIsDialogOpen(false);

        //Fetch shiftId from session.
        const shiftId = loggedInUser?.shift?.id || null;
        if (dialogData && shiftId) {

            const newRefuelingData =
            {
                "shiftId": shiftId,
                ...dialogData
            }
            await updatePatrollerData([newRefuelingData]);
        }
    }

    useEffect(() => {
        if (incidentsData?.length > 0) {

            //remove closed incidents
            let updatedIncidents = incidentsData.filter(incident => incident.status.toLowerCase() !== "closed");

            //if show completed is not true, remove complete incidents
            if (!showCompleted)
                updatedIncidents = updatedIncidents.filter(incident => incident.status.toLowerCase() !== "complete");
            setFilteredIncidents(updatedIncidents);
        }
    }, [showCompleted, incidentsData]);

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* View complete incidents link */}
                    <StyledGrid item xs={12} sm={5} md={5} lg={5}>
                        <StyledChip label="View Complete/Closed Incidents" onClick={() => { navigate('/patroller/view-complete-incidents') }} />
                    </StyledGrid>

                    {/* Page Title */}
                    <Grid item xs={12} sm={7} md={7} lg={7} sx={{ display: 'flex', alignItems: "center" }}>
                        <PageTitle aria-label="View Open Incidents" title="View Open Incidents" />
                    </Grid>

                    {/* Alert */}
                    <StyledGrid container>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Alert severity="info" sx={{ p: 0, paddingLeft: 2, margin: 0, width: "198%" }}>Click on any open incident to complete.</Alert>
                        </Grid>

                        {/* Vehicle Refuel Button */}
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <StyledButton startIcon={<RefuelingIcon />} sx={{ margin: 0 }} onClick={() => { setIsDialogOpen(true) }}>
                                {PatrollerConstants.VEHICLE_REFUELING_BTN}
                            </StyledButton>
                        </Grid>
                    </StyledGrid>

                    {/* Map */}
                    <StyledGrid container sx={{ paddingTop: 1 }}>
                        <OpenIncidentMap
                            incidentsData={filteredIncidents}
                            showCompleted={showCompleted}
                            setShowCompleted={setShowCompleted}
                            updatePatrollerData={updatePatrollerData}
                        />
                    </StyledGrid>

                    {/* Add New Incident button */}
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '18px' }}>
                        <StyledButton variant="contained" onClick={() => { navigate('/patroller/add-incident') }}>
                            {PatrollerConstants.ADD_INCIDENT_BTN}
                        </StyledButton>
                    </Grid>

                    {/* Vehicle Refueling Dialog */}
                    <VehicleRefuelingDialog
                        open={isDialogOpen}
                        onClose={() => { setIsDialogOpen(false) }}
                        onSave={handleSaveDialog}
                        title="Vehicle Refueling Information"
                        uploadedByName={(loggedInUser?.firstName + " " + loggedInUser?.lastName) || null} />
                </Grid>
            </StyledCardContent>
        </StyledCard >
    )
}